<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    customClass="bug-create create-form"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Submit a new Bug</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Submit
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="bugForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-container fluid>
          <v-row>
            <v-col
              class="custom-content-container pt-0"
              cols="12"
              :md="9"
              :sm="9"
            >
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll"
                style="max-height: 90vh; position: relative"
              >
                <v-row>
                  <v-col md="12">
                    <table class="width-100">
                      <tbody>
                        <tr>
                          <td style="vertical-align: top" width="200">
                            <label
                              class="font-size-16 font-weight-600"
                              for="bug-title"
                              >Title</label
                            >
                          </td>
                          <td>
                            <v-text-field
                              dense
                              id="bug-title"
                              filled
                              label="Title"
                              solo
                              flat
                              color="cyan"
                              v-model.trim="bugSubmit.title"
                              :rules="[
                                validateRules.required(
                                  bugSubmit.title,
                                  'Title'
                                ),
                                validateRules.minLength(
                                  bugSubmit.title,
                                  'Title',
                                  2
                                ),
                                validateRules.maxLength(
                                  bugSubmit.title,
                                  'Title',
                                  255
                                ),
                              ]"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td style="vertical-align: top" width="200">
                            <label
                              class="font-size-16 font-weight-600"
                              for="bug-priority"
                              >Priority</label
                            >
                          </td>
                          <td>
                            <v-select
                              id="bug-priority"
                              :items="priorityList"
                              dense
                              filled
                              item-text="text"
                              item-value="value"
                              item-color="cyan"
                              label="Priority"
                              solo
                              flat
                              color="cyan"
                              v-model.trim="bugSubmit.priority"
                              :rules="[
                                validateRules.required(
                                  bugSubmit.priority,
                                  'Priority'
                                ),
                              ]"
                            ></v-select>
                          </td>
                        </tr>
                        <tr>
                          <td style="vertical-align: top" width="200">
                            <label
                              class="font-size-16 font-weight-600"
                              for="bug-description"
                              >Description</label
                            >
                          </td>
                          <td>
                            <editor v-model="bugSubmit.description" />
                          </td>
                        </tr>
                        <tr>
                          <td class="pt-7">
                            <label class="font-size-16 font-weight-600"
                              >Attachment(s)</label
                            >
                          </td>
                          <td class="pt-7">
                            <FileTemplate
                              allowUpload
                              v-on:file:updated="updateFiles"
                            ></FileTemplate>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </perfect-scrollbar>
            </v-col>
            <v-col
              class="custom-sidebar-container grey lighten-3"
              cols="12"
              md="3"
              sm="3"
            >
              <template v-for="(fieldInfo, indx) in fieldDescriptions">
                <div
                  v-if="fieldInfo.field == currentActiveField"
                  :key="indx"
                  class="help-sidebar"
                >
                  <div class="help-sidebar-header">
                    <h3 v-if="fieldInfo.title" class="section-header-title">
                      {{ fieldInfo.title }}
                    </h3>
                  </div>
                  <p
                    v-if="fieldInfo.description"
                    class="help-sidebar-description"
                  >
                    {{ fieldInfo.description }}
                  </p>
                  <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
                    {{ fieldInfo.footer }}
                  </h5>
                  <p
                    v-if="fieldInfo.footer_content"
                    class="help-sidebar-footer-text"
                  >
                    {{ fieldInfo.footer_content }}
                  </p>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "bug-submit",
  data() {
    return {
      bugSubmit: {
        title: null,
        priority: 3,
        description: null,
        attachment: [],
      },
      priorityList: [
        { text: "High", value: 1 },
        { text: "Medium", value: 2 },
        { text: "Low", value: 3 },
      ],
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    editor: TinyMCE,
    CreateUpdateTemplate,
    FileTemplate,
  },
  methods: {
    updateFiles(param) {
      this.bugSubmit.attachment = param;
    },
    onSubmit() {
      const _this = this;
      try {
        if (!_this.$refs.bugForm.validate()) {
          return false;
        }

        _this.formLoading = true;

        _this.$store
          .dispatch(POST, {
            url: "bug",
            data: _this.bugSubmit,
          })
          .then(() => {
            _this.$router.push(
              _this.getDefaultRoute("bug.report", {
                query: {
                  status: "all",
                },
              })
            );
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bug", route: "bug" }]);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
