<template>
  <v-container fluid>
    <v-card
      flat
      class="custom-grey-border remove-border-radius"
      :disabled="pageLoading"
    >
      <v-card-title class="headline grey lighten-4">
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          Terms &amp; Conditions
          <!-- <pre> {{ updateDataTerm }}</pre> -->
        </h3>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <div class="">
          <v-autocomplete
            dense
            color="cyan"
            filled
            id="select-quote"
            :items="quoteList"
            :disabled="pageLoading"
            :loading="pageLoading"
            v-model.trim="terms.title"
            label="Select Terms &amp; Conditions"
            solo
            flat
            item-color="cyan"
            item-text="title"
            item-value="title"
            hide-details
            v-on:change="selectQuote()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Terms Conditions.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <br />
        <div>
          <editor v-model="terms.validity" />
          <!-- <v-textarea
            v-model.trim="terms.validity"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Validity"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.description, 'Validity', 1),
              validateRules.maxLength(terms.description, 'Validity', 1024),
            ]"
            row-height="25"
          ></v-textarea> -->
          <!-- <v-textarea
            v-model.trim="terms.gst_charges"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="GST Charges"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.gst_charges, 'GST Charges', 1),
              validateRules.maxLength(terms.gst_charges, 'GST Charges', 1024),
            ]"
            row-height="25"
          ></v-textarea> -->
          <!-- <v-textarea
            v-model.trim="terms.payment"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Payment"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.payment, 'Payment', 1),
              validateRules.maxLength(terms.payment, 'Payment', 1024),
            ]"
            row-height="25"
          ></v-textarea> -->
        </div>

        <!--  <div class="d-flex">
          <v-textarea
            v-model.trim="terms.delivery"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Delivery"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.delivery, 'Delivery', 1),
              validateRules.maxLength(terms.delivery, 'Delivery', 1024),
            ]"
            row-height="25"
          ></v-textarea>
          <v-textarea
            v-model.trim="terms.deposit"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Deposit"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.deposit, 'Deposit', 1),
              validateRules.maxLength(terms.deposit, 'Deposit', 1024),
            ]"
            row-height="25"
          ></v-textarea>
        </div> -->

        <!--   <div class="d-flex">
          <v-textarea
            v-model.trim="terms.warranty"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Warranty"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.warranty, 'Warranty', 1),
              validateRules.maxLength(terms.warranty, 'Warranty', 1024),
            ]"
            row-height="25"
          ></v-textarea>
          <v-textarea
            v-model.trim="terms.cancellation"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Cancellation"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.cancellation, 'Cancellation', 1),
              validateRules.maxLength(terms.cancellation, 'Cancellation', 1024),
            ]"
            row-height="25"
          ></v-textarea>
        </div> -->

        <div class="d-flex">
          <!-- <v-textarea
            v-model.trim="terms.stock_availability"
            auto-grow
            dense
            filled
            :disabled="pageLoading"
            :loading="pageLoading"
            color="cyan"
            label="Stock Availability"
            solo
            flat
            :rules="[
              validateRules.minLength(terms.stock_availability, 'Deposit', 1),
              validateRules.maxLength(
                terms.stock_availability,
                'Stock Availability',
                1024
              ),
            ]"
            row-height="25"
          ></v-textarea> -->
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  TermConditionEventBus,
  TermConditionOptionsEventBus,
} from "@/core/lib/term.condition.lib";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { QUERY } from "@/core/services/store/request.module";

export default {
  mixins: [ValidationMixin],
  name: "terms-conditions",
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      defaultTerms: {},
      termsConditions: null,
      terms: {
        quote: null,
        validity: null,
        module: null,
        delivery: null,
        warranty: null,
        payment: null,
        gst_charges: null,
        deposit: null,
        cancellation: null,
        stock_availability: null,
      },
      quoteList: [],
    };
  },
  components: {
    editor: TinyMCE,
  },
  methods: {
    selectQuote() {
      const quote_termsssss = this.quoteList.find((quote) => {
        if (quote.title === this.terms.title) {
          return quote;
        }
      });

      if (quote_termsssss) {
        this.terms.validity = quote_termsssss.validity;
      }
    },
    getQuoteTerms() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: `setting/active-quote-terms/${this.moduleType}`,
        })
        .then(({ data }) => {
          this.quoteList = data;
          //console.log(this.quoteList);
          if (!this.quotationId) {
            let defaultData = this.lodash.find(data, (row) => row.default);
            this.terms = {
              quote: defaultData.quote,
              title: defaultData.title,
              validity: defaultData.validity,
              delivery: defaultData.delivery,
              warranty: defaultData.warranty,
              payment: defaultData.payment,
              gst_charges: defaultData.gst_charges,
              deposit: defaultData.deposit,
              cancellation: defaultData.cancellation,
              stock_availability: defaultData.stock_availability,
            };
            TermConditionOptionsEventBus.$emit(
              "update:term-terms-options",
              this.terms
            );
            //  console.log("this.terms", this.terms);
          }
        });
    },
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    quotationId: {
      type: Number,
      required: true,
      default: null,
    },
    moduleType: {
      type: String,
      default: null,
    },
    updateDataTerm: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    termsConditions() {
      // console.log(_this.termsConditions, "w33");
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        TermConditionEventBus.$emit(
          "update:term-condition",
          _this.termsConditions
        );
      }, _this.timeoutLimit);
    },

    // terms() {
    //   const _this = this;
    //   // clearTimeout(_this.timeout);
    //        TermConditionOptionsEventBus.$emit(
    //       "update:term-terms-options",
    //       this.terms
    //     );
    //   // _this.timeout = setTimeout(function () {
    //   //   TermConditionOptionsEventBus.$emit(
    //   //     "update:term-terms-options",
    //   //     _this.terms
    //   //   );
    //   // }, _this.timeoutLimit);
    // },
    terms: {
      deep: true,
      handler() {
        TermConditionOptionsEventBus.$emit(
          "update:term-terms-options",
          this.terms
        );
      },
    },

    updateDataTerm: {
      deep: true,
      handler(param) {
        console.log(param);

        this.terms = {
          // quote: defaultData.quote,
          title: param.term_conditions,
          validity: param.additional_remarks,
          //  delivery: defaultData.delivery,
          //   warranty: defaultData.warranty,
          //   payment: defaultData.payment,
          //  gst_charges: defaultData.gst_charges,
          ///   deposit: defaultData.deposit,
          //   cancellation: defaultData.cancellation,
          //   stock_availability: defaultData.stock_availability,
        };

        //  this.terms.title = this.updateDataTerm.term_conditions;
        //   this.terms.validity = this.updateDataTerm.additional_remarks;
        // this.termsConditions = this.updateDataTerm.term_conditions;
        // this.termsConditions = this.updateDataTerm.term_conditions;

        if (!this.terms.title) {
          this.terms = param.terms;
        }
        TermConditionOptionsEventBus.$emit(
          "update:term-terms-options",
          this.terms
        );
      },
    },
  },
  mounted() {
    this.getQuoteTerms();
  },
};
</script>
