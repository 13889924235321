<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Technicians
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        v-if="false"
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Technicians #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateStaffSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Skills
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createJobType">
                  <span class="font-size-16 font-weight-600">Create Skill</span>
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="updateStaffSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in skillTypeList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Skill"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteJobType(index)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Certificate
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createCertificate">
                  <span class="font-size-16 font-weight-600"
                    >Create Certificate</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="updateStaffSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in certificateList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Certificate"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteCertificate(index)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-engineer-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        skill_type: [],
        certificate_type: [],
      },
      skillTypeList: [
        {
          text: null,
        },
      ],
      certificateList: [
        {
          text: null,
        },
      ],
    };
  },
  methods: {
    createJobType() {
      this.skillTypeList.push({
        text: null,
      });
    },
    deleteJobType(index) {
      if (this.skillTypeList.length > 1) {
        this.skillTypeList.splice(index, 1);
      }
    },
    createCertificate() {
      this.certificateList.push({
        text: null,
      });
    },
    deleteCertificate(index) {
      if (this.certificateList.length > 1) {
        this.certificateList.splice(index, 1);
      }
    },
    getStaffSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/engineer" })
        .then(({ data }) => {
          _this.setting = data;
          if (data.skill_type) {
            _this.skillTypeList = data.skill_type.map((row) => {
              return { text: row };
            });
          }
          if (data.certificate_type) {
            _this.certificateList = data.certificate_type.map((row) => {
              return { text: row };
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateStaffSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.skill_type = _this.skillTypeList.map((row) => {
        return row.text;
      });
      _this.setting.certificate_type = _this.certificateList.map((row) => {
        return row.text;
      });
      _this.$store
        .dispatch(PATCH, { url: "setting/engineer", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getStaffSetting();
  },
};
</script>
