export default {
  data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBillingDialog: false,
      customerLoading: false,
      propertyLoading: false,
      customerDialog: false,
      entityDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
    };
  },
  methods: {
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    selectCustomerBillingDialog() {
      this.customerBillingDialog = true;
    },
  },
};
