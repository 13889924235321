<template>
  <div>
    <ParentVisit></ParentVisit>
  </div>
</template>

<script>
import ParentVisit from "@/view/pages/visit/Visit";

export default {
  components: { ParentVisit },
};
</script>
