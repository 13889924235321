<template>
  <div class="internal-quotation-listing">
    <v-layout class="my-2">
      <v-flex md4>
        <v-text-field
          v-model.trim="search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :loading="quotationLoading"
          :disabled="quotationLoading"
          color="cyan"
          @keydown.enter="getQuotations()"
          @keydown.tab="getQuotations()"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              depressed
              :disabled="quotationLoading"
              v-on:click="getQuotations()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Quotation #</th>
            <th class="simple-table-th">Job Title / Company Address</th>
            <th class="simple-table-th">Basic Detail</th>
            <th class="simple-table-th">Dates</th>
            <th class="simple-table-th">Amount</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              v-on:click="routeToQuotation(row)"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Job Title: </b>{{ row.job_title }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis-two-line"
                    v-if="row.billing"
                  >
                    <b>Company Address: </b>
                    <template v-if="row.billing.property_address"
                      >{{ row.billing.property_address }},
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference</em></template
                    >
                  </p>
                  <p v-if="false" class="m-0 custom-nowrap-ellipsis">
                    <b>Sales representative: </b>
                    <template v-if="row.sales">{{ row.sales }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Sales representative</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Prepared by: </b>
                    <template v-if="row.prepared_by">{{
                      row.prepared_by
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Prepared by</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.date">
                  <b>Created: </b> {{ formatDate(row.date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.open_till">
                  <b>Open Till: </b> {{ formatDate(row.open_till) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                  <b>Sent: </b> {{ formatDate(row.sent_date) }}
                </p>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sub Total: </b>
                    {{ $accountingJS.formatMoney(row.sub_total) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Tax: </b>
                    {{ $accountingJS.formatMoney(row.tax_amount) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total: </b>
                    {{ $accountingJS.formatMoney(row.total) }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-status">
                  <CustomStatus
                    small
                    :status="row.status"
                    endpoint="quotation/status"
                  ></CustomStatus>
                  <template v-if="row.is_contract">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="green lighten-1"
                          class="mx-2 white--text font-weight-600 d-inline"
                          label
                          small
                        >
                          CONTRACT
                        </v-chip>
                      </template>
                      <span>Contract</span>
                    </v-tooltip>
                  </template>
                  <template v-if="row.is_revised">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="red lighten-4"
                          class="mx-2 black--text font-weight-600 d-inline"
                          label
                          small
                        >
                          REVISED
                        </v-chip>
                      </template>
                      <span>Quotation is Revised</span>
                    </v-tooltip>
                  </template>
                  <template v-if="lodash.isEmpty(row.revise) === false">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="teal lighten-4"
                          class="mx-2 black--text font-weight-600 d-inline"
                          label
                          small
                        >
                          REVISION
                        </v-chip>
                      </template>
                      <span>Revision of {{ row.revise.barcode }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </td>
              <td class="simple-table-td">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no quotation at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="quotationLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </div>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      quotationLoading: false,
      rowData: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.getQuotations();
    },
  },
  methods: {
    clearFilter() {
      //
    },
    getQuotations() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId,
      };

      if (this.quotationLoading) {
        return false;
      }

      this.quotationLoading = true;

      this.$store
        .dispatch(QUERY, { url: "quotation", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.quotationLoading = false;
        });
    },
    routeToQuotation(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("quotation.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getQuotations();
  },
  components: {
    Barcode,
    ListingFooter,
    ListingTable,
    TableActivity,
    CustomStatus,
  },
};
</script>
