<template>
  <!--begin::Leave Listing-->
  <div class="leave-template">
    <PageHeaderCount
      moduleType="leave"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_leaves"
      countkey="leave_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'leave-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="box">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template>
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on:click="deleteLeave"
                      >
                        Bulk Delete
                      </v-btn>
                    </template>
                    <v-list>
                      <!--<template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>-->
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_leaves
                          }}</template>
                          <template v-else>{{
                            item.leave_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template>
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    @click="create_leave_dialog = true"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'leave',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  v-if="false"
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="leave"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <!-- <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Leave"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria> -->
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <LeaveKanban
            v-on:update:leave="updateLeave"
            v-on:selected-rows="update_selected($event)"
            v-on:select-box="
              (param) => {
                box = param;
              }
            "
          ></LeaveKanban>
        </perfect-scrollbar>
        <!-- <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Leave</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <DateRangePicker
                      v-model="dates"
                      prop_label="Duration Date Range"
                    >
                    </DateRangePicker>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Leave')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog> -->
      </template>
    </ListingTemplate>
    <!-- <CreateLeave
      :drawer="create_leave_dialog"
      :leaveUpdateData="leaveCreate"
      @close="create_leave_dialog = false"
    ></CreateLeave> -->
    <template v-if="create_leave_dialog">
      <CreateLeave
        :drawer="create_leave_dialog"
        :leaveUpdateData="leaveCreate"
        @close="
          leaveCreate = {};
          create_leave_dialog = false;
        "
      ></CreateLeave>
    </template>
  </div>
  <!--end::Leave Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
//import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, GET, DELETE } from "@/core/services/store/request.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
//import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
//import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
//import DateRangePicker from "@/view/pages/partials/DateRangePicker";
import CreateLeave from "@/view/pages/leave/Create-Or-Update/CreateLeave";
import LeaveKanban from "@/view/pages/leave/LeaveKanban";

export default {
  mixins: [CommonMixin],
  name: "leave-list",
  data() {
    return {
      create_leave_dialog: false,
      exportLoading: false,
      dataLoading: false,

      leaveCreate: null,
      box: false,
      selectedRows: [],
      statusList: [],
      moreActions: [
        /*{
          title: "Export Leave(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      rows_selected: [],
      /* bulkActions: [
        {
          title: "Mark as Approved",
          action: "approved",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Rejected",
          action: "rejected",
          icon: "mdi-check-all",
        },
      ],*/
    };
  },
  watch: {
    /* dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      },
    }, */
  },
  components: {
    PageHeaderCount,
    draggable,
    // DateRangePicker,
    PageTips,
    ListingTemplate,
    ListingHeader,
    //  ListingSearchCriteria,
    //  Dialog,
    CreateLeave,
    LeaveKanban,
  },
  methods: {
    update_selected(param) {
      this.rows_selected = param;
    },
    updateLeave(param) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + param,
        })
        .then(({ data }) => {
          _this.create_leave_dialog = true;
          _this.leaveCreate = new Object({
            id: data && data.id ? data.id : 0,
            staff: data && data.staff ? data.staff : null,
            user_data: data && data.user_data ? data.user_data : null,
            category: data && data.category ? data.category : null,
            duration: data && data.duration ? data.duration : 0,
            duration_type: data && data.duration_type ? data.duration_type : 0,
            day_type: data && data.day_type ? data.day_type : 1,
            start_date: data && data.start_date ? data.start_date : null,
            end_date: data && data.end_date ? data.end_date : null,
            reason: data && data.reason ? data.reason : null,
            role: data && data.role ? data.role : null,
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    clearSelections() {
      this.selectedRows = [];
    },
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "approved":
          _this.updateLeaveStatus(2);
          break;
        case "rejected":
          _this.updateLeaveStatus(3);
          break;
      }
    },
    updateLeaveStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leave/status",
          data: { leave: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.LeaveExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("leave");
          break;
        case "refresh_list":
          window.location.reload();
          break;
      }
    },
    deleteLeave() {
      const _this = this;
      _this.$store
        .dispatch(DELETE, {
          url: "leave/" + 4,
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Leave",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Leave #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Engineer",
        field: "engineer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Status / Leave Type",
        field: "status_type",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 7,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
