<template>
  <CreateUpdateTemplate :routePreventDialog="routePreventDialog" :customClass="'engineer-create create-form'"
    v-if="getPermission('engineer:create')">
    <template v-slot:header-title>
      <template v-if="updateStaff">
        <v-skeleton-loader class="custom-skeleton large-skeleton-title" v-if="pageLoading" type="text">
        </v-skeleton-loader>
        <template v-else>
          <h1 class="form-title margin-auto">
            {{ getStaffName }}
          </h1>
        </template>
      </template>
      <template v-else>
        <h1 class="form-title d-flex margin-auto">
          Create Technician
        </h1>
      </template>
    </template>
    <template v-slot:header-action>
      <v-btn v-on:click="goBack" :disabled="formLoading" class="mx-2 custom-grey-border custom-bold-button" depressed>
        Cancel
      </v-btn>
      <template v-if="updateStaff && pageLoading">
        <v-skeleton-loader class="custom-skeleton large-skeleton-action-button" type="button">
        </v-skeleton-loader>
      </template>
      <v-btn v-else class="mx-2 custom-bold-button" depressed :disabled="!formValid || formLoading" :loading="formLoading"
        v-on:click="onSubmit" color="cyan white--text">
        <template v-if="updateStaff">Update Technician</template>
        <template v-else>Save Technician</template>
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form ref="engineerForm" v-model.trim="formValid" lazy-validation v-on:submit.stop.prevent="onSubmit">
        <v-row>
          <v-col cols="12">
            <v-container fluid class="">
              <v-tabs v-model="engineerTab" background-color="transparent" centered color="cyan" fixed-tabs
                class="custom-tab-transparent">
                <v-tab class="font-size-16 font-weight-600 px-8" :href="'#personal-info'">
                  <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
                  Personal Info
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" :href="'#emergency-contact'">
                  <v-icon color="grey darken-4" left>mdi-phone</v-icon>
                  Emergency Contact
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" :href="'#engineer-address'">
                  <v-icon color="grey darken-4" left>mdi-home-map-marker</v-icon>
                  Address
                </v-tab>
                <!-- <v-tab class="font-size-16 font-weight-600 px-8" :href="'#color'">
                <v-icon color="grey darken-4" left
                  >mdi-format-color-fill</v-icon
                >
                Color
              </v-tab> -->
              </v-tabs>
              <perfect-scrollbar :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow engineer-create-height"
                style="max-height: 90vh; position: relative">
                <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
                  <v-tab-item :value="'personal-info'">
                    <PersonalInfo :pageLoading="pageLoading" :updateStaff="updateStaff" :engineer="engineerArr">
                    </PersonalInfo>
                  </v-tab-item>
                  <v-tab-item :value="'emergency-contact'">
                    <EmergencyContact :pageLoading="pageLoading" :updateStaff="updateStaff" :engineer="engineerArr">
                    </EmergencyContact>
                  </v-tab-item>
                  <v-tab-item :value="'engineer-address'">
                    <StaffAddress :pageLoading="pageLoading" :updateStaff="updateStaff" :engineer="engineerArr">
                    </StaffAddress>
                  </v-tab-item>
                  <v-tab-item :value="'color'">
                    <ColorTemplate :pageLoading="pageLoading" :updateStaff="updateStaff" :engineer="engineerArr">
                    </ColorTemplate>
                  </v-tab-item>
                </v-tabs-items>
              </perfect-scrollbar>
            </v-container>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInfo from "@/view/pages/engineer/create-or-update/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/create-or-update/Emergency-Contact";
import StaffAddress from "@/view/pages/engineer/create-or-update/Engineer-Address";
import ColorTemplate from "@/view/pages/engineer/create-or-update/Color";
import StaffMixin from "@/core/lib/engineer/engineer.mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CommonMixin, StaffMixin],
  name: "manage-engineer-create",
  title: "Create Staff",
  data() {
    return {
      updateStaff: false,
    };
  },
  components: {
    PersonalInfo,
    EmergencyContact,
    StaffAddress,
    ColorTemplate,
    CreateUpdateTemplate
  },
  watch: {
    engineer(param) {
      if (param > 0) {
        this.getStaffDetails();
      }
    },
  },
  methods: {
    getStaffDetails() {
      const _this = this;
      if (_this.engineer) {
        _this
          .getEngineer()
          .then((response) => {
            _this.engineerArr = response;
            _this.data = _this.engineerArr;
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Technician", route: "engineer" },
              { title: "Update" },
              { title: response.barcode },
            ]);
          })
          .catch((error) => {
            _this.logError(error);
            _this.$router.go(-1);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Technician", route: "engineer" },
      { title: "Create" },
    ]);

    _this.getStaffDetails();
  },
  computed: {
    getStaffName() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineerArr) === false) {
        return _this.engineerArr.full_name;
      }
      return null;
    },
  },
  created() {
    const _this = this;
    if (
      _this.$route.name == "admin.engineer.update" ||
      _this.$route.name == "engineer.engineer.update"
    ) {
      _this.updateStaff = true;
      _this.pageLoading = true;
      _this.engineer = _this.$route.params.id;
      if (!_this.engineer || _this.engineer <= 0) {
        _this.$router.go(-1);
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.engineerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
<style scoped>
.v-text-field--filled>.v-input__control>.v-input__slot {
  min-height: 100%;
}

.v-input input {
  max-height: 30px;
}
</style>
