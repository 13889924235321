<template>
  <v-row class="customer-detail custom-border-top">
    <v-col cols="6" md="6" sm="12">
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        type="text@3, image"
      >
      </v-skeleton-loader>
      <template v-else>
        <!-- <v-switch
          v-model.trim="customer.client_reminders"
          class="mt-0"
          inset
          color="cyan"
          label="Client reminders sent for upcoming visits."
          readonly
        ></v-switch>
        <v-switch
          v-model.trim="customer.client_follow_up_emails"
          class="mt-0"
          inset
          color="cyan"
          label="Client follow-up emails when you close a job."
          readonly
        ></v-switch>
        <v-switch
          v-model.trim="customer.print_email_on_work_order"
          class="mt-0"
          inset
          color="cyan"
          label="Print email on document when you download."
          readonly
        ></v-switch>-->
        <v-textarea
          v-model.trim="customer.customer_remark"
          auto-grow
          dense
          color="cyan"
          filled
          label="No Remarks"
          solo
          flat
          readonly
          row-height="30"
          :class="{ 'custom-field-empty': !customer.customer_remark }"
        ></v-textarea>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "notification",
  title: "Customer",
  props: {
    customer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
