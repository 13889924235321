<template>
  <Dialog :common-dialog="reassignScheduleDialog" :dialog-width="dialogWidth">
    <template v-slot:title> Assign & Re-Schedule</template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-16">Delivery Date</label>
              <DatePicker
                :pageLoading="formLoading"
                solo
                :placeholder="'Delivery Date'"
                :default-date="reassignSchedule.start_date"
                :min-date="todayDate"
                v-model="reassignSchedule.start_date"
              ></DatePicker>
              <!--  <v-menu v-model="startDatePicker" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense filled flat label="Delivery Date" hide-details solo
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                        :value="formattedStartDate" color="cyan"></v-text-field>
                                </template>

                                <v-date-picker v-model="reassignSchedule.start_date" color="cyan" :min="todayDate"
                                    v-on:input="startDatePicker = false"></v-date-picker>
                            </v-menu> -->
            </v-col>
            <template v-if="reassignSchedule.custom">
              <v-col md="3">
                <label
                  class="font-weight-700 font-size-16"
                  for="one-off-start-time"
                  >Start Time</label
                >
                <TimePicker
                  key="visit-reschedule-start-time"
                  id="start-time"
                  placeholder="Start Time"
                  v-model.trim="reassignSchedule.start_time"
                  :min-time="todayTime"
                  v-on:change="addTime(reassignSchedule)"
                />
                <!--  <v-autocomplete
                  :disabled="formLoading"
                  :loading="formLoading"
                  dense
                  color="cyan"
                  filled
                  id="start-time"
                  :items="timeListCustom"
                  v-model.trim="reassignSchedule.start_time"
                  placeholder="Start Time"
                  label="Start Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                /> -->
              </v-col>
              <v-col md="3">
                <label
                  class="font-weight-700 font-size-16"
                  for="one-off-end-time"
                  >End Time
                </label>
                <!--   <v-autocomplete
                  :disabled="formLoading"
                  :loading="formLoading"
                  dense
                  color="cyan"
                  filled
                  id="end-time"
                  :items="timeListCustom"
                  v-model.trim="reassignSchedule.end_time"
                  placeholder="End Time"
                  label="End Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                /> -->
                <TimePicker
                  key="visit-reschedule-end-time"
                  id="end-time"
                  placeholder="End Time"
                  v-model="reassignSchedule.end_time"
                  :min-time="updatedDate"
                />
              </v-col>
            </template>
            <template v-if="!reassignSchedule.custom">
              <v-col md="6">
                <div class="">
                  <label
                    class="font-weight-700 font-size-16"
                    for="one-off-start-time"
                    >Slots</label
                  >
                  <v-autocomplete
                    :disabled="formLoading"
                    :loading="formLoading"
                    dense
                    color="cyan"
                    filled
                    id="start-time"
                    :items="timeCustomSlot"
                    v-model.trim="reassignSchedule.customSlot"
                    v-on:change="slotTimeData($event)"
                    placeholder="Time Slots"
                    label="Time Slots"
                    solo
                    item-value="value"
                    flat
                    item-color="cyan"
                    hide-details
                  />
                </div>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col md="6">
              <div class="d-flex">
                <!--  <label class="font-weight-700 font-size-16">Custom</label> -->
                <v-checkbox
                  color="cyan"
                  hide-details
                  v-model.trim="reassignSchedule.custom"
                  class="m-0 p-0"
                ></v-checkbox>
                <label for="" class="font-weight-700 margin-2px">Custom</label>
              </div>
            </v-col>
            <v-col>
              <label class="font-weight-700 font-size-16 required"
                >Select Drivers</label
              >
              <v-autocomplete
                :rules="[
                  validateRules.required(reassignSchedule.drivers, 'Driver'),
                ]"
                dense
                color="cyan"
                filled
                id="job-type"
                :items="engineerList"
                :disabled="formLoading"
                :loading="formLoading"
                label="Select Drivers"
                solo
                flat
                item-color="cyan"
                placeholder="Select Drivers"
                item-text="display_name"
                v-model.trim="reassignSchedule.drivers"
                item-value="id"
                hide-details
                multiple
                class="width-100"
              >
                <template v-slot:item="data">
                  <table>
                    <tr>
                      <td>
                        <span class="mr-2">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            dense
                            small
                            color="#24326d"
                            style="max-width: 72px !important"
                            >{{ data.item.barcode }}</v-chip
                          >
                        </span>
                        <span class="mr-2">{{ data.item.display_name }}</span>
                        <template v-if="data.item && data.item.role == 2">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            dense
                            small
                            color="blue"
                            class="mr-1"
                            style="max-width: 72px !important"
                            >Technician</v-chip
                          >
                        </template>
                        <template v-if="data.item && data.item.is_driver == 1">
                          <v-chip
                            label
                            outlined
                            text-color=""
                            small
                            style="max-width: 48px !important"
                            color="red"
                          >
                            Driver</v-chip
                          >
                        </template>
                      </td>
                    </tr>
                  </table>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <!--  -->
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        :disabled="!formValid || formLoading"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
/*   import { validateEmail } from "@/core/plugins/validation-mixin"; */
import { PUT, QUERY, GET } from "@/core/services/store/request.module";
import TimePicker from "@/view/components/TimePicker.vue";

import AppConfiguration from "@/core/config/app.config";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    reassignScheduleDialog: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      formValid: true,
      startDatePicker: false,
      engineerList: [],
      timeCustomSlot: [],
      reassignSchedule: {
        start_date: null,
        drivers: [],
        end_date: null,
        custom: false,
        customSlot: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  watch: {},
  methods: {
    close_dialog() {
      this.$emit("close", true);
    },
    getAvailableTeam() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-Drivers",
          data: {
            start_date: _this.reassignSchedule.start_date
              ? moment(_this.reassignSchedule.start_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: _this.reassignSchedule.end_date
              ? moment(_this.reassignSchedule.end_date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            start_time: _this.reassignSchedule.start_time
              ? moment(_this.reassignSchedule.start_time, ["h:mm A"]).format(
                  "HH:mm"
                )
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.reassignSchedule.end_time
              ? moment(_this.reassignSchedule.end_time, ["h:mm A"]).format(
                  "HH:mm"
                )
              : moment().endOf("day").format("HH:mm"),
            force: 0,
            all: 1,
            /*  skills: _this.skills, */
          },
        })
        .then(({ data }) => {
          _this.engineerList = data.engineers;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },
    slotTimeData(data) {
      const date = data.split(" - ");
      let startTime = date[0];
      let endTime = date[1];
      this.reassignSchedule.start_time = startTime;
      this.reassignSchedule.end_time = endTime;
    },
    getTimeSlotes() {
      const _this = this;

      _this.$store
        .dispatch(GET, {
          url: "setting/active-slotes",
        })
        .then(({ data }) => {
          _this.timeCustomSlot = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
      /* .finally(() => {
                    _this.pageLoading = false;
                }); */
    },
    addTime(row) {
      this.reassignSchedule.end_time = moment(row.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    submit() {
      const _this = this;
      if (
        _this.reassignSchedule &&
        _this.reassignSchedule.drivers &&
        _this.reassignSchedule.drivers.length == 0
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select driver then try again.")
        );
        return false;
      }
      _this.formLoading = true;
      (_this.reassignSchedule.start_date = _this.reassignSchedule.start_date
        ? moment(_this.reassignSchedule.start_date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")),
        (_this.reassignSchedule.end_date = _this.reassignSchedule.end_date
          ? moment(_this.reassignSchedule.end_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")),
        (_this.reassignSchedule.start_time = _this.reassignSchedule.start_time
          ? moment(_this.reassignSchedule.start_time, ["h:mm A"]).format(
              "HH:mm"
            )
          : null),
        (_this.reassignSchedule.end_time = _this.reassignSchedule.end_time
          ? moment(_this.reassignSchedule.end_time, ["h:mm A"]).format("HH:mm")
          : null);

      _this.$store
        .dispatch(PUT, {
          url:
            "re-assign/" +
            this.ticketId +
            "/visit/" +
            this.visitId +
            "/delivery",
          data: _this.reassignSchedule,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  components: {
    Dialog,
    DatePicker,
    TimePicker,
  },
  mounted() {
    this.getAvailableTeam();
    this.getTimeSlotes();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    todayTime() {
      return moment(this.reassignSchedule.start_date).format("hh:mm A");
    },
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    updatedDate() {
      return moment(this.reassignSchedule.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.reassignSchedule.start_date).format(
          Config.dateFormat
        );
      }
      return this.reassignSchedule.start_date;
    },
  },
};
</script>
