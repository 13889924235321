<template>
  <Dialog scrollable :common-dialog="visitDialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout v-if="visitDialog">
        <v-flex md6 class="d-flex">
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            Leave #{{ visitDetail.barcode }}
          </h1>
          <!-- <Priority
              v-if="detail.priority"
              :priority="detail.priority"
            ></Priority> -->
          <template v-if="visitDetail.status">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="text-uppercase white--text"
                  color="green"
                  label
                >
                  Approved
                </v-chip>
              </template>
              <span>Status</span>
            </v-tooltip>
          </template>
        </v-flex>
        <v-flex md6 class="d-flex justify-end">
          <div v-if="!pageLoading" class="d-flex justify-end">
            <template
              v-if="visitDetail.status == 3 && !visitDetail.is_revised && false"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="statusLoading"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="updateMoreAction('revise')"
                  >
                    <v-icon left>mdi-update</v-icon> Revise
                  </v-btn>
                </template>
                <span>Click here to revise Quote</span>
              </v-tooltip>
            </template>
            <template
              v-if="
                visitDetail.status != 7 &&
                visitDetail.status != 6 &&
                visitDetail.status != 3 &&
                visitDetail.status != 2 &&
                visitDetail.status != 5 &&
                visitDetail.status != 8 &&
                false
              "
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="statusLoading"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="updateMoreAction('edit')"
                  >
                    <v-icon left>mdi-pencil</v-icon> Edit
                  </v-btn>
                </template>
                <span>Click here to edit Quote</span>
              </v-tooltip>
            </template>
            <v-menu
              v-if="false"
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="statusLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="(more, index) in moreActions">
                  <v-list-item
                    link
                    v-on:click="updateMoreAction(more.action)"
                    :key="index"
                    :disabled="statusLoading || more.disabled"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>

            <v-btn
              class="ml-2 custom-grey-border custom-bold-button"
              v-on:click="$emit('close', true)"
            >
              Close
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row>
          <v-col md="6" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 2"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100">
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Staff Name
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ visitDetail.staff.full_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Leave number
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <template>
                            <Barcode
                              dense
                              small
                              :barcode="visitDetail.barcode"
                            ></Barcode>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Leave Type
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ visitDetail.category }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Start Date
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ formatDate(visitDetail.start_date) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">End Date</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          {{ formatDate(visitDetail.end_date) }}
                        </td>
                      </tr>
                      <tr v-if="visitDetail && visitDetail.duration">
                        <td class="font-size-18 py-1" width="200">Half Day</td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <v-chip
                            class="mb-1 mr-1"
                            label
                            color="green white--text"
                            x-small
                            v-if="visitDetail && visitDetail.duration_type == 1"
                          >
                            <template v-if="visitDetail.day_type == 1">
                              {{ formatDate(visitDetail.start_date) }}
                            </template>
                            <template v-else>
                              {{ formatDate(visitDetail.end_date) }}
                            </template>

                            (First Half)
                          </v-chip>
                          <v-chip
                            class="mb-1 mr-1"
                            label
                            color="green white--text"
                            x-small
                            v-else
                          >
                            <template v-if="visitDetail.day_type == 1">
                              {{ formatDate(visitDetail.start_date) }}
                            </template>
                            <template v-else>
                              {{ formatDate(visitDetail.end_date) }} </template
                            >(Second Half)
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="200">
                          Number of Days
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <v-chip
                            class="mb-1 mr-1"
                            label
                            color="green white--text"
                            x-small
                          >
                            {{ visitDetail.total_leave_days }}
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1 d-flex" width="200">
                          Reason
                        </td>
                        <td class="font-weight-600 font-size-18 py-1">
                          <template v-if="visitDetail && visitDetail.reason">{{
                            visitDetail.reason
                          }}</template>
                          <em v-else class="text-muted"> no reason</em>
                        </td>
                      </tr>
                    </table>
                    <br />
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col md="6" class="pb-0 gray-background pl-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                    width="100"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 2"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100" style="margin-top: -10px">
                      <thead>
                        <tr>
                          <td colspan="2">
                            <h1
                              class="form-title custom-nowrap-ellipsis text-capitalize mb-0"
                              style="color: #24326d !important"
                            >
                              Leave Attachments
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <th class="p-2 light-blue-bg" width="50"></th>
                          <th class="p-2 light-blue-bg" width="150">Name</th>
                          <th class="p-2 light-blue-bg" width="300">Action</th>
                        </tr>
                      </thead>

                      <tbody v-if="visitDetail.attachments.length">
                        <tr
                          v-for="(row, index) in visitDetail.attachments"
                          :key="index"
                        >
                          <td class="p-2 border-top-light-grey" width="50">
                            <template
                              v-if="row.extension && isImage(row.extension)"
                            >
                              <ImageTemplate
                                :src="row.file.url"
                                style="max-width: 50px; width: 50px"
                              ></ImageTemplate>
                            </template>
                            <template v-else>
                              <inline-svg
                                :src="
                                  $assetURL(`media/mime/${row.extension}.svg`)
                                "
                              />
                            </template>
                          </td>
                          <td class="p-2 border-top-light-grey" width="250">
                            <p
                              class="m-0 blue--text font-level-1 cursor-pointer bold-600"
                              v-on:click="doAction(row, 'download')"
                            >
                              {{ row.name }}
                            </p>
                            <!-- <div v-if="is_main" class="d-flex my-1">
                                            <p class="m-0 font-level-1 cursor-pointer bold-600">Type :</p>
                                            <v-badge
                                            class="mt-2 ml-2"
                                            :color="row.type_color ? row.type_color : '#58b5bd'"
                                            :content="row.type.toUpperCase()"
                                            ></v-badge>
                                        </div>

                                        <p class="m-0 text-muted font-small w-100">{{ row.added_at }}</p>
                                        <p class="m-0 text-muted font-small w-100">
                                            by {{ row.user_display_name }}
                                        </p> -->
                          </td>
                          <td
                            class="p-2 border-top-light-grey font-level-1"
                            width="150"
                          >
                            <v-btn
                              v-on:click="downloadAttachment(row.file.url)"
                              icon
                              depressed
                              color="blue darken-4"
                              class="mr-2"
                              ><v-icon medium>mdi-download</v-icon></v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-else>
                        <tr>
                          <td colspan="3">
                            <p class="m-0 row-not-found">
                              <img
                                :src="$assetURL('media/error/empty.png')"
                                class="row-not-found-image"
                              />
                              Uhh... There are no file at the moment.
                            </p>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <br />
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ImageTemplate from "@/view/pages/Image";

//import Priority from "@/view/pages/partials/Priority.vue";
/* import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */
import {
  /*  PUT,
     QUERY,
     POST,
     PATCH, */
  GET,
} from "@/core/services/store/request.module";
/* import JwtService from "@/core/services/jwt.service";
 import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
 import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
 import { toNumber } from "lodash";
 import { downloadEvent } from "@/core/lib/ics.lib";
 import Datepicker from "@/view/pages/partials/Datepicker.vue"; */
//import Timepicker from "@/view/pages/partials/Timepicker.vue";
/* import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"; */

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  props: {
    visitDialog: {
      type: Boolean,
      default: false,
    },
    isCalendar: {
      type: Boolean,
      default: false,
    },
    visit: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.loadContent();
        }
      },
    },
  },
  data() {
    return {
      visitId: null,
      allowUpdate: false,
      pageLoading: false,
      detail: new Object(),
      visitDetail: {},
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
    };
  },
  methods: {
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/" + _this.visit,
        })
        .then(({ data }) => {
          _this.visitDetail = data;
          console.log(_this.visitDetail, "_this.visitDetail");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          //_this.getActiveTimer();
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    // Priority,
    Barcode,
    /*  Datepicker, */
    // Timepicker,
    /* VueTimepicker, */
    /*  CreateTimeCloser,
         DeleteDialog, */
    /*    CustomStatus, */
    ImageTemplate,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    updateStartDate() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.finished_at).format("hh:mm A");
      }
      return null;
    },
    canUpdateVisit() {
      if (this.visitDetail && this.visitDetail.status != 1) {
        return false;
      }
      return this.getPermission("visit:update");
    },
    getAppointmentText() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        let date = this.formatDate(this.visitDetail.started_at);
        let startTime = moment(this.visitDetail.started_at).format("hh:mm A");
        let endTime = moment(this.visitDetail.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
  },
};
</script>
