<template>
  <!--begin::Calendar-->
  <div class="map-template">
    <v-container fluid class="white-background main-detail-page">
      <v-row>
        <v-col cols="12">
          <v-layout class="position-relative overflow-hidden">
            <div
              class="left-sidebar"
              :class="leftSidebar === false ? 'sidebar-hide' : ''"
              style="z-index: 999"
            >
              <v-icon
                class="toggle-sidebar-btn primary white--text p-2"
                v-on:click="leftSidebar = !leftSidebar"
              >
                {{
                  leftSidebar === true
                    ? "mdi-chevron-left"
                    : "mdi-chevron-right"
                }}
              </v-icon>
              <h3 class="p-3 primary white--text d-flex">
                <span>Tickets</span>
                <v-spacer></v-spacer>
                <v-icon
                  class="cursor-pointer"
                  color="white"
                  v-on:click="show_filter = !show_filter"
                >
                  {{ show_filter ? "mdi-close" : "mdi-filter-variant" }}
                </v-icon>
              </h3>
              <v-flex class="px-2 map-filter" v-if="show_filter">
                <v-autocomplete
                  v-model="engineers"
                  :items="allEngineers"
                  outlined
                  dense
                  placeholder="Filter"
                  clearable
                  item-color="success"
                ></v-autocomplete>
              </v-flex>
              <div class="px-2">
                <v-tabs
                  v-model="TicketsTab"
                  fixed-tabs
                  centered
                  icons-and-text
                  color="primary"
                >
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab class="primary--text text-capitalize">
                    All
                    <span class="h4 mb-0">1</span>
                  </v-tab>
                  <v-tab class="primary--text text-capitalize">
                    Closed
                    <span class="h4 mb-0">0</span>
                  </v-tab>
                  <v-tab class="primary--text text-capitalize">
                    Inprogress
                    <span class="h4 mb-0">0</span>
                  </v-tab>
                </v-tabs>
                <div class="tab-items-wrap">
                  <v-tabs-items v-model="TicketsTab">
                    <v-tab-item>
                      <div class="d-flex pt-2">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 4px;
                              "
                              >mdi-copyright</v-icon
                            >
                            TIC0123
                          </span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="cyan white--text"
                          class="cm-btn rounded-pill"
                        >
                          Details
                        </v-btn>
                      </div>
                      <div class="d-flex pt-2">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 20px;
                                position: absolute;
                                left: 0;
                                top: 2px;
                              "
                              >mdi-account</v-icon
                            >
                            Mr. ethan li</span
                          >
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <div class="pr-20 pt-2">
                        <div class="location primary--text position-relative">
                          <p class="pl-5 mb-1">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 3px;
                              "
                              >mdi-map-marker</v-icon
                            >
                            Rowell Road 42 Rochor Singapore 277993
                          </p>
                        </div>
                        <div class="location primary--text position-relative">
                          <p class="pl-5 mb-1 font-weight-bold">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 3px;
                              "
                              >mdi-account-tie</v-icon
                            >
                            Leo Won
                          </p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div class="primary--text pl-5 position-relative">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 2px;
                              "
                              >mdi-calendar</v-icon
                            >
                            <p>26 Jan 2022</p>
                          </div>
                          <div class="primary--text pl-5 position-relative">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 2px;
                              "
                              >mdi-clock-time-five-outline</v-icon
                            >
                            <p>12:00 - 15:00</p>
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="d-flex pt-3">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 8px;
                              "
                              >mdi-copyright</v-icon
                            >
                            TIC0123
                          </span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="cyan white--text"
                          class="cm-btn rounded-pill"
                        >
                          Details
                        </v-btn>
                      </div>
                      <div class="d-flex pt-3">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 20px;
                                position: absolute;
                                left: 0;
                                top: 2px;
                              "
                              >mdi-account</v-icon
                            >
                            Mr. ethan li</span
                          >
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <div class="pr-20 pt-3">
                        <div class="location primary--text position-relative">
                          <p class="pl-5">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 3px;
                              "
                              >mdi-map-marker</v-icon
                            >
                            Rowell Road 42 Rochor Singapore 277993
                          </p>
                        </div>
                        <div class="primary--text pl-5 position-relative">
                          <v-icon
                            color="primary"
                            style="
                              font-size: 18px;
                              position: absolute;
                              left: 0;
                              top: 2px;
                            "
                            >mdi-clock-time-five-outline</v-icon
                          >
                          <p>12:00 - 15:00</p>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="d-flex pt-3">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 8px;
                              "
                              >mdi-copyright</v-icon
                            >
                            TIC0123
                          </span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="cyan white--text"
                          class="cm-btn rounded-pill"
                        >
                          Details
                        </v-btn>
                      </div>
                      <div class="d-flex pt-3">
                        <div
                          class="name d-flex align-items-center position-relative"
                        >
                          <span class="h4 mb-0 pl-5 ellipsis">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 20px;
                                position: absolute;
                                left: 0;
                                top: 2px;
                              "
                              >mdi-account</v-icon
                            >
                            Mr. ethan li</span
                          >
                        </div>
                        <v-spacer></v-spacer>
                      </div>
                      <div class="pr-20 pt-3">
                        <div class="location primary--text position-relative">
                          <p class="pl-5">
                            <v-icon
                              color="primary"
                              style="
                                font-size: 18px;
                                position: absolute;
                                left: 0;
                                top: 3px;
                              "
                              >mdi-map-marker</v-icon
                            >
                            Rowell Road 42 Rochor Singapore 277993
                          </p>
                        </div>
                        <div class="primary--text pl-5 position-relative">
                          <v-icon
                            color="primary"
                            style="
                              font-size: 18px;
                              position: absolute;
                              left: 0;
                              top: 2px;
                            "
                            >mdi-clock-time-five-outline</v-icon
                          >
                          <p>12:00 - 15:00</p>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </div>
            <div class="w-100" style="height: calc(100vh - 120px)">
              <GmapMap ref="mapRef" :center="mapCenter" :zoom="7">
                <GmapMarker :position="mapCenter" />
              </GmapMap>
            </div>
            <div
              class="right-sidebar"
              :class="rightSidebar === false ? 'sidebar-hide' : ''"
            >
              <v-icon
                class="toggle-sidebar-btn primary white--text p-2"
                v-on:click="rightSidebar = !rightSidebar"
              >
                {{
                  rightSidebar === true
                    ? "mdi-chevron-right"
                    : "mdi-chevron-left"
                }}
              </v-icon>
              <div v-if="!engineerDetail">
                <h3 class="p-3 primary white--text">Engineer</h3>
                <div class="px-2">
                  <div class="d-flex py-2 border-bottom cursor-pointer">
                    <div
                      class="name d-flex align-items-center position-relative"
                      v-on:click="engineerDetail = true"
                    >
                      <img
                        src="https://picsum.photos/id/11/500/300"
                        alt=""
                        height="30px"
                        width="30px"
                        class="rounded-circle mr-2"
                      />
                      <div>
                        <p class="h4 mb-0 ellipsis">Mr. ethan li</p>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-icon color="primary"> mdi-map-marker </v-icon>
                    <v-chip
                      class="m-1 font-weight-bold"
                      color="primary"
                      outlined
                      small
                      text-color="primary"
                      >1 Task
                    </v-chip>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="">
                  <div class="d-flex py-2 px-2 primary white--text">
                    <div class="name d-flex position-relative">
                      <img
                        src="https://picsum.photos/id/11/500/300"
                        alt=""
                        height="30px"
                        width="30px"
                        class="rounded-circle mr-2"
                      />
                      <div>
                        <p class="h4 mb-0 ellipsis">Mr. ethan li</p>
                        <p class="m-0 mt-2">0 Task Left</p>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-icon
                      color="white"
                      outlined
                      mdi-close
                      class="cursor-pointer"
                      v-on:click="engineerDetail = false"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                  <div class="py-2 px-2 font-weight-bold">Mob : 12345432</div>
                  <div class="px-2">
                    <div class="d-flex pt-3">
                      <div>
                        <p class="h4 mb-0 primary--text ellipsis">TIC0123</p>
                        <p class="m-0 mt-2 font-weight-bold">Jan 27, 2022</p>
                      </div>
                      <v-spacer></v-spacer>
                      <div class="text-right">
                        <strong class="red--text"> closed </strong>
                        <div class="mt-2">
                          <v-chip color="primary white--text" small>
                            Details
                          </v-chip>
                        </div>
                      </div>
                    </div>
                    <div class="pr-20 pt-3">
                      <div class="location primary--text position-relative">
                        <p class="pl-5">
                          <v-icon
                            color="primary"
                            style="
                              font-size: 18px;
                              position: absolute;
                              left: 0;
                              top: 3px;
                            "
                            >mdi-map-marker</v-icon
                          >
                          Rowell Road 42 Rochor Singapore 277993
                        </p>
                      </div>
                      <div class="primary--text pl-5 position-relative">
                        <v-icon
                          color="primary"
                          style="
                            font-size: 18px;
                            position: absolute;
                            left: 0;
                            top: 2px;
                          "
                          >mdi-clock-time-five-outline</v-icon
                        >
                        <p>12:00 - 15:00</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!--end::Calendar-->
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      show_filter: false,
      TicketsTab: null,
      engineerDetail: false,
      engineers: null,
      allEngineers: ["ethan li", "Henry Thomas", "Leo Won"],
      leftSidebar: true,
      rightSidebar: true,
      mapCenter: { lat: 1.29027, lng: 103.851959 },
    };
  },
};
</script>
<style scoped></style>
