<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'job-create'"
    v-if="getPermission('job:create')"
  >
    <template v-slot:header-title>
      <div class="form-action">
        <div class="form-title">
          Visit for
          <span
            class="form-title-link cursor-pointer"
            v-on:click="openDialog('customer')"
          >
            <template v-if="vCustomerId">{{ vCustomer.display_name }}</template>
            <template v-else>
              Select a Customer
              <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
            </template>
          </span>
        </div>
      </div>
    </template>
    <template v-slot:header-action>
      <!--  {{ updateTeamChanged }}
      {{ updateScheduleChanged }}
      {{ vUpdateTarget }}
      {{ updateFormChanged }} -->
      <v-btn
        :disabled="update_loading"
        class="custom-bold-button custom-grey-border"
        depressed
        v-on:click="goBack()"
        >Cancel</v-btn
      >
      <v-btn
        class="custom-bold-button ml-4"
        depressed
        :disabled="!formValid || formLoading || update_loading"
        :loading="formLoading"
        v-on:click="validateJob()"
        color="cyan white--text"
        >Save</v-btn
      >
    </template>
    <template v-slot:body>
      <v-container fluid class="">
        <v-form
          ref="createVisitForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="validateJob()"
        >
          <v-row>
            <v-col md="7">
              <label for="visit-title" class="required">Visit Title</label>
              <v-text-field
                id="visit-title"
                dense
                filled
                placeholder="Title"
                solo
                flat
                v-model="dbVisit.title"
                color="cyan"
                :rules="[
                  validateRules.required(dbVisit.title, 'Title'),
                  validateRules.maxLength(dbVisit.title, 'Title', 100),
                ]"
              />
              <v-textarea
                auto-grow
                dense
                filled
                color="cyan"
                v-model="dbVisit.description"
                placeholder="Description"
                solo
                v-on:keypress="(e) => manageLimit(e)"
                v-on:paste="(e) => onPaste(e)"
                flat
                row-height="25"
                :rules="[
                  validateRules.maxLength(
                    dbVisit.description,
                    'Description',
                    1024
                  ),
                ]"
              />
              <div md="7" class="text-right">
                {{ dbVisit.description ? dbVisit.description.length : 0 }}/200
              </div>
              <v-row>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0 font-weight-600"
                        >Billing Location</label
                      ></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        v-on:click="openDialog('billing')"
                        small
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBilling?.id">
                    <span v-if="vBilling && vBilling.property_address">
                      <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                    >
                    <p v-if="vBilling && vBilling.property_address">
                      {{ vBilling.street_1 }},
                      <br v-if="vBilling.street_2 || vBilling.unit_no" />
                      <template v-if="vBilling.street_2">
                        {{ vBilling.street_2 }},
                      </template>
                      <template v-if="vBilling.unit_no">
                        {{ vBilling.unit_no }},
                      </template>
                      <br />
                      {{ vBilling.country }}
                      <template
                        v-if="vBilling && vBilling.zip_code != '000000'"
                      >
                        , {{ vBilling.zip_code }}
                      </template>
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select billing location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0 font-weight-600"
                        >Contact Details</label
                      ></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('billing-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>
                  <template v-if="vBillingContact?.id">
                    <p class="mb-0">{{ vBillingContact.display_name }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                    <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>
                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0 font-weight-600"
                        >Email Notification
                      </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbVisit.billing_person_notify"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="6">
                  <v-layout class="align-center">
                    <v-flex md3
                      ><label class="my-0 font-weight-600"
                        >Site Location</label
                      ></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vProperty?.id">
                    <span v-if="vProperty && vProperty.property_address">
                      <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                    >
                    <p
                      class="mr-2"
                      v-if="vProperty && vProperty.property_address"
                    >
                      {{ vProperty.street_1 }},
                      <br v-if="vProperty.street_2 || vProperty.unit_no" />
                      <template v-if="vProperty.street_2">
                        {{ vProperty.street_2 }},
                      </template>
                      <template v-if="vProperty.unit_no">
                        {{ vProperty.unit_no }},
                      </template>
                      <br />
                      {{ vProperty.country }}
                      <template
                        v-if="vProperty && vProperty.zip_code != '000000'"
                      >
                        , {{ vProperty.zip_code }}
                      </template>
                    </p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select site location</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3 align-center">
                    <v-flex md3
                      ><label class="my-0 font-weight-600"
                        >Contact Details</label
                      ></v-flex
                    >
                    <v-flex md9 class="position-relative">
                      <v-icon
                        small
                        v-on:click="openDialog('property-contact')"
                        class="py-0 my-0"
                        color="cyan"
                        style="position: absolute; top: -8px"
                        >mdi-pencil</v-icon
                      >
                    </v-flex>
                  </v-layout>

                  <template v-if="vPropertyContact?.id">
                    <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                    <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                  </template>
                  <template v-else-if="vCustomer?.id">
                    <p class="mb-0 red--text">Please select contact person</p>
                  </template>
                  <template v-else>
                    <p class="mb-0 red--text">Please select customer</p>
                  </template>

                  <v-layout class="mt-3">
                    <v-flex md4
                      ><label class="my-0 font-weight-600"
                        >Email Notification
                      </label></v-flex
                    >
                    <v-flex md8>
                      <v-switch
                        v-model="dbVisit.property_person_notify"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </v-flex>
                  </v-layout>
                </v-col>
                <v-col md="12">
                  <!-- <v-layout class="mt-3"> -->
                  <label class="my-0 font-weight-600"
                    >Reminder Notification
                  </label>
                  <!--    </v-layout> -->
                </v-col>
                <v-col md="12">
                  <div class="d-flex" style="margin-top: -15px">
                    <v-checkbox
                      class="mr-2"
                      color="cyan white--text"
                      :disabled="getDisableReminder()"
                      v-model="dbVisit.admin_reminder"
                      label="Admin"
                    />
                    <v-checkbox
                      class="mr-2"
                      color="cyan white--text"
                      :disabled="getDisableReminder()"
                      v-model="dbVisit.customer_reminder"
                      label="Customer"
                    />
                    <v-checkbox
                      class="mr-2"
                      color="cyan white--text"
                      :disabled="getDisableReminder()"
                      v-model="dbVisit.technician_reminder"
                      label="Technician"
                    />
                  </div>
                </v-col>
              </v-row>
              <label for="visit-title" class="">Contract</label>
              <v-autocomplete
                id="contract"
                dense
                v-model="dbVisit.contract"
                :items="vOption.contract_list"
                item-text="barcode"
                v-on:click:clear="contractDetail = {}"
                clearable
                item-color="cyan"
                v-on:change="getContractDetail($event)"
                item-value="id"
                filled
                hide-details
                placeholder="Contract"
                solo
                flat
                color="cyan"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      v-html="'No Contract(s) Found.'"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <Barcode :barcode="item.barcode"></Barcode>
                        <v-chip
                          color="red white--text"
                          label
                          samll
                          class="custom-barcode ml-2"
                          outlined
                        >
                          <span class="font-size-16 font-weight-500">
                            {{ formatDate(item.contract_start) }} to
                            {{ formatDate(item.contract_end) }} ({{
                              item.duration_value
                            }}
                            {{ item.duration_type }})</span
                          >
                        </v-chip>
                        <label class="custom-badge"> Total: </label>
                        <v-badge
                          color="green"
                          :content="item.total_service"
                          class="mr-4"
                        >
                          <!--   Item Two -->
                        </v-badge>
                        <label class="custom-badge"> Used: </label>
                        <template v-if="item.visit_count > 0">
                          <v-badge
                            color="orange"
                            :content="item.visit_count"
                            class="mr-4"
                          >
                            <!--   Item Two -->
                          </v-badge>
                        </template>
                        <template v-else>
                          <v-badge color="orange" content="0" class="mr-4">
                            <!--   Item Two -->
                          </v-badge>
                        </template>

                        <label class="custom-badge"> Balance: </label>
                        <template v-if="getBalance(item) > 0">
                          <v-badge color="red" :content="getBalance(item)">
                          </v-badge>
                        </template>
                        <template v-else>
                          <v-badge color="red" content="0"> </v-badge>
                        </template>
                        <!-- <p class="mb-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue" small
                        >mdi-bag-checked</v-icon
                      >
                      {{ item.customer.display_name }}
                    </p>
                    <p class="mb-0 font-weight-500 font-size-16">
                      <v-icon class="chip-custom-blue" small
                        >mdi-account</v-icon
                      >
                      {{ item.customer.default_person.display_name }}
                    </p>
                    <p
                      class="mb-0 font-weight-500 font-size-16"
                      v-if="item.customer.single_property"
                    >
                      <v-icon class="chip-custom-blue" small
                        >mdi-map-marker-outline</v-icon
                      >
                      {{
                        item.customer &&
                        item.customer.single_property &&
                        item.customer.single_property.street_1
                      }}
                      {{ item.customer.single_property.street_2 }}
                      {{ item.customer.single_property.zip_code }}
                    </p> -->
                      </v-list-item-title>
                      <!--   <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.company_name }}</v-list-item-title
                          > -->
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-row v-if="contractDetail?.contract_start">
                <v-col md="6">
                  <v-layout class="mt-3 align-center">
                    <v-flex md12
                      ><label class="my-0 custom-title"
                        >Contract Details</label
                      ></v-flex
                    >
                  </v-layout>
                  <template v-if="contractDetail?.contract_start">
                    <table width="100%">
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Contract #
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label class="m-0" v-if="contractDetail.barcode">{{
                            contractDetail.barcode
                          }}</label>
                          <em class="text-muted" v-else> no barcode</em>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Title
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label class="m-0" v-if="contractDetail.job_title">{{
                            contractDetail.job_title
                          }}</label>
                          <em class="text-muted" v-else> no title</em>
                        </td>
                      </tr>
                      <tr v-if="contractDetail.is_contract">
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Contract Duration
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <v-chip
                            small
                            color="red white--text"
                            label
                            class=""
                            outlined
                          >
                            <span class="font-size-16 font-weight-500">
                              {{ formatDate(contractDetail.contract_start) }} to
                              {{
                                formatDate(contractDetail.contract_end)
                              }}</span
                            >
                          </v-chip>
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Reference #
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label class="m-0" v-if="contractDetail.reference">{{
                            contractDetail.reference
                          }}</label>
                          <em class="text-muted" v-else> no reference</em>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
                <v-col md="6">
                  <v-layout class="mt-3 align-center">
                    <v-flex md12
                      ><label class="my-0 custom-title"></label
                    ></v-flex>
                  </v-layout>
                  <template v-if="contractDetail?.contract_start">
                    <table width="100%">
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Sales representatives
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label
                            class="m-0"
                            v-if="
                              contractDetail &&
                              contractDetail.sales_representatives &&
                              contractDetail.sales_representatives.full_name
                            "
                            >{{
                              contractDetail.sales_representatives.full_name
                            }}</label
                          >
                          <em class="text-muted" v-else>
                            no Sales representatives</em
                          >
                        </td>
                      </tr>
                      <tr>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          Prepared by
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-500"
                        >
                          <label
                            class="m-0"
                            v-if="contractDetail.prepared_by"
                            >{{ contractDetail.prepared_by }}</label
                          >
                          <em class="text-muted" v-else> no Prepared by</em>
                        </td>
                      </tr>

                      <tr v-if="contractDetail.is_contract">
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600 d-flex"
                        >
                          Total/Used/Balance Services
                        </td>
                        <td
                          valign="middle"
                          class="py-1 font-size-18 font-weight-600"
                        >
                          <label
                            class="custom-badge-new"
                            style="margin-left: -6px !important"
                          >
                          </label>
                          <v-badge
                            color="green"
                            :content="contractDetail.total_service"
                            class="mr-4"
                          >
                          </v-badge>
                          <label class="custom-badge-new"> </label>
                          <v-badge
                            color="orange"
                            :content="contractDetail.visit_count"
                            class="mr-4"
                          >
                          </v-badge>
                          <label class="custom-badge-new"> </label>
                          <template v-if="getBalance(contractDetail) > 0">
                            <v-badge
                              color="red"
                              :content="getBalance(contractDetail)"
                            >
                            </v-badge>
                          </template>
                          <template v-else>
                            <v-badge color="red" content="0"> </v-badge>
                          </template>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td width="20%">
                    <div class="d-flex ml-1 mt-4">
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbVisit.is_billable == 1)"
                        v-on:click="dbVisit.is_billable = 1"
                        :value="1"
                        color="green darken-3 white--text"
                        style="font-size: 15px; margin-right: 20px"
                        >Billable</v-btn
                      >
                      <v-btn
                        style="font-size: 15px; margin-right: 20px"
                        class="mx-2"
                        depressed
                        tile
                        :outlined="!(dbVisit.is_billable == 0)"
                        v-on:click="dbVisit.is_billable = 0"
                        :value="0"
                        color="red darken-4 white--text"
                        >Non Billable</v-btn
                      >
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <template v-if="scheduleTab == 'recurring'">
                    <td width="20%">
                      <label for="job-id">Job # </label>
                    </td>
                    <td>
                      <v-text-field
                        id="job-id"
                        dense
                        filled
                        readonly
                        hide-details
                        placeholder="Job #"
                        solo
                        flat
                        :value="
                          vOption && vOption.barcode ? `${vOption.barcode}` : ''
                        "
                        color="cyan"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td width="20%">
                      <label for="job-id">Visit # / Job # </label>
                    </td>
                    <td>
                      <v-text-field
                        id="job-id"
                        dense
                        filled
                        readonly
                        hide-details
                        placeholder="Visit # / Job #"
                        solo
                        flat
                        :value="
                          vOption && vOption.visit_barcode
                            ? `${vOption.visit_barcode} / ${vOption.barcode}`
                            : visitBarcode
                            ? `${visitBarcode} / ${ticketBarcode}`
                            : ''
                        "
                        color="cyan"
                      />
                    </td>
                  </template>
                </tr>

                <tr>
                  <td width="20%">
                    <label for="priority">Priority</label>
                  </td>
                  <td>
                    <div class="d-flex ml-1">
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbVisit.priority == 1)"
                        v-on:click="dbVisit.priority = 1"
                        value="1"
                        color="red darken-3 white--text"
                        >High</v-btn
                      >
                      <v-btn
                        class="mx-2"
                        depressed
                        tile
                        :outlined="!(dbVisit.priority == 2)"
                        v-on:click="dbVisit.priority = 2"
                        value="2"
                        color="orange darken-4 white--text"
                        >Medium</v-btn
                      >
                      <v-btn
                        depressed
                        tile
                        :outlined="!(dbVisit.priority == 3)"
                        v-on:click="dbVisit.priority = 3"
                        value="3"
                        color="light-green darken-3 white--text"
                        >Low</v-btn
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="reference-no">Reference #</label>
                  </td>
                  <td>
                    <v-text-field
                      id="reference-no"
                      dense
                      filled
                      v-model="dbVisit.reference"
                      hide-details
                      placeholder="Reference #"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="job-type" class="required">Job Type</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="job-type"
                      dense
                      multiple
                      v-model="dbVisit.job_type"
                      :items="vOption.ticket_types"
                      item-text="value"
                      item-color="cyan"
                      item-value="value"
                      filled
                      hide-details
                      placeholder="Job Type"
                      solo
                      flat
                      color="cyan"
                      :rules="[
                        validateRules.required(dbVisit.job_type, 'Job Type'),
                      ]"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="tag">Tag</label>
                  </td>
                  <td>
                    <TagAutoComplete type="visit" v-model="dbVisit.tags" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="po-number">PO Number</label>
                  </td>
                  <td>
                    <v-text-field
                      id="po-number"
                      dense
                      v-model="dbVisit.po_number"
                      filled
                      hide-details
                      placeholder="PO Number"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="payment-mode">Payment Mode</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="payment-mode"
                      dense
                      v-model="dbVisit.payment_mode"
                      :items="vOption.payment_modes"
                      item-text="value"
                      item-color="cyan"
                      item-value="id"
                      filled
                      hide-details
                      placeholder="Payment Mode"
                      solo
                      flat
                      color="cyan"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="quotation">Project</label>
                  </td>
                  <td>
                    <v-autocomplete
                      id="project"
                      dense
                      v-model="dbVisit.project"
                      :items="vOption.project_list"
                      item-text="barcode"
                      v-on:change="getAllMilestones($event)"
                      item-color="cyan"
                      item-value="id"
                      filled
                      hide-details
                      placeholder="Project"
                      solo
                      flat
                      color="cyan"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Project(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                            >
                              <span class="font-weight-600 text-capitalize">
                                {{ item.name }}</span
                              >
                              <div>
                                <Barcode :barcode="item.barcode"></Barcode>
                                <v-chip
                                  color="red white--text"
                                  label
                                  samll
                                  class="custom-barcode ml-2"
                                  outlined
                                >
                                  <span class="font-size-16 font-weight-500">
                                    {{ formatDate(item.started_at) }} to
                                    {{ formatDate(item.deadline) }}
                                  </span>
                                </v-chip>
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="milestone">Milestone</label>
                  </td>
                  <td>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="milestoneList"
                      :disabled="formLoading"
                      :loading="formLoading"
                      v-model.trim="dbVisit.milestones"
                      label="Milestones"
                      solo
                      flat
                      item-color="cyan"
                      item-text="barcode"
                      item-value="id"
                      hide-details
                      multiple
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.barcode }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text font-size-12"
                        >
                          (+{{ dbVisit.milestones.length - 1 }} others)
                        </span>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Milestone Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" class="job-schedule" ref="engineer">
              <v-tabs
                v-model="scheduleTab"
                background-color="transparent"
                centered
                color="cyan"
                icons-and-text
                class="mb-3"
                v-on:change="setScheduleType()"
              >
                <v-tab
                  href="#one-off"
                  :disabled="do_update == 1 ? true : false"
                >
                  <div class="custom-tab-title d-flex">
                    <div class="custom-tab-icon pr-2">
                      <v-icon left large color="gray">mdi-calendar</v-icon>
                    </div>
                    <div class="text-left">
                      <h3 class="text-capitalize">One-Off Job</h3>
                      <p class="m-0 text-capitalize">
                        A one time job with one or more visits
                      </p>
                    </div>
                  </div>
                </v-tab>
                <v-tab
                  href="#recurring"
                  :disabled="do_update == 1 ? true : false"
                >
                  <div class="custom-tab-title d-flex">
                    <div class="custom-tab-icon pr-2">
                      <v-icon left large color="gray"
                        >mdi-calendar-multiple</v-icon
                      >
                    </div>
                    <div class="text-left">
                      <h3 class="text-capitalize">Recurring Job</h3>
                      <p class="m-0 text-capitalize">
                        A contract job with repeating visits
                      </p>
                    </div>
                  </div>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="scheduleTab">
                <v-tab-item value="one-off">
                  <Schedule :visit-data="visitData" />
                </v-tab-item>
                <v-tab-item value="recurring">
                  <RecurringSchedule
                    :ticket-details="updatedTicket"
                    :visit-details="updatedVisit"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col cols="12" ref="lineItem">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Line Items
                  </h3>
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <LineItemV2
                    is-job
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :ticket-detail="updatedTicket"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Terms &amp; Conditions
                  </h3>
                </v-card-title>
                <v-card-text ref="term-condition" class="p-6 font-size-16">
                  <TinyMCE v-model="dbVisit.term_conditions" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" v-if="false">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Reminder Notifications
                  </h3>
                </v-card-title>
                <EmailReminder
                  v-on:update:data="updateData($event)"
                  parent-type="visit"
                  :parent-id="updateTicketId"
                >
                </EmailReminder>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat class="custom-grey-border remove-border-radius">
                <v-card-title class="headline grey lighten-4">
                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                    Notes &amp; Attachments
                  </h3>
                </v-card-title>
                <v-card-text ref="term-condition" class="p-6 font-size-16">
                  <v-row>
                    <v-col cols="6">
                      <p class="font-weight-600 mb-2">
                        Client Note (visible in PDF)
                      </p>
                      <TinyMCE v-model="dbVisit.client_remark" />
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex">
                        <span class="font-weight-600">
                          Admin Note (internal use only)
                        </span>
                        <v-spacer></v-spacer>
                        <span
                          class="font-weight-600 d-flex"
                          style="margin-top: -3px !important"
                        >
                          <v-checkbox
                            color="cyan"
                            v-model="dbVisit.is_remark_show"
                          ></v-checkbox>
                          <span class="mt-1">Show In Application</span>
                        </span>
                      </div>
                      <!--  <p class="font-weight-600 mb-2">
                    Admin Note (internal use only)
                  </p> -->
                      <TinyMCE v-model="dbVisit.admin_remark" />
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <p class="font-weight-600 mb-1">Attachments</p>
                      <table width="100%" style="table-layout: fixed">
                        <tr
                          v-for="(row, index) in vPdfAttachments"
                          :key="`create-visit-attachment-${index}`"
                        >
                          <td class="px-0 py-0">
                            <v-file-input
                              hide-details
                              class="px-0"
                              dense
                              filled
                              solo
                              flat
                              v-model="row.file"
                              placeholder="Select File"
                              accept="image/png, image/jpeg, image/bmp"
                              prepend-icon=""
                              prepend-inner-icon="$file"
                              v-on:change="
                                updateFileName('vPdfAttachments', index, $event)
                              "
                              color="cyan"
                            />
                          </td>
                          <td class="py-0">
                            <v-text-field
                              hide-details
                              class="px-0"
                              v-model="row.name"
                              placeholder="Name"
                              dense
                              filled
                              :suffix="row.ext ? `.${row.ext}` : ''"
                              solo
                              flat
                              color="cyan"
                            />
                          </td>
                          <td class="px-0 py-0" width="10%" align="center">
                            <v-btn
                              v-on:click="removeFile('vPdfAttachments', index)"
                              icon
                              class="w-100"
                              color="red lighten-1"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 pb-0" colspan="3">
                            <v-btn
                              color="cyan white--text"
                              class="custom-bold-button"
                              depressed
                              v-on:click="addFile('vPdfAttachments')"
                              tile
                              >Add More</v-btn
                            >
                          </td>
                        </tr>
                      </table>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <p class="font-weight-600 mb-1">
                        Attachments (internal use only)
                      </p>
                      <table width="100%" style="table-layout: fixed">
                        <tr
                          v-for="(row, index) in vAttachments"
                          :key="`create-visit-attachment-${index}`"
                        >
                          <td class="px-0 py-0">
                            <v-file-input
                              hide-details
                              class="px-0"
                              dense
                              filled
                              solo
                              flat
                              v-model="row.file"
                              placeholder="Select File"
                              prepend-icon=""
                              prepend-inner-icon="$file"
                              v-on:change="
                                updateFileName('vAttachments', index, $event)
                              "
                              color="cyan"
                            />
                          </td>
                          <td class="py-0">
                            <v-text-field
                              hide-details
                              class="px-0"
                              v-model="row.name"
                              placeholder="Name"
                              dense
                              filled
                              :suffix="row.ext ? `.${row.ext}` : ''"
                              solo
                              flat
                              color="cyan"
                            />
                          </td>
                          <td class="px-0 py-0" width="10%" align="center">
                            <v-btn
                              v-on:click="removeFile('vAttachments', index)"
                              icon
                              class="w-100"
                              color="red lighten-1"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 pb-0" colspan="3">
                            <v-btn
                              color="cyan white--text"
                              class="custom-bold-button"
                              depressed
                              v-on:click="addFile('vAttachments')"
                              tile
                              >Add More</v-btn
                            >
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <RouteDialog
          :dialog="routeDialog"
          :db-visit="dbVisit"
          :schedule-tab="scheduleTab"
          :loading="formLoading"
          :do-update="do_update"
          :updateScheduleChanged="updateScheduleChanged"
          :updateTeamChanged="updateTeamChanged"
          :updateFormChanged="updateFormChanged"
          v-on:close="routeDialog = false"
          v-on:scroll-to="scrollTo($event)"
          v-on:submit="onSubmit($event)"
        />
        <CustomerSelect
          :dialog="customerDialog"
          v-on:close="closeDialogWithData"
        />
        <PersonSelect
          :dialog="contactDialog"
          v-on:close="contactDialog = false"
          :type="contactType"
        />
        <AddressSelect
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          :type="propertyType"
          :label="propertyLabel"
        />
      </v-container>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RouteDialog from "@/view/components/RouteDialog.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import RecurringSchedule from "@/view/pages/job/visit-partials/Recurring-Schedule.vue";
import Schedule from "@/view/pages/job/visit-partials/Schedule.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import EmailReminder from "@/view/pages/job/partials/Email-Reminder.vue";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
/*import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import { JobEventBus } from "@/core/lib/job/job.lib";*/
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import {
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  SET_CUSTOMER,
  RESET_STATE,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_VISIT_TEAM,
  SET_AVAILABLE_TEAM,
  RESET_CREATE_STATE,
  SET_EDIT_RECURRING_SCHEDULE,
  UPDATE_VISIT_TEAM,
  SET_FLAGS,
} from "@/core/services/store/visit.module";
import { UPLOAD, POST, GET, QUERY } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";
import ObjectPath from "object-path";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ManageVisits from "@/core/plugins/visit.plugin";

export default {
  name: "create-job",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      formValid: false,
      formLoading: false,
      do_update: 0,
      productDialog: false,
      scheduleTab: "one-off",
      visitBarcodes: [],
      customerDialog: false,
      visitData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      duplicate: 0,
      contactType: null,
      updateTicketId: 0,
      updateVisitId: 0,
      contactDialog: false,
      update_loading: false,
      dbLineItems: [],
      dbEquipments: [],
      milestoneList: [],
      updatedVisit: {},
      updatedTicket: {},
      routeDialog: false,
      visitBarcode: "",
      ticketBarcode: "",
      all_reminder: false,
      oneMonth_reminder: false,
      fifteenday_reminder: false,
      oneweek_reminder: false,
      oneday_reminder: false,
      /*    reminderTab:'customer', */
      onday_reminder: false,
      ondayafter_reminder: false,
      reminderNotification: {},
      updateTargetType: "upcomming",
      dbVisit: {
        title: null,
        description: null,
        is_remark_show: false,
        property_person_notify: true,
        technician_reminder: true,
        customer_reminder: true,
        admin_reminder: true,
        billing_person_notify: true,
        billing_contact_person: null,
        property_contact_person: null,
        priority: "3",
        is_billable: 1,
        reference: null,
        job_type: null,
        tags: null,
        po_number: null,
        payment_mode: null,
        project: null,
        contract: null,
        invoice: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        milestones: [],
      },
      contractDetail: {},
      vCreated: false,
      vFileUploaded: false,
      vTicket: {},
      vVisit: {},
      vAttachments: [{ file: null, name: null, ext: null }],
      vPdfAttachments: [{ file: null, name: null, ext: null }],
      updateTeamChanged: false,
      updateFormChanged: false,
      updateScheduleChanged: false,
      scheduleChangeConfirmed: false,
    };
  },
  watch: {
    vCustomerId(param) {
      if (param) {
        this.getVisitOption();
      }
    },
  },
  methods: {
    getDisableReminder() {
      if (this.vOption?.reminder_notification == 0) {
        this.dbVisit.admin_reminder = false;
        this.dbVisit.customer_reminder = false;
        this.dbVisit.technician_reminder = false;
        return true;
      }
      return false;
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    closeDialogWithData() {
      (this.dbVisit.contract = null),
        (this.contractDetail = null),
        (this.dbVisit.project = null),
        (this.dbVisit.milestones = null),
        (this.customerDialog = false);
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.dbVisit.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.dbVisit.description = trimValue;
        e.preventDefault();
      }
    },
    updateData(reminderAlert) {
      this.reminderNotification = reminderAlert;
    },
    manageLimit(e) {
      if (this.dbVisit.description && this.dbVisit.description.length > 199) {
        e.preventDefault();
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Site Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      if (!this.vSchedule.start_time) {
        ErrorEventBus.$emit("update:error", "Please Select Start Time.");
        return false;
      }
      if (!this.vSchedule.end_time) {
        ErrorEventBus.$emit("update:error", "Please Select End Time.");
        return false;
      }
      const allHeaders = this.vSelectedLineItem.every(
        (obj) => obj.type === "header"
      );
      if (
        !this.vSelectedLineItem ||
        !this.vSelectedLineItem.length ||
        allHeaders
      ) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createVisitForm.validate();

      const formErrors = this.validateForm(this.$refs.createVisitForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      const visitInstance = new ManageVisits();
      this.updateScheduleChanged = false;
      this.updateTeamChanged = false;
      this.updateFormChanged = false;

      if (
        this.updateVisitId > 0 &&
        this.scheduleTab == "recurring" &&
        !this.scheduleChangeConfirmed
      ) {
        if (
          !visitInstance.scheduleCompare(
            this.updatedTicket,
            this.vRecurringSchedule
          )
        ) {
          this.updateScheduleChanged = true;
          if (!visitInstance.teamCompare(this.vUpdateTeam, this.vTeam)) {
            this.updateTeamChanged = true;
          }
        }
        if (!visitInstance.teamCompare(this.vUpdateTeam, this.vTeam)) {
          this.updateTeamChanged = true;
        }
        if (!visitInstance.visitCompare(this.visitData, this.dbVisit)) {
          this.updateFormChanged = true;
        }
      }

      if (validateStatus) {
        this.routeDialog = true;
      }
    },
    scrollTo(param) {
      this.routeDialog = false;
      this.$nextTick(() => {
        const el = this.$refs[param];
        el?.scrollIntoView({ behavior: "smooth" });
      });
    },
    updateFileName(type, index, param) {
      if (param && param instanceof File) {
        this[type][index].name = param?.name?.split(".")[0];
        this[type][index].ext = param?.name?.split(".")[1];
      } else {
        this[type][index].name = null;
        this[type][index].ext = null;
      }
    },
    addFile(param) {
      this[param].push({ file: null, name: null, ext: null });
    },
    removeFile(param, index) {
      this[param].splice(index, 1);
      if (!this[param].length) {
        this.addFile(param);
      }
    },
    setScheduleType() {
      this.$store.dispatch(SET_SCHEDULE_TYPE, this.scheduleTab);
    },
    openDialog(type) {
      /*   if (this.scheduleTab == "recurring" && this.updateVisitId > 0) {
        return false;
      } */
      this.customerDialog = false;
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.customerDialog = true;
      } else {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    getVisitOption() {
      this.$store
        .dispatch(GET_OPTION, {
          visit: toSafeInteger(this.updateVisitId),
          job: toSafeInteger(this.updateTicketId),
          do_update: toSafeInteger(this.do_update),
        })
        .then(() => {
          if (!this.do_update && !this.updateVisitId) {
            this.dbVisit.term_conditions = this.vOption.terms_conditions;
          }
          if (!this.updateTicketId) {
            if (this.scheduleTab == "one-off") {
              this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Visit", route: "visit" },
                { title: "Create" },
                { title: this.vOption && this.vOption.visit_barcode },
              ]);
            } else {
              this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Visit", route: "visit" },
                { title: "Create" },
                { title: ObjectPath.get(this.vOption, "barcode", null) },
              ]);
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          //
        });
    },
    getBalance(row) {
      let balance = Number(row.total_service) - Number(row.visit_count);
      return balance > 0 ? Number(balance) : 0;
    },
    uploadFile(type) {
      let formData = new FormData();

      for (let i = 0; i < this.vAttachments.length; i++) {
        if (
          this.vAttachments[i].file &&
          this.vAttachments[i].file instanceof File
        ) {
          formData.append(`attachments[${i}][file]`, this.vAttachments[i].file);
          formData.append(`attachments[${i}][name]`, this.vAttachments[i].name);
        }
      }

      for (let z = 0; z < this.vPdfAttachments.length; z++) {
        if (
          this.vPdfAttachments[z].file &&
          this.vPdfAttachments[z].file instanceof File
        ) {
          formData.append(
            `pdf-attachments[${z}][file]`,
            this.vPdfAttachments[z].file
          );
          formData.append(
            `pdf-attachments[${z}][name]`,
            this.vPdfAttachments[z].name
          );
        }
      }

      let ticketId = this.vTicket?.id;
      let visitId = this.vVisit?.id;

      let vCustomerId = this.vCustomerId;

      this.formLoading = true;

      this.$store
        .dispatch(UPLOAD, {
          url: `visit-v1/${ticketId}/uploads`,
          data: formData,
        })
        .then(() => {
          this.vFileUploaded = true;
          if (type == "new") {
            this.$store.dispatch(RESET_STATE);
            this.$router
              .push(
                this.getDefaultRoute("job.create", {
                  query: { t: Number(new Date()) },
                })
              )
              .then(() => {
                this.$store.dispatch(SET_CUSTOMER, { id: vCustomerId });
              });
          }
          if (type == "detail") {
            this.$store.dispatch(RESET_STATE);
            this.$router.push(
              this.getDefaultRoute("visit.detail", {
                params: { id: visitId },
                query: { t: Number(new Date()) },
              })
            );
          }
          if (type == "listing") {
            this.$store.dispatch(RESET_STATE);
            this.$router.push(
              this.getDefaultRoute("visit", {
                query: { t: Number(new Date()), htx: ticketId },
              })
            );
          }
        })
        .catch(() => {
          this.vFileUploaded = false;
        })
        .finally(() => {
          this.routeDialog = false;
          this.formLoading = false;
        });
    },
    getContractDetail(id) {
      this.loading = true;
      if (id) {
        this.$store
          .dispatch(GET, { url: `contract/${id}` })
          .then(({ data }) => {
            this.contractDetail = data;
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /* getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }, */
    onSubmit(type) {
      if (this.vCreated) {
        this.uploadFile();
        return false;
      }
      const request = {
        ...this.dbVisit,
        dates: this.vDates,
        reminder_notification: this.reminderNotification,
        do_update: toSafeInteger(this.do_update),
        updatedTikcetId: this.updateTicketId,
        updatedVisitId: this.updateVisitId,
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        tab: this.scheduleTab,
        update_target_type: this.vUpdateTarget,
        update_schedule_changed: this.updateScheduleChanged,
        update_team_changed: this.updateTeamChanged,
        update_form_changed: this.updateFormChanged,
        "one-off": this.scheduleTab == "one-off" ? this.vSchedule : {},
        recurring:
          this.scheduleTab == "recurring" ? this.vRecurringSchedule : {},
        "line-item": this.vSelectedLineItem,
        calculation: this.vCalculations,
        team: this.vTeam,
        visitBarcodes: this.visitBarcodes ? this.visitBarcodes : [],
      };

      this.formLoading = true;
      this.$store
        .dispatch(POST, { url: "visit-v1", data: request })
        .then(({ data }) => {
          this.forcePush = true;
          this.vCreated = true;
          this.vTicket = data.ticket;
          this.vVisit = data.visit;
          this.uploadFile(type);
          this.scheduleChangeConfirmed = true;
        })
        .catch(() => {
          this.vCreated = false;
          this.formLoading = false;
        });
    },
    getAllMilestones(id) {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `project-milestone/${id}` })
        .then(({ data }) => {
          _this.milestoneList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    initUpdate() {
      this.update_loading = true;
      this.$store
        .dispatch(QUERY, {
          url: `job/update?job-id=${this.updateTicketId}`,
          data: {
            visitid: this.updateVisitId,
          },
        })
        .then((response) => {
          let engineers = ObjectPath.get(response, "data.engineers", []);

          this.updatedVisit = ObjectPath.get(response, "data.visit", {});

          this.$store.commit(SET_EDIT_RECURRING_SCHEDULE, {
            ...this.updatedVisit,
            engineers,
          });

          this.updatedTicket = response.data.ticket;

          this.visitBarcodes = ObjectPath.get(
            response,
            "data.visit_barcode",
            []
          );

          this.ticketBarcode = ObjectPath.get(
            response,
            "data.ticket.barcode",
            ""
          );
          this.visitBarcode = ObjectPath.get(
            response,
            "data.visitDb.barcode",
            ""
          );
          /*  this.updatedVisitId = ObjectPath.get(response, "data.visitDb.id", null); */
          this.dbVisit.reference = ObjectPath.get(
            response,
            "data.ticket.reference",
            null
          );
          this.visitData = response.data.visitDb;

          this.dbVisit.title = ObjectPath.get(
            response,
            "data.visitDb.title",
            null
          );
          this.dbVisit.attention = ObjectPath.get(
            response,
            "data.ticket.attention",
            null
          );
          this.dbVisit.po_number = ObjectPath.get(
            response,
            "data.ticket.po_number",
            null
          );
          this.dbVisit.payment_mode = ObjectPath.get(
            response,
            "data.ticket.payment_mode",
            0
          );
          this.dbVisit.description = ObjectPath.get(
            response,
            "data.ticket.description",
            null
          );
          this.dbVisit.term_conditions = ObjectPath.get(
            response,
            "data.ticket.term_conditions",
            null
          );
          this.dbVisit.admin_remark = ObjectPath.get(
            response,
            "data.ticket.admin_remark",
            null
          );
          this.dbVisit.client_remark = ObjectPath.get(
            response,
            "data.ticket.client_remark",
            null
          );

          this.dbVisit.tags = ObjectPath.get(response, "data.ticket.tags", []);

          this.visitBarcodes = ObjectPath.get(
            response,
            "data.visit_barcode",
            []
          );

          this.dbVisit.job_type = ObjectPath.get(
            response,
            "data.ticket.ticket_type",
            []
          );

          this.dbVisit.project = ObjectPath.get(
            response,
            "data.visitDb.project",
            0
          );
          this.dbVisit.is_remark_show = ObjectPath.get(
            response,
            "data.ticket.is_remark_show",
            false
          );
          if (this.dbVisit.project > 0) {
            this.getAllMilestones(this.dbVisit.project);
          }
          this.dbVisit.milestones = response?.data?.visitDb?.milestone ?? [];
          this.dbVisit.admin_reminder = ObjectPath.get(
            response,
            "data.visitDb.admin_reminder",
            false
          );
          this.dbVisit.technician_reminder = ObjectPath.get(
            response,
            "data.visitDb.technician_reminder",
            false
          );
          this.dbVisit.customer_reminder = ObjectPath.get(
            response,
            "data.visitDb.customer_reminder",
            false
          );
          let skills = ObjectPath.get(response, "data.ticket.skills", []);
          this.dbVisit.skill = [];
          for (let i = 0; i < skills.length; i++) {
            let found = this.lodash.find(this.skillList, { value: skills[i] });
            if (found) {
              this.dbVisit.skill.push(found);
            }
          }

          this.dbVisit.property_person_notify = ObjectPath.get(
            response,
            "data.ticket.property_person_notify",
            0
          );
          this.dbVisit.billing_person_notify = ObjectPath.get(
            response,
            "data.ticket.billing_person_notify",
            0
          );
          this.dbVisit.priority = ObjectPath.get(
            response,
            "data.ticket.priority",
            1
          );
          this.dbVisit.is_billable = ObjectPath.get(
            response,
            "data.ticket.is_billable",
            1
          );
          this.dbVisit.contract = ObjectPath.get(
            response,
            "data.ticket.contract",
            0
          );
          this.scheduleTab =
            ObjectPath.get(response, "data.ticket.type", 1) == 1
              ? "one-off"
              : "recurring";
          this.dbVisit.total_occurence = ObjectPath.get(
            response,
            "data.ticket.total_frequency",
            0
          );
          if (this.scheduleTab == "one-off") {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Visit", route: "visit" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.visitDb.barcode", null) },
            ]);
          } else {
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Visit", route: "visit" },
              { title: "Update" },
              { title: ObjectPath.get(response, "data.visitDb.barcode", null) },
            ]);
          }

          this.dbVisit.barcode = ObjectPath.get(
            response,
            "data.ticket.barcode",
            null
          );
          this.dbVisit.contract = ObjectPath.get(
            response,
            "data.ticket.contract",
            null
          );
          this.dbVisit.visit_barcode = ObjectPath.get(
            response,
            "data.visitDb.barcode",
            null
          );

          if (ObjectPath.get(response, "data.ticket.type", 0) == 1) {
            this.$store.commit(SET_SCHEDULE_TYPE, "one-off");
          } else if (ObjectPath.get(response, "data.ticket.type", 0) == 2) {
            this.$store.commit(SET_SCHEDULE_TYPE, "recurring");
          }

          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.visit.ticket.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.visit.ticket.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.visit.ticket.property_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.visit.ticket.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.visit.ticket.billing_person", {})
          );

          this.$nextTick(() => {
            this.$store.commit(SET_AVAILABLE_TEAM, engineers);
            this.$nextTick(() => {
              this.$store.commit(SET_VISIT_TEAM, {
                engineer: this.lodash.filter(engineers, {
                  assign_status: true,
                }),
                email_team: !!Number(
                  ObjectPath.get(response, "data.ticket.notify_engineer", "0")
                ),
              });
            });
            this.$nextTick(() => {
              this.$store.commit(UPDATE_VISIT_TEAM, {
                engineer: this.lodash.filter(engineers, {
                  assign_status: true,
                }),
                email_team: !!Number(
                  ObjectPath.get(response, "data.ticket.notify_engineer", "0")
                ),
              });
            });
          });
          this.$store.commit(SET_FLAGS, "upcomming");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.update_loading = false;
        });
    },
  },
  components: {
    TinyMCE,
    TagAutoComplete,
    Barcode,
    CustomerSelect,
    CreateUpdateTemplate,
    RouteDialog,
    PersonSelect,
    AddressSelect,
    Schedule,
    RecurringSchedule,
    EmailReminder,
    LineItemV2,
  },
  mounted() {
    this.updateTicketId = toSafeInteger(this.$route.query.job_id);
    this.updateVisitId = toSafeInteger(this.$route.params.id);
    this.scheduleTab = this.$route.query.job_type;
    this.do_update = toSafeInteger(this.$route.query.do_update);
    this.duplicate = toSafeInteger(this.$route.query.duplicate);
    if (this.duplicate > 0) {
      this.$store.dispatch(SET_CUSTOMER, {
        id: toSafeInteger(this.$route.query.customer),
      });
      this.updateTicketId = this.duplicate;
      this.initUpdate();
    }
    if (this.duplicate == 0 && toSafeInteger(this.$route.query.customer) > 0) {
      this.$store.dispatch(SET_CUSTOMER, {
        id: toSafeInteger(this.$route.query.customer),
      });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visit", route: "visit" },
      { title: "Create" },
    ]);
    // this.getVisitOption();
    this.setScheduleType();
    if (this.updateTicketId && this.updateTicketId > 0 && this.do_update > 0) {
      this.initUpdate();
      /*  this.getVisitOption(); */
    }
    if (this.updateTicketId && this.updateTicketId > 0 && this.do_update > 0) {
      if (this.scheduleTab == "one-off") {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Visit", route: "visit" },
          { title: "Create" },
          { title: ObjectPath.get(this.dbVisit, "visit_barcode", null) },
        ]);
      } else {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Visit", route: "visit" },
          { title: "Create" },
          { title: ObjectPath.get(this.dbVisit, "barcode", null) },
        ]);
      }
    }
  },
  beforeMount() {
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } /* else if (_this.jobCreated) {
      next();
    }  */ else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  beforeDestroy() {
    this.$store.dispatch(RESET_CREATE_STATE);
  },
  computed: {
    ...mapGetters([
      "vOption",
      "vTeam",
      "vDates",
      "vSchedule",
      "vAvailableTeam",
      "vCalculations",
      "vRecurringSchedule",
      "vSelectedLineItem",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vEditRecurringSchedule",
      "vUpdateTarget",
      "vUpdateTeam",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.visit-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
