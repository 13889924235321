<template>
  <DetailTemplate
    :customClass="'equipment-detail detail-page'"
    v-if="getPermission('equipment:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-3"
          >
            {{ detail.name }}
          </h1>
        </template>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3 mr-2"
          style="font-size: 21px !important; font-weight: 700"
          >{{ detail.barcode }}</v-chip
        >
        <!--  <v-chip color="cyan" label text-color="white" class="mr-2">
          {{ detail.serial_no }}
        </v-chip> -->
        <v-chip
          label
          :color="detail.main_type == 'main_customer' ? '#4CAF50' : '#0D47A1'"
          class="text-white p-3 mr-2 mb-1"
        >
          <template v-if="detail.main_type == 'main_customer'">
            Customer
          </template>
          <template v-else> Company </template>
        </v-chip>
        <v-chip
          label
          :color="detail.type == 'personal' ? 'cyan' : 'red'"
          outlined
          class="text-white p-3 mr-3"
        >
          <template v-if="detail.type == 'personal'"> Customer/Own </template>
          <template v-else> Acom </template>
        </v-chip>
        <v-chip
          label
          color="#0d47a1"
          v-if="detail.main_type == 'main_company'"
          outlined
          class="p-3 mr-5"
        >
          Own Equipment
        </v-chip>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('equipment:update')">
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('equipment.update', {
              param: { id: equipment },
            })
          "
        >
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-tabs
          v-model="equipmentTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              v-if="getPermission(tab.permission + ':view')"
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="equipmentTab">
          <v-tab-item
            v-if="getPermission('equipment:view')"
            :value="'equipment'"
          >
            <Overview :detail="detail"></Overview>
          </v-tab-item>
          <v-tab-item v-if="getPermission('equipment:view')" :value="'image'">
            <Images
              :images="detail.product_images"
              :parent="detail.id"
              type="product"
              :isPageLoading="pageLoading"
            ></Images>
          </v-tab-item>
          <v-tab-item v-if="getPermission('warranty:view')" :value="'warranty'">
            <Warranty :detail="detail"></Warranty>
          </v-tab-item>
          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <Tickets :detail="detail" type="product"></Tickets>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('quotation:view')"
            :value="'quotation'"
          >
            <Quotations :detail="detail" type="product"></Quotations>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <Invoices :detail="detail" type="product"></Invoices>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="product"
              :type_id="detail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/equipment/detail/Overview";
import Warranty from "@/view/pages/equipment/detail/Warranty";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";

export default {
  data() {
    return {
      detail: new Object(),
      equipmentMoreAction: [
        {
          title: "Duplicate",
          action: "duplicate",
          icon: "mdi-content-duplicate",
          disabled: false,
        },
        {
          title: "Mark as Active",
          action: "mark_as_active",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as In-Active",
          action: "mark_as_inactive",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
      tabs: [
        {
          title: "Details",
          icon: "mdi-phone",
          key: "equipment",
          permission: "equipment",
          disabled: false,
        },
        /*  {
          title: "Images",
          icon: "mdi-phone",
          key: "image",
          permission: "equipment",
          disabled: false,
        },
        {
          title: "Warranty",
          icon: "mdi-credit-card",
          key: "warranty",
          permission: "warranty",
          disabled: false,
        },
        {
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        }, */
      ],
      formValid: true,
      formLoading: false,
      pageLoading: true,
      //equipmentTab: null,
      equipment: 0,
    };
  },
  components: {
    DetailTemplate,
    Overview,
    Warranty,
    Tickets,
    Images,
    Quotations,
    Invoices,
    InternalHistoryDetail,
  },
  methods: {
    patchEquipmentParams(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: [_this.equipment],
          },
        })
        .then(() => {
          _this.getEquipmentDetail();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.duplicateEquipment();
          break;
        case "mark_as_active":
          _this.patchEquipmentParams(1);
          break;
        case "mark_as_inactive":
          _this.patchEquipmentParams(0);
          break;
      }
    },
    duplicateEquipment() {
      this.$router.push(
        this.getDefaultRoute("equipment.create", {
          query: {
            duplicate: this.equipment,
          },
        })
      );
    },
    getEquipmentDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "equipment/" + _this.equipment,
        })
        .then(({ data }) => {
          _this.detail = data;

          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Equipment", route: "equipment" },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getEquipmentDetail();

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Equipment", route: "equipment" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.equipment = _this.$route.params.id;
    if (!_this.equipment || _this.equipment <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.equipmentTab = _this.$route.query.tab;
    }
  },
  computed: {
    equipmentTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (this.equipmentTab != val) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "equipment";
      },
    },
  },
};
</script>
