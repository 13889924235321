<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo" v-if="detail">
      <router-link to="/">
        <img
          style="width: 98%; margin-left: 4px"
          :src="detail.company_logo"
          alt="Logo"
        />
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            :src="$assetURL('media/svg/icons/Navigation/Angle-double-left.svg')"
          />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
.brand-logo img {
  width: 100px;
  margin-left: 15px;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import { QUERY } from "@/core/services/store/request.module";
/* import LocalService from "@/core/services/local.service";  */

export default {
  name: "KTBrand",
  data() {
    return {
      detail: {
        company_logo: null,
      },
    };
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);

    const _toggleObject = KTLayoutAsideToggle.getToggle();
    const _toggleState = localStorage.getItem("kt_aside_toggle_state");

    if (_toggleState == "on") {
      _toggleObject.toggleOn();
    }
    this.siteLogo();
  },
  methods: {
    /*  siteLogo() {
      const _this = this;
      let cache_company_logo = LocalService.getData("company_logo");

      if (_this.lodash.isEmpty(cache_company_logo) === false) {
        _this.company_logo = cache_company_logo;
      } else {
        this.$store
          .dispatch(GET, {
            url: "setting/branding",
          })
          .then(({ data }) => {
            _this.detail = data;
            if (data.company_logo) {
              LocalService.saveData("company_logo", data.company_logo);
              _this.company_logo = data.company_logo;
            } else {
              _this.company_logo = _this.default_companyLogo();
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      }
    }, */
    siteLogo() {
      const _this = this;
      _this.detail = new Object({
        company_logo: null,
      });
      _this.$store
        .dispatch(QUERY, {
          url: "setting/branding",
          data: { entity: 1 },
        })
        .then(({ data }) => {
          _this.detail = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });

      // const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // // set brand logo
      // const logoObject = this.layoutConfig("self.logo");

      // let logo;
      // if (typeof logoObject === "string") {
      //   logo = logoObject;

      // }
      // if (typeof logoObject === "object") {
      //   logo = objectPath.get(logoObject, menuAsideLeftSkin + "");

      // }
      // if (typeof logo === "undefined") {
      //   const logos = this.layoutConfig("self.logo");
      //   logo = logos[Object.keys(logos)[0]];
      // }
      // return process.env.VUE_APP_BASE_URL + logo;
    },
    default_companyLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.VUE_APP_BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
};
</script>
