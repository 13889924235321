<template>
  <div class="company-setting">
    <v-tabs
      vertical
      active-class="custom-tab-active"
      v-model="settingTab"
      background-color="transparent"
      color="cyan"
      class="tab-border-right"
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll setting-tabs-height"
        style="max-height: 91vh; position: relative"
      >
        <template v-for="(tab, index) in SettingTabs">
          <v-tab
            v-if="getPermission(tab.permission + ':view')"
            :key="index"
            :disabled="tab.disabled"
            class="font-size-16 font-weight-600 px-8"
            :href="'#' + tab.key"
          >
            {{ tab.title }}
          </v-tab>
        </template>
      </perfect-scrollbar>
      <v-tabs-items v-model="settingTab">
        <template v-for="(tab, index) in SettingTabs">
          <v-tab-item
            v-if="getPermission(tab.permission + ':view')"
            :value="tab.key"
            :key="index"
          >
            <component :is="tab.template" />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EntityTemplate from "@/view/pages/profile/Setting/Entity";
/* import GstTemplate from "@/view/pages/profile/Setting/Gst"; */
/* import TermsAndCondition from "@/view/pages/profile/Setting/TermsAndCondition"; */
/* import CountryList from "@/view/pages/profile/Setting/CountryList"; */
import CompanyTemplate from "@/view/pages/profile/Setting/Company";
import RolePermissionTemplate from "@/view/pages/profile/Setting/RolePermission";
import BrandingTemplate from "@/view/pages/profile/Setting/Branding";
import CustomerTemplate from "@/view/pages/profile/Setting/Customer";
import SlotsTemplate from "@/view/pages/profile/Setting/Time-Slotes";

/* import QuotationTemplate from "@/view/pages/profile/Setting/Quotation"; */
/* import PropertyTemplate from "@/view/pages/profile/Setting/Property";
import InvoiceTemplate from "@/view/pages/profile/Setting/Invoice";

import PaymentTemplate from "@/view/pages/profile/Setting/Payment"; */
import EngineerTemplate from "@/view/pages/profile/Setting/Engineer";
/* import ProductTemplate from "@/view/pages/profile/Setting/Product";
import ServiceTemplate from "@/view/pages/profile/Setting/Service"; */
/* import IncidentTemplate from "@/view/pages/profile/Setting/Incident"; */
import CalendarTemplate from "@/view/pages/profile/Setting/Calendar";
import CalendarSettingTemplate from "@/view/pages/profile/Setting/Calendar-Setting";

import IntegrationTemplate from "@/view/pages/profile/Setting/Integrate";

/* import ScheduleTemplate from "@/view/pages/profile/Setting/schedule.vue"; */
import EmailTemplate from "@/view/pages/profile/Setting/EmailTemplate";
// import LineItemTemplate from "@/view/pages/profile/Setting/LineItem";
import VisitTemplate from "@/view/pages/profile/Setting/Visit";
import DeliveryTemplate from "@/view/pages/profile/Setting/Delivery";
import PickupTemplate from "@/view/pages/profile/Setting/Pickup";
/* import LeaveBarcodeTemplate from "@/view/pages/profile/Setting/Leave-barcode"; */
// import ServiceFormTemplate from "@/view/pages/profile/Setting/ServiceForm";

export default {
  name: "profile-setting",
  data() {
    return {
      SettingTabs: [
        {
          disabled: false,
          key: "entity",
          template: EntityTemplate,
          title: "Entity",
          permission: "setting",
        },
        /*   {
          disabled: false,
          key: "gst",
          template: GstTemplate,
          title: "GST",
          permission: "setting",
        }, */
        {
          disabled: false,
          key: "company",
          template: CompanyTemplate,
          title: "Company",
          permission: "setting",
        },
        {
          disabled: false,
          key: "role-permission",
          template: RolePermissionTemplate,
          title: "Role & Permission",
          permission: "setting",
        },
        {
          disabled: false,
          key: "branding",
          template: BrandingTemplate,
          title: "Branding",
          permission: "setting",
        },
        {
          disabled: false,
          key: "customer",
          template: CustomerTemplate,
          title: "SERIAL NUMBERS",
          permission: "setting",
        },
        {
          disabled: false,
          key: "slotes",
          template: SlotsTemplate,
          title: "Delivery Time Slots",
          permission: "setting",
        },
        /*  {
          disabled: false,
          key: "property",
          template: PropertyTemplate,
          title: "Property",
          permission: "setting",
        }, */
        /*{
          disabled: false,
          key: "lineitem",
          template: LineItemTemplate,
          title: "Line Item",
          permission: "setting",
        },*/
        /* {
          disabled: false,
          key: "quotation",
          template: QuotationTemplate,
          title: "Quotation",
          permission: "setting",
        }, */
        /*   {
          disabled: false,
          key: "invoice",
          template: InvoiceTemplate,
          title: "Invoice",
          permission: "setting",
        }, */
        /*  {
          disabled: false,
          key: "payment",
          template: PaymentTemplate,
          title: "Payment",
          permission: "setting",
        }, */
        {
          disabled: false,
          key: "engineer",
          template: EngineerTemplate,
          title: "Technicians",
          permission: "setting",
        },
        /*  {
          disabled: false,
          key: "product",
          template: ProductTemplate,
          title: "Product",
          permission: "setting",
        },
        {
          disabled: false,
          key: "service",
          template: ServiceTemplate,
          title: "Service",
          permission: "setting",
        }, */
        {
          disabled: false,
          key: "visit",
          template: VisitTemplate,
          title: "Visit",
          permission: "setting",
        },
        {
          disabled: false,
          key: "delivery",
          template: DeliveryTemplate,
          title: "Delivery",
          permission: "setting",
        },
        {
          disabled: false,
          key: "pickup",
          template: PickupTemplate,
          title: "Pickup Address",
          permission: "setting",
        },
        {
          disabled: false,
          key: "calendar",
          template: CalendarTemplate,
          title: "Holiday",
          permission: "setting",
        },
        {
          disabled: false,
          key: "calendar-setting",
          template: CalendarSettingTemplate,
          title: "Calendar Setting",
          permission: "setting",
        },
        {
          disabled: false,
          key: "email-template",
          template: EmailTemplate,
          title: "Email Template",
          permission: "setting",
        },
        {
          disabled: false,
          key: "integration",
          template: IntegrationTemplate,
          title: "Integrations",
          permission: "dashboard",
        },
        /* {
          disabled: false,
          key: "leave",
          template: ScheduleTemplate,
          title: "Schedule",
          permission: "dashboard",
        }, */

        /*  {
          disabled: false,
          key: "terms-and-conditions",
          template: TermsAndCondition,
          title: "Terms & Conditions",
          permission: "setting",
        }, */
        /*  {
          disabled: false,
          key: "phone-country-code",
          template: CountryList,
          title: "Country List",
          permission: "setting",
        }, */
      ],
    };
  },
  watch: {
    settingTab(param) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Profile" },
        { title: "Setting" },
        { title: this.getTitle(param) },
      ]);
    },
  },
  methods: {
    getTitle(key) {
      const selected = this.lodash.find(this.SettingTabs, { key });
      if (selected) {
        return selected.title;
      }
      return "";
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile" },
      { title: "Setting" },
    ]);

    if (this.$route.query && this.$route.query.tab) {
      this.settingTab = this.$route.query.tab;

      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Profile" },
        { title: "Setting" },
        { title: this.getTitle(this.settingTab) },
      ]);
    }
  },
  computed: {
    settingTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.settingTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "entity";
      },
    },
  },
};
</script>
