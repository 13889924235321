<template>
  <div class="leave-kanban">
    <div class="kanban-view-list">
      <v-flex
        class="kanban-innerlist"
        v-for="(row, index) in leaveData"
        :key="index"
      >
        <div :class="`${row.status.color} lighten-4 list-items`">
          <div class="d-flex">
            <div class="fw-600 title-text">{{ row.status.text }}</div>
            <v-spacer></v-spacer>
            <v-chip
              class="mb-1 mr-1"
              label
              :color="`${row.status.color} white--text`"
              x-small
            >
              {{ row.list.length }}
            </v-chip>
            <!-- 	<v-badge
                         :color="row.status.color"
                        :content="row.list.length"
                        >
                      </v-badge> -->
            <!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
          </div>
          <!-- <div class="mt-1" v-if="row.status && row.status.color">
						<v-chip class="mb-1 mr-1" label :color="`${row.status.color} white--text`" x-small>
							{{ row.list.length }} leave
						</v-chip>
					</div> -->
        </div>
        <Container
          group-name="leadkanbanlist"
          v-on:drop="onDrop(index, row.list, $event, row.status.value)"
          :get-child-payload="(e) => getChildPayload(row.list, index, e)"
          :drop-placeholder="dropPlaceholderOptions"
          class="overflow-list-item"
          :id="!row.list.length ? 'blank-list' : ''"
        >
          <Draggable
            v-model="row.status.value"
            v-for="(data, index) in row.list"
            :key="index"
            class=""
          >
            <div :class="`${row.status.color} lighten-5 list-items`">
              <!-- <div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Leave Number : </span>
									<span class="fw-500" v-if="data && data.barcode">{{ data.barcode }}</span>
								</div>
							</div> -->
              <div class="d-flex">
                <div class="ellipsis fw-500 mr-2 w-80">
                  <div class="ellipsis pr-6">
                    <span class="fw-600 pr-2">Staff Name : </span>
                    <span
                      class="fw-500"
                      v-if="data && data.user_data && data.user_data.full_name"
                      >{{ data.user_data.full_name }}</span
                    >
                  </div>
                </div>
                <div class="ellipsis fw-500 text-end w-20">
                  <!-- <template v-if="getProvision(data.provision_date) >= 0">
										<span class="fw-500">
											<v-chip
												v-if="getProvision(data.provision_date) >= 0"
												color="red"
												class="white--text"
												outlined
												style="letter-spacing: 0.8px"
												small
											>
												Probation
											</v-chip>
										</span>
									</template> -->
                  <v-menu
                    content-class="custom-menu-list"
                    offset-y
                    left
                    v-if="data.attachments.length > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        dark
                        >mdi-paperclip</v-icon
                      >
                    </template>
                    <v-list>
                      <template v-for="(item, index) in data.attachments">
                        <v-list-item
                          link
                          :key="index"
                          v-on:click="downloadAttachment(item.file.url)"
                        >
                          <v-list-item-title>
                            <div class="d-flex align-items-center">
                              <div>
                                <v-btn
                                  small
                                  icon
                                  depressed
                                  color="blue darken-4"
                                  class="mr-1"
                                  ><v-icon small>mdi-download</v-icon></v-btn
                                >
                              </div>
                              <div class="ml-2">
                                <div>
                                  {{ item.name }}
                                </div>
                              </div>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                  <!--  <v-icon
                      style="font-size: 18px"
                      class="mr-2"
                      color="cyan"
                      small
                      v-on:click="Updateleave(data.id)"
                      >mdi-paperclip</v-icon
                    > -->
                </div>
              </div>
              <!-- 	<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Probation Period : </span>
									<span class="fw-500" v-if="data && data.joining_date && data.provision_date"
										>{{ formatDate(data.joining_date) }} <b>To</b> {{ formatDate(data.provision_date) }}</span
									>
								</div>
							</div> -->
              <div class="d-flex">
                <div class="ellipsis fw-500 w-80">
                  <span class="fw-600 pr-2">Leave # :</span>
                  <template v-if="data && data.barcode">
                    <span class="fw-500">{{ data.barcode }}</span>
                  </template>
                  <template v-else>no barcode</template>
                </div>
                <div
                  class="ellipsis fw-500 text-end w-20"
                  v-if="row.status.status == 1"
                >
                  <v-icon
                    style="font-size: 18px"
                    class="mr-2"
                    color="cyan"
                    small
                    v-on:click="Updateleave(data.id)"
                    >mdi-pencil</v-icon
                  >
                </div>
              </div>
              <div class="ellipsis pr-6">
                <div class="ellipsis pr-6">
                  <span class="fw-600 pr-2">Leave Type : </span>
                  <span
                    class="fw-500"
                    style="text-transform: capitalize"
                    v-if="data && data.category"
                    >{{ data.category }}</span
                  >
                </div>
              </div>
              <div class="ellipsis pr-6">
                <div class="ellipsis pr-6">
                  <span class="fw-600 pr-2">Start Date : </span>
                  <span class="fw-500" v-if="data && data.start_date"
                    >{{ formatDate(data.start_date)
                    }}<!--  <template v-if="data && data.duration && data.duration_type == 1 && data.day_type == 1">
								            (First Half)
								</template> -->
                  </span>
                </div>
              </div>
              <div class="ellipsis pr-6">
                <div class="ellipsis pr-6">
                  <span class="fw-600 pr-2">End Date : </span>
                  <span class="fw-500" v-if="data && data.end_date"
                    >{{ formatDate(data.end_date)
                    }}<!-- <template v-if="data && data.duration_type == 1 && data.duration && data.day_type == 2">
								            (Second Half)
								</template> --></span
                  >
                </div>
              </div>
              <!-- 	<div class="ellipsis pr-6">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Duration : </span>
									<span class="fw-500" v-if="data && data.duration">{{ data.duration }}</span>
								</div>
							</div> -->

              <template v-if="data && data.duration">
                <span class="fw-600 pr-2">Half Day : </span>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  :color="`${row.status.color} white--text`"
                  x-small
                  v-if="data && data.duration_type == 1"
                >
                  <template v-if="data.day_type == 1">
                    {{ formatDate(data.start_date) }}
                  </template>
                  <template v-else>
                    {{ formatDate(data.end_date) }}
                  </template>

                  (First Half)
                </v-chip>
                <v-chip
                  class="mb-1 mr-1"
                  label
                  :color="`${row.status.color} white--text`"
                  x-small
                  v-else
                >
                  <template v-if="data.day_type == 1">
                    {{ formatDate(data.start_date) }}
                  </template>
                  <template v-else> {{ formatDate(data.end_date) }} </template
                  >(Second Half)
                </v-chip>
              </template>
              <div class="ellipsis pr-6">
                <div class="ellipsis pr-6">
                  <span class="fw-600 pr-2">Number of Days : </span>
                  <span class="fw-500" v-if="data && data.total_leave_days">
                    <v-chip
                      class="mb-1 mr-1"
                      label
                      :color="`${row.status.color} white--text`"
                      x-small
                    >
                      {{ data.total_leave_days }}
                    </v-chip>
                  </span>
                  <!-- <span v-if="getProvision(data.provision_date) >= 0"> (Unpaid leave)</span> -->
                </div>
              </div>
              <div class="ellipsis pr-6">
                <div class="ellipsis pr-6">
                  <span class="fw-600 pr-2">Reason for leave : </span>
                  <span class="fw-500" v-if="data && data.reason">{{
                    data.reason
                  }}</span>
                </div>
              </div>
              <!-- <div class="pr-6">
								<div class="pr-6">
									<span class="fw-600 pr-2">Half Day : </span>
									<template v-if="row && row.status && row.status.status_color">
										<v-chip label x-small :color="`${row.status.status_color} white--text`">
											<template v-if="data && data.leave_days == 'first_half'"> First Half </template>
											<template v-else> Second Half </template>
										</v-chip>
									</template>
								</div>
							</div>
							<div class="pr-6">
								<div class="pr-6">
									<span class="fw-600 pr-2">Reason : </span>
									<span class="fw-500" v-if="data && data.leave_reason">{{ data.leave_reason }}</span>
								</div>
							</div> -->
              <!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
            </div>
          </Draggable>
        </Container>
        <!-- <DeleteTemplate
					type="leave"
					:deleteUrl="delURL"
					:deleteText="delTEXT"
					:deleteDialog="confirm_dialog"
					v-on:close="confirm_dialog = false"
					v-on:success="refresh"
				></DeleteTemplate> -->
        <Dialog :common-dialog="statusDialog" :dialog-width="640">
          <template v-slot:title>
            <div class="d-flex">
              <div>Confirmed<br /></div>
              <div>
                <v-chip
                  class="ml-2 mr-2 text-capitalize"
                  outlined
                  color="red white--text"
                  ><template v-if="allLeavedata && allLeavedata.category">{{
                    allLeavedata.category
                  }}</template></v-chip
                >
                <v-chip
                  class="ml-2 mr-2 text-capitalize"
                  outlined
                  color="blue white--text"
                  ><template
                    v-if="
                      allLeavedata &&
                      allLeavedata.staff &&
                      allLeavedata.staff.full_name
                    "
                    >{{ allLeavedata.staff.full_name }}</template
                  ></v-chip
                >
              </div>
            </div>
          </template>
          <template v-slot:body>
            <v-row>
              <v-col md="12">
                <v-layout
                  class="page-summary show"
                  style="height: 90px !important"
                >
                  <v-flex class="summary-details blue lighten-5" md4>
                    <v-avatar color="blue" size="35" class="mr-2">
                      <span class="white--text fw-600" style="font-size: 18px">
                        {{ allocated }}
                      </span>
                    </v-avatar>
                    <span class="name text-primary">Total </span>
                  </v-flex>
                  <v-flex class="summary-details green lighten-5" md4>
                    <v-avatar color="green" size="35" class="mr-2">
                      <span class="white--text fw-600" style="font-size: 18px">
                        {{ apprveLeaves }}
                      </span>
                    </v-avatar>
                    <span class="name" style="color: green">Consume </span>
                  </v-flex>
                  <v-flex class="summary-details orange lighten-5" md4>
                    <v-avatar color="orange" size="35" class="mr-2">
                      <span class="white--text fw-600" style="font-size: 18px">
                        {{ pendingLeaves }}
                      </span>
                    </v-avatar>
                    <span class="name" style="color: orange">Pending </span>
                  </v-flex>
                  <v-flex class="summary-details red lighten-5" md4>
                    <v-avatar color="red" size="35" class="mr-2">
                      <span class="white--text fw-600" style="font-size: 18px">
                        {{ remainingLeaves }}
                      </span>
                    </v-avatar>
                    <span class="name" style="color: red">Balance</span>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
            <p
              class="btx-p m-0 text-align-center"
              style="text-align: center; margin-top: -21px !important"
            >
              <span
                class="red--text lighten-1 font-level-1-bold"
                style="
                  font-size: 22px !important;
                  color: #24326d !important;
                  font-weight: 600 !important;
                "
                >Are you sure, You want to
                <template v-if="statusdata == 'approved'">
                  <span style="color: green">Approve</span> this Leave
                  <span
                    class="text-primary"
                    v-if="allLeavedata && allLeavedata.barcode"
                  >
                    {{ allLeavedata.barcode }}</span
                  ></template
                ><template v-else>
                  <span style="color: red">Reject</span> this Leave
                  <span
                    class="text-primary"
                    v-if="allLeavedata && allLeavedata.barcode"
                    >{{ allLeavedata.barcode }}</span
                  ></template
                >?</span
              >
            </p>
          </template>
          <template v-slot:action>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-on:click="onSubmit(visits)"
              style="min-width: 78px !important"
            >
              <template
                v-if="visits && visits.length > 0 && statusdata == 'approved'"
              >
                Approve & UnAssigned
              </template>
              <template v-else>
                <template v-if="statusdata == 'approved'"> Approve </template>
                <template v-else> Reject </template>
              </template>
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeMainDialog()"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
      </v-flex>
    </div>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { QUERY, PATCH, GET } from "@/core/services/store/request.module";
import { filter, cloneDeep } from "lodash";
/* import { LeaveEventBus } from "@/core/lib/leave.form.lib"; */
import { LeaveApplyEventBus } from "@/core/lib/leave.from.lib.js";
/* import DeleteTemplate from "@/view/components/DeleteTemplate"; */
import { currentUser } from "@/core/services/jwt.service";

import Dialog from "@/view/pages/partials/Dialog";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import moment from "moment-timezone";
export default {
  name: "LeaveKanban",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      dropPlaceholderOptions: {
        className: "lead-drop-preview",
        animationDuration: "50",
        showOnTop: true,
      },
      leaveData: [],
      statusvalue: 0,
      formValid: true,
      allLeavedata: {},
      categoryList: [],
      visits: [],
      leaveId: 0,
      statusdata: null,
      probisionTime: 0,
      confirm_dialog: false,
      confirm: false,
      delURL: null,
      apprveLeaves: 0,
      pendingLeaves: 0,
      remainingLeaves: 0,
      allocated: 0,
      dropObjectData: {},
      statusDialog: false,
      status_remark: {
        drop_status: "Cancelled",
        dropData: null,
        drop_value: 0,
      },
      delTEXT: null,
    };
  },
  watch: {
    $route: {
      handler() {
        const { query } = this.$route;
        this.getLeaveListing(query);
      },
    },
  },

  methods: {
    closeDialog() {
      this.statusDialog = false;
    },
    openStatusDialog() {
      this.statusDialog = true;
    },
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
    onSubmit(data) {
      this.confirm = true;
      this.dropData(data);
      this.statusDialog = false;
    },
    closeMainDialog() {
      this.confirm = false;
      this.statusDialog = false;
      this.getLeaveListing();
    },
    refresh() {
      this.confirm_dialog = false;
      this.getLeaveListing();
    },
    Updateleave(id) {
      this.$emit("update:leave", id);
    },
    delete_leave(row) {
      this.delTEXT = row.barcode;
      (this.delURL = `leave/${row.id}`), (this.confirm_dialog = true);
      this.getLeaveListing();
    },
    getProvision(proDate) {
      let a = moment();
      let b = moment(proDate);
      return b.diff(a, "days");
    },
    getLeaveListing(query) {
      this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: `leave`,
          data: {
            status: "all",
            ...query,
          },
        })
        .then((data) => {
          const output = [];
          for (let i = 0; i < data.data.status_list.length; i++) {
            if (data.data.status_list[i].value == "all") {
              continue;
            }
            output.push({
              status: data.data.status_list[i],
              list: filter(data.data.rows, {
                status_text: data.data.status_list[i].text,
              }),
            });
          }
          this.leaveData = output;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    dropData(visitArray) {
      if (this.confirm == true) {
        this.update_loading = true;
        this.$store
          .dispatch(PATCH, {
            url: `leave/${this.leaveId}/${this.statusdata}`,
            data: {
              visit: visitArray,
            },
          })
          .then(() => {
            this.getLeaveListing();
            this.$emit("refressData", true);
            this.$emit("refressStatus", true);
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.update_loading = false;
          });
      }
      if (this.confirm == false) {
        this.leaveData[this.dropObjectData.index].list = this.applyDrag(
          this.dropObjectData.collection,
          this.dropObjectData.dropResult
        );
        this.getLeaveListing();
        return false;
      }
    },
    onDrop(index, collection, dropResult, dropStatus) {
      /* if (this.is_staff) {
				return false;
			} */

      const { addedIndex } = dropResult;

      if (addedIndex != null) {
        this.statusdata = dropStatus;
      }
      if (this.dragstatus != 1) {
        this.leaveData[index].list = this.applyDrag(collection, dropResult);
        this.getLeaveListing();
        return false;
      }
      if (addedIndex != null) {
        this.statusDialog = true;
        this.dropObjectData = {
          collection,
          dropResult,
          index,
        };
      }
      /* 		console.log(collection) */
      /*  if(collection && collection[index] && collection[index].staff && collection[index].staff.id){
				this.getCategoryOptions(collection[index]);
			} */

      /* this.leaveData[index].list = this.applyDrag(collection, dropResult); */
    },
    getChildPayload(row, index, event) {
      this.allLeavedata = row[event];
      this.leaveId = row[event].id;
      this.dragstatus = row[event].status;
      this.getCategoryOptions();
      return this.leaveData[index];
    },
    getCategoryOptions() {
      const _this = this;
      _this.pageLoading = true;

      _this.$store
        .dispatch(GET, {
          url: "setting/leave/option/" + this.allLeavedata.staff.id,
        })
        .then((data) => {
          _this.categoryList = data.data.categories;
          _this.visits = data.data.visit;
          _this.categoryList.push({
            category: "UnPaid",
          });
          let categoryData = filter(_this.categoryList, function (row) {
            return row.category == _this.allLeavedata.category;
          });
          console.log(this.allLeavedata, "unpaidCategory");
          /*    let unpaidCategory = filter(_this.leaveData, function (row) {
            return row.category == _this.allLeavedata.category && row.staff == _this.allLeavedata.staff;
          }); */
          /*  console.log(unpaidCategory,'unpaidCategory');
          console.log(categoryData[0].category,'sfdsdfsdfsdf'); */
          if (
            categoryData[0] &&
            categoryData[0].balance &&
            categoryData[0].category != "UnPaid"
          ) {
            this.remainingLeaves = categoryData[0].balance;
            this.apprveLeaves = categoryData[0].taken;
            this.pendingLeaves = categoryData[0].pending;
            this.allocated = categoryData[0].allocated_days;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });

      /* 	this.categoryDays(); */
    },
    applyDrag(output, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return output;
      const result = cloneDeep(output);
      let itemToAdd = payload.list[addedIndex];
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = user.role;
      return role_name == "Staff";
    },
  },
  mounted() {
    this.getLeaveListing();
    LeaveApplyEventBus.$on("refresh", () => {
      this.getLeaveListing();
    });
  },
  components: {
    Container,
    Draggable,
    /* DeleteTemplate, */
    Dialog,
  },
};
</script>
