<template>
  <DetailTemplate
    :custom-class="'contract-detail detail-page'"
    v-if="getPermission('contract:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center" v-if="pageLoaded">
        <span class="color-custom-blue font-weight-700 font-size-20 mr-2">{{
          detail.job_title
        }}</span>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3"
          style="font-size: 21px !important; font-weight: 700"
          >{{ detail.barcode }}</v-chip
        >
        <CustomStatus
          :status="detail.status"
          endpoint="contract/status"
        ></CustomStatus>
        <template v-if="detail.is_revised">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="red lighten-4"
                class="ml-0 mr-2 black--text font-weight-600"
                label
              >
                Renewed
              </v-chip>
            </template>
            <span>Contract is Renewed</span>
          </v-tooltip>
        </template>

        <template v-if="detail.is_reviewed">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="red lighten-4"
                class="ml-0 mr-2 white--text font-weight-600"
                label
              >
                Reviewed
              </v-chip>
            </template>
            <span>Contract is Reviewed</span>
          </v-tooltip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template
          v-if="detail.status != 4 && detail.status != 1 && detail.status != 2"
        >
          <template
            v-if="
              (detail.status != 2 || detail.status != 3) && detail.status != 6
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="statusLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="updateMoreAction('edit')"
                >
                  <v-icon left>mdi-pencil</v-icon> Edit
                </v-btn>
              </template>
              <span>Click here to edit Contract</span>
            </v-tooltip>
          </template>
        </template>

        <template v-if="detail.status == 1 && !detail.is_revised">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('revise')"
              >
                <v-icon left>mdi-update</v-icon> Renew
              </v-btn>
            </template>
            <span>Click here to renew Contract</span>
          </v-tooltip>
        </template>

        <template v-if="false && detail.status == 6">
          <template v-if="!detail.is_reviewed">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="statusLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="updateMoreAction('review')"
                >
                  <v-icon left>mdi-update</v-icon> Review
                </v-btn>
              </template>
              <span>Click here to review Contract</span>
            </v-tooltip>
          </template>
        </template>
        <v-menu
          v-if="detail.status != 4 && detail.status != 2"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="
          max-height: calc(100vh - 185px);
          height: calc(100vh - 185px);
          position: relative;
        "
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="5">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.billing_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_email }}
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Site Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.property_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="detail.property_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="detail.property_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_email }}
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>

                    <!-- <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-600"
                      >
                        Delivery Site (Service Locaiton As Default)
                      </td>
                    </tr> -->
                    <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-500"
                      >
                        <label class="m-0">{{ detail.delivery_site }}</label>
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="7" class="custom-border-left gray-background">
              <table width="100%">
                <!-- <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ detail.job_title }}</span
                    >
                  </td>
                </tr> -->
                <!--  <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.barcode }}</label>
                  </td>
                </tr> -->
                <!--  <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Rate opportunity
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-rating
                      readonly
                      dense
                      v-model.trim="detail.rating"
                      background-color="orange lighten-3"
                      color="orange"
                    ></v-rating>
                  </td>
                </tr> -->

                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail.reference">{{
                      detail.reference
                    }}</label>
                    <em class="text-muted" v-else> no reference</em>
                  </td>
                </tr>

                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract Duration
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-chip
                      small
                      color="red white--text"
                      label
                      class=""
                      outlined
                    >
                      <span class="font-size-16 font-weight-500">
                        {{ formatDate(detail.contract_start) }} to
                        {{ formatDate(detail.contract_end) }} ({{
                          detail.duration_value
                        }}
                        {{ detail.duration_type }})</span
                      >
                    </v-chip>
                    <!--  <label class="m-0"
                      >{{ formatDate(detail.contract_start) }} to
                      {{ formatDate(detail.contract_end) }} ({{
                        detail.duration_value
                      }}
                      {{ detail.duration_type }})</label
                    > -->
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Sales representatives
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label
                      class="m-0"
                      v-if="
                        detail &&
                        detail.sales_representatives &&
                        detail.sales_representatives.full_name
                      "
                      >{{ detail.sales_representatives.full_name }}</label
                    >
                    <em class="text-muted" v-else> no Sales representatives</em>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Prepared by
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail.prepared_by">{{
                      detail.prepared_by
                    }}</label>
                    <em class="text-muted" v-else> no Prepared by</em>
                  </td>
                </tr>
                <!-- <tr
                  v-if="detail.is_contract"
                  class="custom-border-bottom gray-background"
                >
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Remark
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail.remark">{{
                      detail.remark
                    }}</label>
                    <em v-else class="text-muted">no remark</em>
                  </td>
                </tr> -->
                <!-- <tr v-if="detail.fax">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    FAX
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail.fax">{{
                      detail.fax
                    }}</label>
                    <em class="text-muted" v-else> no fax</em>
                  </td>
                </tr>
                <tr v-if="detail.did">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    DID
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.did }}</label>
                  </td>
                </tr> -->
                <tr v-if="false">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Is Contract ?
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                      detail.is_contract ? "Yes" : "No"
                    }}</label>
                  </td>
                </tr>

                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    No. of Services
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">
                      <v-badge color="green" :content="detail.total_service">
                        <!--   Item Two -->
                      </v-badge>
                      <!--  {{ detail.total_service }} --></label
                    >
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Used Services
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">
                      <v-badge color="orange" :content="detail.visit_count">
                        <!--   Item Two -->
                      </v-badge>
                      <!--  {{ detail.visit_count }} --></label
                    >
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Balance
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">
                      <template v-if="getBalance(detail) > 0">
                        <v-badge color="red" :content="getBalance(detail)">
                        </v-badge>
                      </template>
                      <template v-else>
                        <v-badge color="red" content="0"> </v-badge>
                      </template>
                    </label>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="contractTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/contract.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#attachment"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/attachment.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Attachment
                </v-tab>

                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Invoices
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Visits
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="contractTab">
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail && detail.description
                              ? detail.description
                              : '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="100"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                      v-if="detail.cancelled_remark"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Cancelled Remark</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.cancelled_remark ||
                            '<em>No Cancelled Remark</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="100"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <!-- <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card> -->
                    <v-row>
                      <v-col md="6">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mb-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Client Contract Notes
                            </span>
                          </v-card-title>

                          <v-card-text class="p-6 font-size-16">
                            <v-layout class="mb-4">
                              <v-flex md6 class="custom-border-right">
                                <read-more
                                  class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                                  more-str="read more"
                                  :text="
                                    detail.client_remark ||
                                    '<em>No Client Contract Notes</em>'
                                  "
                                  link="#"
                                  less-str="read less"
                                  :max-chars="200"
                                >
                                </read-more>
                              </v-flex>
                              <!-- <v-flex md6>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark ||
                                '<em>No Internal Admin Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex> -->
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col md="6">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mb-4"
                        >
                          <v-card-title class="headline grey lighten-4">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >Internal Admin Notes
                            </span>
                          </v-card-title>

                          <v-card-text class="p-6 font-size-16">
                            <v-layout class="mb-4">
                              <!-- <v-flex md6 class="custom-border-right">
                                <read-more
                                  class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                                  more-str="read more"
                                  :text="
                                    detail.client_remark ||
                                    '<em>No Client Contract Notes</em>'
                                  "
                                  link="#"
                                  less-str="read less"
                                  :max-chars="200"
                                >
                                </read-more>
                              </v-flex> -->
                              <v-flex md6>
                                <read-more
                                  class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                                  more-str="read more"
                                  :text="
                                    detail.admin_remark ||
                                    '<em>No Internal Admin Notes</em>'
                                  "
                                  link="#"
                                  less-str="read less"
                                  :max-chars="200"
                                >
                                </read-more>
                              </v-flex>
                            </v-layout>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="detail.additional_remarks"
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                      <!-- <v-card-text class="p-6 font-size-16">
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.validity">
                              {{ detail.validity }}
                            </template>
                            <template v-else>
                              <em>No Validity Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.payment">
                              {{ detail.payment }}
                            </template>
                            <template v-else>
                              <em>No Payment Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.delivery">
                              {{ detail.delivery }}
                            </template>
                            <template v-else>
                              <em>No Delivery Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.deposit">
                              {{ detail.deposit }}
                            </template>
                            <template v-else>
                              <em>No Deposit Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.warranty">
                              {{ detail.warranty }}
                            </template>
                            <template v-else>
                              <em>No Warranty Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.cancellation">
                              {{ detail.cancellation }}
                            </template>
                            <template v-else>
                              <em>No Cancellation Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                      </v-card-text> -->
                    </v-card>
                  </v-container>
                </v-tab-item>

                <v-tab-item
                  v-if="getPermission('adjustment:view')"
                  :value="'attachment'"
                >
                  <div class="service-detail-height">
                    <Images
                      v-if="
                        detail &&
                        detail.documents &&
                        detail.documents.length &&
                        detail.documents.length > 0
                      "
                      :images="detail.documents"
                      :parent="detail.id"
                      type="addjustment"
                      :isPageLoading="pageLoading"
                    ></Images>
                    <v-col md="12" v-else>
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          height="35"
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no attachment at the moment.
                      </p>
                    </v-col>
                  </div>
                </v-tab-item>

                <v-tab-item value="line-item">
                  <ContractLineItem
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :currency="detail.currency"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:load:content="getContract()"
                  ></ContractLineItem>
                </v-tab-item>
                <v-tab-item value="invoice">
                  <InternalInvoice
                    type="contract"
                    :type-id="contractId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="visit">
                  <InternalVisit
                    is-admin
                    visit-type="all"
                    internal
                    :contract-id="detail.id"
                    :visit-status="0"
                    detailRoute="visit.detail"
                    :customer-id="detail.customer.id"
                    :entity-id="detail.entity_id"
                  ></InternalVisit>
                </v-tab-item>
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="contract"
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="contract"
        v-on:close="mail_dialog = false"
        :type-id="contractId"
        v-on:success="getContract()"
      ></SendMail>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure want to
                <span class="font-weight-700">{{ get_confirm_title() }}</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirm_type == 'review' ||
                  confirm_type == 'mark_as_cancelled'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <v-textarea
                    v-model.trim="actionData.remark"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    :rules="[
                      validateRules.required(actionData.remark, 'Remark'),
                      validateRules.minLength(actionData.remark, 'Remark', 1),
                      validateRules.maxLength(
                        actionData.remark,
                        'Remark',
                        1024
                      ),
                    ]"
                    v-on:keypress="(e) => manageLimit(e)"
                    v-on:paste="(e) => onPaste(e)"
                    row-height="25"
                  ></v-textarea>
                  <div class="d-flex justify-end">
                    {{
                      actionData &&
                      actionData.remark &&
                      actionData.remark.length
                        ? actionData.remark.length
                        : 0
                    }}/200
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                class="mt-4"
                v-if="confirm_type == 'mark_as_cancelled' && false"
              >
                <v-flex md12>
                  <label class="font-weight-500 font-size-16">Attachment</label>
                  <FileTemplateName
                    :disabled="formLoading"
                    v-on:file:updated="updateAttachment($event)"
                    allowUpload
                  ></FileTemplateName>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirm_submit()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
/* import FileTemplate from "@/view/pages/partials/FileTemplate.vue"; */
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import ContractLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import Dialog from "@/view/pages/partials/Dialog";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import FileTemplateName from "@/view/pages/partials/FileTemplateName.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Images from "@/view/pages/partials/Detail/adjustmenetImage";
export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      contractId: 0,
      pageLoading: true,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      confirm_dialog: false,
      invoiceLoading: false,
      contractTab: "other",
      moreActions: [],
      detail: {},
      dbLineItems: [],
      dbEquipments: [],
      confirm_type: null,
      confirm_loading: false,
      actionData: {
        remark: null,
        attachments: [],
        status: null,
      },
    };
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.actionData.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval && finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.actionData.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.actionData.remark &&
        this.actionData.remark.length &&
        this.actionData.remark.length > 199
      ) {
        e.preventDefault();
      }
    },

    updateAttachment(param) {
      this.attachments = param;
    },
    confirm_submit() {
      const _this = this;
      const validateStatus = _this.$refs.statusForm.validate();
      const formErrors = _this.validateForm(_this.$refs.statusForm);
      if (formErrors && formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (!validateStatus) {
        return false;
      }
      switch (this.confirm_type) {
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "review":
          this.updateStatus("review");
          break;
      }
    },
    getBalance(row) {
      let balance = Number(row.total_service) - Number(row.visit_count);
      return Number(balance);
    },
    get_confirm_title() {
      if (this.confirm_type == "review") {
        return "Reviewed";
      } else if (this.confirm_type == "mark_as_cancelled") {
        return "Mark as Cancelled";
      }
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data && data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                project_price: data[i].project_price,
                is_optional: data[i].is_optional,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = _this.lodash.filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = _this.lodash.filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editContract();
          break;
        case "revise":
          this.reviseContract();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "renew":
          this.routeToRenew();
          break;
        case "review":
          this.confirm_dialog = true;
          this.confirm_type = "review";
          break;
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_cancelled";

          //this.updateStatus("mark_as_cancelled");
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      this.actionData.attachments = this.attachments;
      this.actionData.status = status;
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "contract/" + this.contractId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getContract();
          this.confirm_dialog = false;
          this.confirm_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
          this.confirm_loading = false;
        });
    },
    downloadPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}contract/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}contract/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            contract: this.contractId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            contract: this.contractId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToRenew() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("contract.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            renew: this.contractId,
            duplicate: this.contractId,
          },
        })
      );
    },
    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("contract.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.contractId,
          },
        })
      );
    },
    reviseContract() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("contract.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            revise: this.contractId,
          },
        })
      );
    },
    editContract() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("contract.update", {
          params: {
            id: this.contractId,
          },
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
          },
        })
      );
    },
    getContract() {
      this.$store
        .dispatch(GET, { url: "contract/" + this.contractId })
        .then(({ data }) => {
          this.detail = data;

          this.moreActions = data.more_actions;

          this.getLineItems({
            contract: this.detail.id,
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Contract", route: "contract" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  created() {
    this.contractId = this.lodash.toSafeInteger(this.$route.params.id);
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  mounted() {
    this.getContract();
  },
  components: {
    Images,
    CustomStatus,
    /* FileTemplate, */
    DetailTemplate,
    InternalVisit,
    SendMail,
    InternalInvoice,
    ContractLineItem,
    Dialog,
    FileTemplateName,
    InternalHistoryDetail,
  },
};
</script>
