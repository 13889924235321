<template>
  <v-layout>
    <v-col class="custom-content-container" cols="12" :md="12" :sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 90vh; position: relative"
      >
        <v-layout class="d-block">
          <v-row>
            <v-col class="iv-custom-field" :cols="12" :md="12" :sm="12">
              <v-container fluid>
                <!--<v-switch
                  v-model.trim="notification.client_reminders"
                  class="mt-0"
                  inset
                  color="cyan"
                  label="Client reminders sent for upcoming visits.123"
                ></v-switch>
                <v-switch
                  v-model.trim="notification.client_follow_up_emails"
                  class="mt-0"
                  inset
                  color="cyan"
                  label="Client follow-up emails when you close a job."
                ></v-switch>
                <v-switch
                  v-model.trim="notification.print_email_on_work_order"
                  class="mt-0"
                  inset
                  color="cyan"
                  label="Print email on document when you download."
                ></v-switch>
                <hr />-->
                <label class="font-size-16 font-weight-500"
                  >Remarks (Internal Use Only)</label
                >
                <v-textarea
                  v-model.trim="notification.customer_remark"
                  :rules="[
                    validateRules.minLength(
                      notification.customer_remark,
                      'Remarks',
                      2
                    ),
                    validateRules.maxLength(
                      notification.customer_remark,
                      'Remarks',
                      512
                    ),
                  ]"
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  row-height="30"
                ></v-textarea>
              </v-container>
            </v-col>
          </v-row>
        </v-layout>
      </perfect-scrollbar>
    </v-col>
    <template v-if="false && !isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "notification-and-remarks",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notification: {
        client_reminders: 1,
        client_follow_up_emails: 1,
        print_email_on_work_order: 1,
        customer_remark: null,
      },
      fieldDescriptions: [
        /*{
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type."
        }*/
      ],
    };
  },
  watch: {
    notification: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("saveNotification", param);
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.notification.customer_remark = param.remarks;
        }
      },
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.customer) === false) {
      this.notification.client_reminders = this.customer.client_reminders;
      this.notification.client_follow_up_emails =
        this.customer.client_follow_up_emails;
      this.notification.print_email_on_work_order =
        this.customer.print_email_on_work_order;
      this.notification.customer_remark = this.customer.customer_remark;
    }
  },
};
</script>
