<template>
  <CreateUpdateTemplate :routePreventDialog="routePreventDialog" :customClass="'product-create create-form'"
    v-if="getPermission('product:create')">
    <template v-slot:header-title>
      <template>
        <v-skeleton-loader class="custom-skeleton large-skeleton-title" v-if="formLoading" type="text">
        </v-skeleton-loader>
        <template v-else>
          <h1 class="form-title d-flex">
            <template v-if="productId">Update</template>
            <template v-else>Create</template> Product
          </h1>
        </template>
      </template>
    </template>
    <template v-slot:header-action>
      <template v-if="formLoading">
        <v-skeleton-loader class="custom-skeleton large-skeleton-action-button" type="button">
        </v-skeleton-loader>
      </template>
      <v-btn :disabled="!formValid || formLoading" :loading="formLoading"
        class="mx-2 custom-bold-button text-white" color="cyan" v-on:click="onSubmit">
        <template v-if="productId">Update</template>
        <template v-else>Save</template> Product
      </v-btn>
      <v-btn v-on:click="goBack" :disabled="formLoading" class="mx-2 custom-grey-border custom-bold-button"
        outlined>Cancel
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid class="">
        <v-form ref="productForm" v-model.trim="formValid" lazy-validation v-on:submit.stop.prevent="onSubmit">
            <ProductCreate :productId="productId" :barcodeData="barcode" :updateMode="updateMode"
              :productCreateData="productCreate" v-on:saveProduct="updateProduct"></ProductCreate>
              </v-form>
          </v-container>
    </template>
  </CreateUpdateTemplate>
 
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ProductCreate from "@/view/pages/product/partials/Create.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
//import { map } from "lodash";
export default {
  mixins: [CommonMixin, ProductMixin],
  name: "product-create",
  title: "Create Product",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      productCreated:false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      productCreate: null,
      productId: null,
      duplicateId: null,
    };
  },
  components: {
    ProductCreate,
    CreateUpdateTemplate
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateProduct(param) {
      this.productCreate = param;
    },
    getProduct(productId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (productId && productId > 0) {
          _this.$store
            .dispatch(GET, { url: "product/" + productId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },
    createWarrantyEntity() {
      const _this = this;
      return new Promise((resolve) => {
        let warrantyFormData = _this.lodash.compact(
          _this.lodash.map(_this.productCreate.warranty, function (param) {
            if (param.field && param.value) {
              return param;
            }
          })
        );

        if (_this.lodash.isEmpty(warrantyFormData) === false) {
          _this.formLoading = true;
          _this.$store
            .dispatch(POST, {
              url: "product/" + _this.productId + "/entity",
              data: {
                type: 1,
                entities: warrantyFormData,
              },
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } else {
          resolve(true);
        }
      });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.productForm.validate()) {
        return false;
      }
      if (
        Number(this.productCreate.selling_cost) <
        Number(this.productCreate.threshold_price)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Selling cost / Unit cost Should be greater than Threshold Price."
          )
        );
        return false;
      }
      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "product";

      if (_this.productId && _this.productId > 0) {
        requestType = PUT;
        requestURL = "product/" + _this.productId;
      }
      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.productCreate })
        .then(async (response) => {
           _this.productCreated=true,
           _this.forcePush = true,
          _this.productId = response.data ? response.data.id : null;

          await _this.createWarrantyEntity();

          _this.$router.push(
            _this.getDefaultRoute("product", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getProduct(_this.productId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Product", route: "product" },
              { title: "Update" },
            ]);

            _this.productCreate = {
              name: response.name,
              /*    quantity: response.quantity, */
              reference: response.reference,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: response.product_images,
              product_attachments: response.product_attachments,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              serial_number: response.serial_number,
              description: response.description,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              barcode: response.barcode ? response.barcode : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,

              project_price: response.project_price,
              threshold_price: response.threshold_price,
              commission_rate: response.commission_rate,
              website_url: response.website_url,

              length: response.length,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              initial_stock: response.initial_stock,
              charges: response.charges,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,

              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: param.id,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */

              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getProduct(_this.duplicateId)
        .then((response) => {
          /*   console.log(response.warranty_data.length)
            if(response && response.warranty_data && response.warranty_data.length > 0){
               this.warrantyActive = true;
            } */
          if (_this.lodash.isEmpty(response) === false) {
            _this.productCreate = {
              barcode: this.barcode,
              name: response.name,
              /*  quantity: response.quantity, */
              reference: response.reference,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: [], //response.product_images,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              description: response.description,
              project_price: response.project_price,
              serial_number: response.serial_number,
              threshold_price: response.threshold_price,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              length: response.length,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              initial_stock: response.initial_stock,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              charges: response.charges,
              website_url: response.website_url,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,
              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: null,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */
              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.productId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.product.update" ||
      _this.$route.name == "engineer.product.update"
    ) {
      _this.updateMode = true;

      if (_this.productId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getProduct(_this.productId);
    });
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.productCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
