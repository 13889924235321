<template>
  <div class="engineer-detail detail-page">
    <v-row>
      <v-col cols="12">
        <v-container fluid class="white-background main-create-page">
          <div class="d-flex">
            <h1 class="form-title custom-nowrap-ellipsis text-capitalize mr-2">
              {{ teamArray.team_name }}
            </h1>

            <v-chip
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3"
              style="font-size: 21px !important; font-weight: 700"
              >{{ teamArray.barcode }}</v-chip
            >
            <v-spacer></v-spacer>
            <v-btn
              v-on:click="goBack"
              class="mx-2 custom-grey-border custom-bold-button"
              depressed
            >
              <v-icon left>mdi-keyboard-backspace</v-icon>
              Back
            </v-btn>
          </div>
          <!-- <div class="p-5 pb-0">
            <v-row>
              <v-col cols="6" class="pt-0">
                <span>
                  <h1 class="form-title margin-auto text-capitalize">
                  {{teamArray.team_name}} 
                </h1>
                <v-chip
                  label
                  color="chip-custom-blue"
                  outlined
                  class="text-white p-3"
                  style="font-size: 21px !important; font-weight: 700"
                  >{{ teamArray.barcode }}</v-chip
                >
                </span>
              </v-col>
              <v-col cols="6" class="pt-0 text-right">
        
                <v-btn
                  v-on:click="goBack"
                  class="mx-2 custom-grey-border custom-bold-button"
                  depressed
                >
                  <v-icon left>mdi-keyboard-backspace</v-icon>
                  Back
                </v-btn>
              </v-col>
            </v-row>
          </div> -->

          <v-tabs
            v-model="engineerTab"
            background-color="transparent"
            centered
            color="cyan"
            fixed-tabs
            class="custom-tab-transparent"
          >
            <!--  <v-tab
              class="font-size-16 font-weight-600 px-8"
              :href="'#personal-info'"
              v-if="false"
            >
              <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
              Team Info
            </v-tab> -->
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              :href="'#engineer-info'"
            >
              <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
              Technicians Info
            </v-tab>

            <v-tab class="font-size-16 font-weight-600 px-8" href="#visits">
              <v-icon color="grey darken-4" left>mdi-notebook-check</v-icon>
              Visits
            </v-tab>
            <!--  <v-tab
              class="font-size-16 font-weight-600 px-8"
              :href="'#leave'"
              v-if="false"
            >
              Leave
            </v-tab> -->
          </v-tabs>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 90vh; position: relative"
          >
            <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
              <v-tab-item :value="'personal-info'">
                <!-- <h2 class="margin-auto color-custom-blue font-weight-600">
                  Team Info
                </h2> -->

                <PersonalInfo
                  :engineer="engineerArr"
                  :teamId="teamId"
                ></PersonalInfo>
              </v-tab-item>

              <v-tab-item :value="'visits'">
                <VisitListingTemplate
                  is-admin
                  visit-type="all"
                  internal
                  :team-id="teamId"
                ></VisitListingTemplate>
              </v-tab-item>

              <v-tab-item :value="'engineer-info'">
                <EngineerListingTemplate
                  :team-id="teamId"
                ></EngineerListingTemplate>
              </v-tab-item>

              <v-tab-item value="leave">
                <InternalLeave :user-id="engineerArr.user"> </InternalLeave>
              </v-tab-item>
            </v-tabs-items>
          </perfect-scrollbar>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PersonalInfo from "@/view/pages/team/detail/Staff-Personal-Info.vue";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";
//import Status from "@/view/pages/partials/Status.vue";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import EngineerListingTemplate from "@/view/pages/engineer/Engineer";
import { toSafeInteger, map } from "lodash";
import { QUERY } from "@/core/services/store/request.module";
//import InternalLeave from "@/view/pages/team/detail/Internal-Leave";

export default {
  mixins: [CommonMixin, ValidationMixin, EngineerMixin],
  // name: "engineer-detail",
  // title: "Engineer",
  data() {
    return {
      detail: new Object(),
      engineer: 0,
      teamId: 0,
      tab: null,
      // tab:null,
      panel: 0,
      engineerTab: null,
      pageLoading: true,
      deleteDialog: false,
      engineerArr: {},
      teamArray: {},
      engineerId: [],
    };
  },
  components: {
    //Status,
    VisitListingTemplate,
    PersonalInfo,
    //  InternalLeave,
    EngineerListingTemplate,
  },
  methods: {
    deleteEngineer() {
      const _this = this;
      if (!_this.$refs.engineerDeleteForm.validate()) {
        return false;
      }
    },
    getteam() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "team/" + _this.teamId,
        })
        .then(({ data }) => {
          _this.teamArray = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    const _this = this;
    _this
      .getteam()
      // _this.getEngineer()
      .then((response) => {
        // console.log(response.staffData.id,"response")
        let staffRow = map(response.staffData, (row) => row.id);
        _this.engineerId = staffRow;
        _this.engineerArr = response;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Team", route: "teams" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;

    _this.engineer = _this.$route.params.id;
    _this.teamId = toSafeInteger(_this.$route.params.teamId);
    _this.tab = _this.$route.params.tab;
    // console.log(_this.tab , "tab")

    if (_this.tab) {
      this.engineerTab = _this.tab;
      // console.log(this.engineerTab , "this.engineerTab")
    }

    if (!_this.teamId || _this.teamId <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getTitle() {
      let result = [];
      if (this.engineerArr && this.engineerArr.customer) {
        result.push(this.engineerArr.customer.display_name);
      }
      if (
        this.engineerArr &&
        this.engineerArr.customer &&
        this.engineerArr.customer.default_person
      ) {
        result.push(this.engineerArr.customer.default_person.display_name);
      }
      if (this.engineerArr && this.engineerArr.barcode) {
        result.push(this.engineerArr.barcode);
      }
      return result.join(" • ");
    },
    countryName() {
      let result = "";
      if (this.lodash.isEmpty(this.engineerArr.country) === false) {
        result = this.engineerArr.country.name;
      }
      return result;
    },
    getStatus() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.activated
        : false;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr && this.engineerArr.barcode
        : false;
    },
    getRouteParam() {
      let result = { customer: 0, id: 0 };
      if (this.lodash.isEmpty(this.engineerArr) === false) {
        if (this.engineerArr.id) {
          result.id = this.engineerArr.id;
        }
        if (this.engineerArr.customer) {
          result.customer = this.engineerArr.customer.id;
        }
      }
      return result;
    },
  },
};
</script>
