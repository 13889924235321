<template>
  <div class="recurring-schedule">
    <v-container fluid class="pt-0 px-0">
      <v-layout class="schedule-tab-item" :class="{ 'd-block': showCalendar }">
        <v-flex :md12="showCalendar" :md8="!showCalendar">
          <div class="schedule-tab-item-header custom-grey-border p-3">
            <RecurringScheduleHeader
              :showCalendar="showCalendar"
            ></RecurringScheduleHeader>
          </div>
          <div class="schedule-tab-item-body custom-grey-border p-3">
            <RecurringScheduleBody
              :showCalendar="showCalendar"
            ></RecurringScheduleBody>
          </div>
        </v-flex>
        <v-flex
          :md12="showCalendar"
          :md4="!showCalendar"
          :class="{ 'ml-3': !showCalendar, 'mt-3': showCalendar }"
        >
          <JobScheduleTeam></JobScheduleTeam>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import RecurringScheduleHeader from "@/view/pages/job/partials/Recurring-Schedule-Header.vue";
import RecurringScheduleBody from "@/view/pages/job/partials/Recurring-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/job/partials/Job-Schedule-Team.vue";

export default {
  name: "recurring-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  components: {
    RecurringScheduleHeader,
    RecurringScheduleBody,
    JobScheduleTeam,
  },
};
</script>
