<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="8" offset-md="2" class="gray-background">
            <div class="p-10">
              <table class="width-100">
                <tr>
                  <td class="pb-2 width-200px font-size-18 text-capitalize">
                    Full name:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <!--  {{ engineer.emergency_contact_name || " no name " }} -->
                    <template v-if="engineer.emergency_contact_name">{{
                      engineer.emergency_contact_name
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">no name</em></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">
                    Mobile number:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <!--  {{
                      engineer.emergency_contact_number || " no mobile number "
                    }} -->
                    <template v-if="engineer.emergency_contact_number">{{
                      engineer.emergency_contact_number
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >no mobile number</em
                      ></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Relation:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <!-- {{ engineer.emergency_contact_relation || " no relation " }} -->

                    <template v-if="engineer.emergency_contact_relation">{{
                      engineer.emergency_contact_relation
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">no relation</em></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Email:</td>
                  <td class="pb-2 font-size-18 font-weight-600">
                    <!-- {{ engineer.emergency_contact_email || " no email " }} -->

                    <template v-if="engineer.emergency_contact_email">{{
                      engineer.emergency_contact_email
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">no email</em></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Address:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template
                      v-if="
                        engineer.emergency_contact_unit_no ||
                        engineer.emergency_contact_street_1 ||
                        engineer.emergency_contact_street_2 ||
                        engineer.emergency_contact_postal_code
                      "
                      >{{ engineer.emergency_contact_unit_no }},
                      {{ engineer.emergency_contact_street_1 }},
                      {{ engineer.emergency_contact_street_2 }},
                      {{ engineer.emergency_contact_postal_code }}.</template
                    >
                    <template v-else
                      ><em class="text--secondary">no address</em></template
                    >

                    <!-- {{ engineer.emergency_contact_unit_no }},
                    {{ engineer.emergency_contact_street_1 }},
                    {{ engineer.emergency_contact_street_2 }},
                    {{ engineer.emergency_contact_postal_code }}. -->
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">Country:</td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    <template v-if="engineer.emergency_country_name"
                      >{{ engineer.emergency_country_name }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">no country</em></template
                    >
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "emergency-contact",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [],
    };
  },
};
</script>
