<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="850"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          Terminate Warranty :- {{ warrantyDetail.unique_id }}
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 90vh; position: relative"
          >
            <v-form
              ref="warrantyForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="update_or_create"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="12" class="py-0">
                    <label class="font-weight-600 ml-1">Terminate Date</label>
                    <DatePicker
                      solo
                      :pageLoading="pageLoading"
                      :placeholder="'Terminate Date'"
                      v-model="warrantyOption.terminate_date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <label class="font-weight-600 ml-1 required">Reason</label>
                    <v-textarea
                      v-model.trim="warrantyOption.terminate_reason"
                      auto-grow
                      dense
                      filled
                      solo
                      flat
                      :rules="[
                        validateRules.required(
                          warrantyOption.terminate_reason,
                          'Reason'
                        ),
                      ]"
                      color="cyan"
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                      label="Reason"
                      row-height="20"
                    ></v-textarea>
                    <div class="text-right">
                      {{
                        warrantyOption.terminate_reason
                          ? warrantyOption.terminate_reason.length
                          : 0
                      }}/200
                    </div>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <template v-for="(file, index) in files">
                      <v-row :key="index">
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0 d-flex align-items-center"
                        >
                          <v-file-input
                            hide-details
                            class="px-0"
                            dense
                            filled
                            solo
                            flat
                            v-model="file.file"
                            placeholder="Select File"
                            prepend-icon=""
                            prepend-inner-icon="$file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                            color="cyan"
                          />
                          <!--  <v-file-input
                            :id="`document-file-${index}`"
                            placeholder="Select File"
                            outlined
                            class="mt-3"
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment"
                            hide-details
                            v-model="file.file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                          ></v-file-input> -->
                        </v-col>
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0 d-flex align-items-center"
                        >
                          <v-text-field
                            hide-details
                            class="px-0"
                            v-model="file.name"
                            placeholder="File Name"
                            dense
                            filled
                            solo
                            flat
                            color="cyan"
                          />
                        </v-col>
                        <v-col
                          lg="1"
                          cols="1"
                          class="my-auto py-0"
                          style="text-align: center"
                        >
                          <v-btn
                            :disabled="files.length < 2"
                            v-on:click="removeFile(index)"
                            color="red lighten-1 white--text"
                            class="mx-2"
                            icon
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col lg="1" cols="1" class="my-auto py-0">
                          <v-btn
                            class="mx-2 custom-bold-button white--text"
                            color="cyan"
                            tile
                            depressed
                            v-on:click="addMore()"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="update_or_create"
              class="mx-2 custom-bold-button white--text"
              color="red"
              >Yes! Terminate
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import {
  /* , PUT, CLEAR_ERROR , */ GET,
  POST,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    /*  allCustomerList: {
        type: Array,
        default: () => {
          return new Array();
        },
      },
      allProductList: {
        type: Array,
        default: () => {
          return new Array();
        },
      }, */
    warrantyDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    DatePicker,
  },
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          /*  this.getOptions();
               this.getAllCustomers();
               this.getCustomerEquipment();  */
        }
      },
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: false,
      startDatePicker: null,
      files: [
        {
          file: null,
          name: null,
        },
      ],
      warrantyOption: new Object({
        id: null,
        terminate_date: null,
        terminate_reason: null,
      }),
    };
  },
  methods: {
    update_or_create() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }
      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = new FormData();

      if (this.warrantyOption && this.warrantyOption.terminate_date) {
        formData.append(
          "terminate_date",
          this.warrantyOption.terminate_date
            ? this.warrantyOption.terminate_date
            : null
        );
      }
      if (this.warrantyOption && this.warrantyOption.terminate_reason) {
        formData.append(
          "terminate_reason",
          this.warrantyOption.terminate_reason
            ? this.warrantyOption.terminate_reason
            : null
        );
      }
      for (let i = 0; i < this.files.length; i++) {
        if (this.files && this.files[i] && this.files[i].file) {
          formData.append(`file[${i}][file]`, this.files[i].file);
          formData.append(`file[${i}][name]`, this.files[i].name);
        }
      }

      let requestTYPE = POST;
      let requestURL = `warranty-terminate/${this.warrantyDetail.id}`;
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    updateFile(index, file, value, data) {
      if (value == "keyword") {
        if (file && file.name) {
          data[index].name = file.name.split(".").slice(0, -1).join(".");
          data[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          data[index].name = null;
          data[index].suffix = null;
        }
      } else {
        if (file && file.name) {
          this.files[index].name = file.name.split(".").slice(0, -1).join(".");
          this.files[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          this.files[index].name = null;
          this.files[index].suffix = null;
        }
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.warrantyOption.terminate_reason;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.warrantyOption.terminate_reason = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.warrantyOption.terminate_reason &&
        this.warrantyOption.terminate_reason.length > 199
      ) {
        e.preventDefault();
      }
    },
    getAllCustomers() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer-list" })
        .then(({ data }) => {
          _this.allCustomerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerEquipment() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "equipment-list" })
        .then(({ data }) => {
          _this.allEquipemtList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "warranty/options" })
        .then(({ data }) => {
          _this.barcode = data;
          this.warrantyOption.unique_id = _this.barcode.barcode;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
    removeWarranty(index) {
      this.defaultItemWarranty.splice(index, 1);
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
