<template>
   <CreateUpdateTemplate :routePreventDialog="routePreventDialog" :customClass="'product-create create-form'"
   > 
   <template v-slot:header-title>
    <h1 class="form-title d-flex">
      <template v-if="adjustmentId">Create</template>
      <template v-else>Create</template> Adjustment
    </h1>
   </template>
   <template v-slot:header-action>
    <v-btn
    :disabled="!formValid || formLoading"
    :loading="formLoading"
    class="mx-2 custom-bold-button text-white"
    color="cyan"
    v-on:click="onSubmit(1)"
  >
    Save Adjustment
  </v-btn>
  <!--  <v-btn
    :disabled="!formValid || formLoading"
    :loading="formLoading"
    class="mx-2 custom-bold-button text-white"
    color="cyan"
    v-on:click="onSubmit(3)"
  >
    Approve and Adjust
  </v-btn> -->
  <v-btn
    v-on:click="goBack"
    :disabled="formLoading"
    class="mx-2 custom-grey-border custom-bold-button"
    outlined
    >Cancel
  </v-btn>

   </template>
   <template v-slot:body>
    <v-form
      ref="adjustmentForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit(1)"
    >
      <v-row>
        <v-col cols="12">
            <v-container fluid>
              <v-card flat class="remove-border-radius">
                <v-card-text ref="overview" class="p-6 font-size-16">
                  <v-row dense>
                    <v-col cols="7" class="pb-0">
                      <label class="font-weight-600 font-size-18 ml-1"
                        >Supplier</label
                      >
                      <div
                        style="margin-bottom: -17px !important"
                        v-for="(row, index) in adjustment.suppliers"
                        :key="index"
                      >
                        <v-autocomplete
                          hide-details
                          solo
                          clearable
                          @click:clear="getproduct"
                          flat
                          dense
                          :items="
                            supplierList(
                              adjustment.suppliers[index].supplier_id
                            )
                          "
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="supplier"
                          placeholder="Supplier"
                          v-model="adjustment.suppliers[index].text"
                          v-on:change="
                            getProductPrice(adjustment.suppliers[index].text)
                          "
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Supplier Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                      <v-layout class="nmt-1 bg-quo" v-if="suppliersingle_id">
                        <v-flex md6 class="mr-2 custom-border-right">
                          <table width="100%">
                            <tr>
                              <td class="font-weight-600">
                                <label>Contact details</label>
                              </td>
                            </tr>
                            <tr
                              v-if="contactDetail && contactDetail.display_name"
                            >
                              <td class="py-0">
                                <span
                                  class="mr-2"
                                  v-if="
                                    contactDetail && contactDetail.display_name
                                  "
                                >
                                  <v-icon small>mdi-account</v-icon></span
                                >
                                <label>{{ contactDetail.display_name }}</label>
                              </td>
                            </tr>
                            <em v-else class="text-muted ml-2">
                              no contact detail</em
                            >
                            <tr
                              v-if="
                                contactDetail && contactDetail.primary_phone
                              "
                            >
                              <td class="py-0">
                                <span
                                  class="mr-2"
                                  v-if="
                                    contactDetail && contactDetail.primary_phone
                                  "
                                >
                                  <v-icon small>mdi-phone</v-icon></span
                                >
                                <label
                                  v-if="
                                    contactDetail && contactDetail.primary_phone
                                  "
                                  >{{
                                    contactDetail && contactDetail.primary_phone
                                  }}</label
                                >
                                <em v-else class="text-muted">
                                  no phone number</em
                                >
                              </td>
                            </tr>
                            <tr
                              v-if="
                                contactDetail && contactDetail.primary_email
                              "
                            >
                              <td class="py-0">
                                <span
                                  class="mr-2"
                                  v-if="
                                    contactDetail && contactDetail.primary_email
                                  "
                                >
                                  <v-icon small>mdi-email</v-icon></span
                                >
                                <label
                                  v-if="
                                    contactDetail && contactDetail.primary_email
                                  "
                                  >{{
                                    contactDetail && contactDetail.primary_email
                                  }}</label
                                >
                                <em v-else class="text-muted"> no email</em>
                              </td>
                            </tr>
                          </table>
                        </v-flex>
                        <v-flex md6 class="mr-2">
                          <table width="100%">
                            <tr>
                              <td class="font-weight-600">
                                <label>Company Address</label>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                contactDetail &&
                                contactDetail.supplier_address &&
                                contactDetail.supplier_address.property_address
                              "
                            >
                              <td class="py-0 d-flex">
                                <span
                                  class="mr-2"
                                  v-if="
                                    contactDetail &&
                                    contactDetail.supplier_address &&
                                    contactDetail.supplier_address
                                      .property_address
                                  "
                                >
                                  <v-icon small>mdi-map-marker</v-icon></span
                                >
                                <label
                                  v-if="
                                    contactDetail &&
                                    contactDetail.supplier_address &&
                                    contactDetail.supplier_address
                                      .property_address
                                  "
                                  >{{
                                    contactDetail.supplier_address.street_1
                                  }},
                                  <br
                                    v-if="
                                      contactDetail.supplier_address.street_2 ||
                                      contactDetail.supplier_address.unit_no
                                    "
                                  />
                                  <template
                                    v-if="
                                      contactDetail.supplier_address.street_2
                                    "
                                  >
                                    {{
                                      contactDetail.supplier_address.street_2
                                    }},
                                  </template>
                                  <template
                                    v-if="
                                      contactDetail.supplier_address.unit_no
                                    "
                                  >
                                    {{
                                      contactDetail.supplier_address.unit_no
                                    }},
                                  </template>
                                  <br />
                                  {{ contactDetail.supplier_address.country }}
                                  <template
                                    v-if="
                                      contactDetail.supplier_address.zip_code &&
                                      contactDetail.supplier_address.zip_code !=
                                        '000000'
                                    "
                                  >
                                    ,
                                    {{
                                      contactDetail.supplier_address.zip_code
                                    }}
                                  </template>
                                </label>
                              </td>
                            </tr>
                            <em v-else class="text-muted ml-2">
                              no company address</em
                            >
                          </table>
                        </v-flex>
                      </v-layout>
                      <label class="font-weight-600 font-size-18 ml-1 mt-2"
                        >Description</label
                      >
                      <v-textarea
                        v-model.trim="adjustment.description"
                        auto-grow
                        dense
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        label="Description"
                        solo
                        style="margin-bottom: -15px"
                        flat
                        :rules="[
                          validateRules.minLength(
                            adjustment.description,
                            'Description',
                            2
                          ),
                          validateRules.maxLength(
                            adjustment.description,
                            'Description',
                            1024
                          ),
                        ]"
                        row-height="25"
                        hide-details
                        v-on:keypress="(e) => manageLimit(e)"
                        v-on:paste="(e) => onPaste(e)"
                      ></v-textarea>
                      <div
                        class="d-flex justify-end"
                        style="margin-bottom: -13px !important"
                      >
                        {{
                          adjustment.description
                            ? adjustment.description.length
                            : 0
                        }}/200
                      </div>
                      <label class="font-weight-600 font-size-18 ml-1 mt-2"
                        >Choose Product</label
                      >
                      <template v-if="!productCreateadjustmentId">
                        <v-autocomplete
                          hide-details
                          solo
                          flat
                          item-text="nameBarcode"
                          item-value="id"
                          dense
                          :items="ProductListfunction(selected_product)"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="product"
                          placeholder="Please add products to order list"
                          v-model="selected_product"
                          v-on:change="getProducttabledata(selected_product)"
                          class="mt-0"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="
                                    'No Please add products to order list.'
                                  "
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-if="false"
                              v-bind="attr"
                              :input-value="selected"
                              color="blue-grey"
                              class="white--text"
                              v-on="on"
                            >
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-col>
                    <v-col cols="5" class="pb-0">
                      <label class="font-weight-600 font-size-18 ml-1 required"
                        >Type</label
                      >
                      <v-autocomplete
                        style="margin-bottom: -13px !important"
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="Typeadjustment"
                        placeholder="Type"
                        solo
                        flat
                        item-text="name"
                        item-value="name"
                        v-model="adjustment.type"
                        :rules="[
                          validateRules.required(adjustment.type, 'Type'),
                        ]"
                      >
                      </v-autocomplete>
                      <label class="font-weight-600 font-size-18 ml-1"
                        >Reference #</label
                      >
                      <v-text-field
                        v-model.trim="adjustment.reference"
                        style="margin-bottom: -13px !important"
                        dense
                        filled
                        flat
                        label="Reference #"
                        hide-details
                        solo
                        :rules="[
                          validateRules.minLength(
                            adjustment.reference,
                            'Reference #',
                            2
                          ),
                          validateRules.maxLength(
                            adjustment.reference,
                            'Reference #',
                            100
                          ),
                        ]"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                      <label
                        class="font-weight-600 font-size-18 ml-1 mt-2 required"
                        >Date</label
                      >
                      <div class="custom-margin">
                        <DatePicker
                          solo
                          mandatory
                          v-on:update:date-picker="updateDate"
                          :pageLoading="pageLoading"
                        ></DatePicker>
                      </div>
                      <label class="font-weight-600 font-size-18 ml-1 mt-2"
                        >Reason</label
                      >
                      <v-textarea
                        v-model.trim="adjustment.reason"
                        style="margin-bottom: -13px !important"
                        auto-grow
                        dense
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        label="Reason"
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            adjustment.reason,
                            'Reason',
                            2
                          ),
                          validateRules.maxLength(
                            adjustment.reason,
                            'Reason',
                            100
                          ),
                        ]"
                        row-height="25"
                        hide-details
                        v-on:keypress="(e) => ReasonmanageLimit(e)"
                        v-on:paste="(e) => ReasononPaste(e)"
                      ></v-textarea>
                      <div
                        class="d-flex justify-end"
                        style="margin-bottom: -13px !important"
                      >
                        {{
                          adjustment.reason ? adjustment.reason.length : 0
                        }}/100
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 89vh position: relative"
              >
                <v-row>
                  <v-col md="12" class="custom-line-item-template">
                    <!--  <v-col
                      md="12"
                      class="py-0"
                      v-if="!productCreateadjustmentId"
                    >
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        item-text="nameBarcode"
                        item-value="id"
                        dense
                        :items="ProductListfunction(selected_product)"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Please add products to order list"
                        v-model="selected_product"
                        v-on:change="getProducttabledata(selected_product)"
                        class="mt-0"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Please add products to order list.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-if="false"
                            v-bind="attr"
                            :input-value="selected"
                            color="blue-grey"
                            class="white--text"
                            v-on="on"
                          >
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col> -->

                    <table class="custom-border-top width-100">
                      <thead>
                        <tr>
                          <th width="500" class="font-size-16 py-2">
                            <span class="pl-2"> Product</span>
                          </th>

                          <th class="pl-5 font-size-16 py-2">
                            <span class="pl-2"> Available Quantity</span>
                          </th>
                          <th class="pl-5 font-size-16 py-2">
                            <span class=""> Type</span>
                            <!-- <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-2" v-bind="attrs" v-on="on"
                                  >mdi-progress-question</v-icon
                                >
                              </template>
                              <span>Maximum allowed Qty is 9999</span>
                            </v-tooltip> -->
                          </th>
                          <th class="pl-5 font-size-16 py-2">
                            <span class="pl-2"> Adjusted Quantity</span>
                            <!-- <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-2" v-bind="attrs" v-on="on"
                                  >mdi-progress-question</v-icon
                                >
                              </template>
                              <span>Maximum allowed Qty is 9999</span>
                            </v-tooltip> -->
                          </th>
                          <th class="pl-5 font-size-16 py-2" v-if="false">
                            <span class="pl-2"> Updated Quantity</span>
                            <!-- <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mx-2" v-bind="attrs" v-on="on"
                                  >mdi-progress-question</v-icon
                                >
                              </template>
                              <span>Maximum allowed Qty is 9999</span>
                            </v-tooltip> -->
                          </th>
                          <th class="pl-5 font-size-16 py-2">
                            <span class="pl-2"> Serial Number</span>
                          </th>
                          <!-- <th class="pl-5 font-size-16 py-2">
                            <span class="pl-2">  Delete</span>
                          </th> -->
                        </tr>
                        <tr>
                          <td
                            colspan="8"
                            class="custom-border-bottom first-border"
                          ></td>
                        </tr>
                      </thead>

                      <tbody v-if="adjustmentItems.length > 0">
                        <tr
                          class="custom-line-item"
                          v-for="(item, index) in adjustmentItems"
                          :key="index"
                        >
                          <!--  <td class="pt-4">
                            <v-img
                              :lazy-src="$defaultImage"
                              :src="item.image"
                              width="100"
                              class="margin-auto"
                            >
                            </v-img>
                          </td> -->

                          <td class="pl-5 pt-4">
                            <span>{{ `${item.barcode} - ${item.name}` }}</span>
                          </td>

                          <!-- <td
                            class="pl-5 pt-4"
                            :class="{ 'pb-4': !adjustmentItems[index + 1] }"
                          >
                            <v-autocomplete
                              v-model="item.product"
                              clearable
                              :items="lineItemList"
                              hide-details
                              dense
                              filled
                              flat
                              label="Search..."
                              solo
                              :rules="[
                                validateRules.required(item.product, 'Product'),
                              ]"
                              item-color="cyan"
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              item-text="name"
                              item-value="id"
                              v-on:change="updateLineItem(item.product, index)"
                              class="remove-border-radius hide-border-bottom"
                              return-object
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title
                                    v-html="'Nothing Found.'"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-avatar
                                  color="cyan"
                                  class="headline font-weight-light white--text text-uppercase"
                                >
                                  {{ item.name.charAt(0) }}
                                </v-list-item-avatar>
                                <v-list-item-content class="max-width-350px">
                                  <v-list-item-title
                                    class="font-size-16 text-ellipsis pb-1 text-capitalize"
                                    v-text="item.name"
                                  ></v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="item.description"
                                    class="font-size-14 text-ellipsis pb-1"
                                    v-text="item.description"
                                  ></v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    class="font-size-14 pb-1"
                                    ><span class="font-weight-500"
                                      >Selling Cost / Unit Cost:</span
                                    >
                                    {{
                                      formatNumber(item.selling_cost)
                                    }}</v-list-item-subtitle
                                  >
                                  <v-list-item-subtitle class="font-size-14"
                                    ><span class="font-weight-500"
                                      >Available Stock:</span
                                    >
                                    {{
                                      accountingUnFormat(item.available_stock)
                                    }}</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <v-textarea
                              v-model.trim="item.description"
                              auto-grow
                              dense
                              filled
                              flat
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Description"
                              solo
                              color="cyan"
                              class="remove-border-radius"
                              hide-details
                              row-height="20"
                              :rules="[
                                validateRules.minLength(
                                  item.description,
                                  'Description',
                                  2
                                ),
                                validateRules.maxLength(
                                  item.description,
                                  'Description',
                                  1024
                                ),
                              ]"
                            ></v-textarea>
                          </td> -->
                          <td class="pl-5 pt-4">
                            <v-text-field
                              v-model.number="item.initial_stock"
                              dense
                              filled
                              flat
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Available Quantity"
                              type="number"
                              min="0"
                              max="9999"
                              hide-details
                              solo
                              readonly
                              class="remove-border-radius"
                            ></v-text-field>
                          </td>
                          <td class="pl-5 pt-4">
                            <v-autocomplete
                              v-model="item.calculation_type"
                              clearable
                              :items="calculation_type"
                              hide-details
                              dense
                              filled
                              flat
                              label="Type..."
                              solo
                              item-color="cyan"
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              item-text="text"
                              item-value="id"
                              class="remove-border-radius hide-border-bottom"
                              return-object
                              v-on:change="
                                adjustedquantitycalculation(item, index)
                              "
                              :rules="[
                                validateRules.required(
                                  item.calculation_type,
                                  'Type'
                                ),
                              ]"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title
                                    v-html="'Nothing Found.'"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </td>

                          <td class="pl-5 pt-4">
                            <v-text-field
                              v-model.trim="item.adjusted_quantity"
                              dense
                              filled
                              flat
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Adjusted Quantity"
                              v-mask="'####'"
                              hide-details
                              solo
                              :rules="[
                                validateRules.required(
                                  item.adjusted_quantity,
                                  'Adjusted Quantity'
                                ),
                              ]"
                              hide-spin-buttons
                              @keyup="adjustedquantitycalculation(item, index)"
                              v-on:change="
                                adjustedquantitycalculation(item, index)
                              "
                            ></v-text-field>
                          </td>
                          <!--  <td class="pl-5 pt-4">
                            <v-text-field
                              v-model.number="item.totalQuan"
                              dense
                              filled
                              flat
                              readonly
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Adjusted Quantity"
                              hide-details
                              solo
                            ></v-text-field>
                          </td> -->
                          <td class="pl-5 pt-4">
                            <v-text-field
                              v-model="item.serial_number"
                              dense
                              filled
                              flat
                              color="cyan"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Serial Number"
                              hide-details
                              solo
                              class="remove-border-radius"
                            ></v-text-field>
                          </td>
                          <td
                            class="pl-5 pt-2"
                            v-if="!productCreateadjustmentId"
                          >
                            <template v-if="adjustmentItems.length >= 1">
                              <div>
                                <v-icon
                                  color="red"
                                  class="mt-2"
                                  v-on:click="removeLineItem(index, item.id)"
                                  >mdi-delete</v-icon
                                >
                                <!-- <v-btn
                                  tile
                                  :disabled="pageLoading"
                                  class="my-2 custom-bold-button"
                                  color="red"
                                  outlined
                                  small
                                  v-on:click="removeLineItem(index, item.id)"
                                  ><strong>Delete</strong>
                                </v-btn> -->
                              </div>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <!-- <tfoot v-if="!$route.query.product">
                        <tr>
                          <td colspan="5" class="custom-border-top">
                            <div>
                              <v-btn
                                :disabled="pageLoading"
                                class="mx-2 my-2 custom-bold-button white--text"
                                color="cyan"
                                v-on:click="addLineItem"
                                ><v-icon small left
                                  >mdi-format-align-left</v-icon
                                >
                                Add Line Item
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tfoot> -->
                    </table>
                  </v-col>
                  <v-col md="12" class="mt-2">
                    <v-card
                      style="min-height: 100%"
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Attachment
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <div v-if="getPermission('attachment:create')">
                          <!--  <label class="custom-form-label width-100"
                    >Images
                    
                  </label> -->
                          <div class="service-detail-height">
                            <!-- <FileTemplateName
                              allowUpload
                              isMinDisplay
                              :file-type="true"
                              :attachments="adjustment.product_images"
                              v-on:file:updated="updateImages"
                            ></FileTemplateName> -->
                            <FileTemplate
                              allowUpload
                              isMinDisplay
                              :file-type="true"
                              :attachments="adjustment.product_images"
                              v-on:file:updated="updateImages"
                              acceptFile="image/png, image/jpeg, image/jpg"
                            ></FileTemplate>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </perfect-scrollbar>
            </v-container>
        </v-col>
      </v-row>
    </v-form>

    <Dialog :common-dialog="adjustedquantitydialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
          <v-container class="px-10" fluid>
            <p class="font-weight-600 font-size-19">
              Product QTY. will become negative. Are you sure you want proceed ?
            </p>
            <!--  <v-layout class="my-4">
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    row-height="25"
                  ></v-textarea>
                </v-flex>
              </v-layout> -->
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirm_loading"
          v-on:click="quantityDialog(false)"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          :disabled="!formValid || confirm_loading"
          :loading="confirm_loading"
          v-on:click="quantityDialog(true)"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </Dialog>

   </template>
   </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import FileTemplate from "@/view/pages/partials/FileTemaplateadjustment.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import {
  POST,
  GET,
  QUERY,
  /* DELETE, */ PUT,
} from "@/core/services/store/request.module";

import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import DatePicker from "@/view/pages/partials/Datepicker";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { cloneDeep, filter, find, forEach, map, isEmpty } from "lodash";
import Dialog from "@/view/pages/partials/Dialog";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "product-adjustment-create",
  data() {
    return {
      vendorList: [],
      list: [],
      productoptiondata: null,
      adjusted_quantity_index: null,
      adjustedquantitydialog: false,
      confirm_loading: false,
      adjustmentCreated : false,
      suppliersingle_id: null,
      /*   totalQuan : 0, */
      adjustment: {
        reference: null,
        type: null,
        date: null,
        reason: null,
        description: null,
        product_images: [],
        totalQuan: 0,
        supplier_id: null,

        suppliers: [
          {
            id: null,
            uuid: null,
            product_id: null,
            supplier_price: null,
            part_number: null,
          },
        ],
      },
      pageLoading: false,
      adjustmentId: null,
      lineItemList: [],
      ProductList: [],
      selected_product: null,
      contactDetail: {},
      ProductListNew: [],
      calculation_type: [
        {
          id: 1,
          text: "Subtraction",
        },
        {
          id: 2,
          text: "Addition",
        },
      ],
      Typeadjustment: [
        {
          id: 0,
          name: "Purchase Order",
        },
        {
          id: 1,
          name: "Delivery Order",
        },
        {
          id: 2,
          name: "Quotation",
        },
      ],
      adjustmentItems: [
        // {
        //   id: null,
        //   image: null,
        //   batch: null,
        //   product: null,
        //   product_id: null,
        //   description: null,
        //   initial_stock: null,
        //   adjusted_quantity: null,
        //   adjusted_qty: null,
        //   serial_number: null,
        // },
      ],
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    CreateUpdateTemplate,
    Dialog,
  },
  methods: {
    clear() {
      alert(2121);
    },
    quantityDialog(status) {
      //alert(status);
      if (!status) {
        this.adjustmentItems[this.adjusted_quantity_index].adjusted_quantity =
          null;

        this.adjustedquantitydialog = false;
      } else {
        this.adjustedquantitydialog = false;
      }
    },
    adjustedquantitycalculation(params, index) {
      this.adjusted_quantity_index = index;
      const adjusted_quantity = params.adjusted_quantity;
      const available_quantity = params.initial_stock;
      if (
        params &&
        params.calculation_type &&
        params.calculation_type.text == "Subtraction"
      ) {
        if (available_quantity < adjusted_quantity) {
          this.adjustedquantitydialog = true;
        }
      }
      if (
        params &&
        params.calculation_type &&
        params.calculation_type.text == "Subtraction" &&
        params.adjusted_quantity
      ) {
        this.adjustmentItems[index].totalQuan =
          Number(available_quantity) - Number(adjusted_quantity);
      }
      if (
        params &&
        params.calculation_type &&
        params.calculation_type.text == "Addition" &&
        params.adjusted_quantity
      ) {
        this.adjustmentItems[index].totalQuan =
          Number(available_quantity) + Number(adjusted_quantity);
      }

      // console.log(params,"3333");
    },
    updateImages(param) {
      this.adjustment.images = param;
    },

    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.adjustment.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.adjustment.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.adjustment.description &&
        this.adjustment.description.length > 199
      ) {
        e.preventDefault();
      }
    },

    ReasononPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.adjustment.reason;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.adjustment.reason = trimValue;
        e.preventDefault();
      }
    },

    ReasonmanageLimit(e) {
      if (this.adjustment.reason && this.adjustment.reason.length > 99) {
        e.preventDefault();
      }
    },

    updateDate(date) {
      this.adjustment.date = date;
    },
    setProductImage(index) {
      try {
        let product_images = this.adjustmentItems[index].product.product_image;
        if (product_images) {
          this.adjustmentItems[index].image = product_images.file.url;
        }
      } catch (error) {
        this.logError(error);
        this.adjustmentItems[index].image = null;
      }
    },
    fixQuantity(value, index, type) {
      if (type == "stock_on_hand") {
        this.adjustmentItems[index].adjusted_qty =
          this.lodash.toSafeInteger(value) -
          this.lodash.toSafeInteger(this.adjustmentItems[index].available_qty);
      } else if (type == "adjusted_qty") {
        this.adjustmentItems[index].stock_on_hand =
          this.lodash.toSafeInteger(value) +
          this.lodash.toSafeInteger(this.adjustmentItems[index].available_qty);
      }
    },
    removeLineItem(index, id) {
      // console.log("id",id)

      const _this = this;
      // console.log(_this.selected_product)

      if (_this.selected_product == id) {
        _this.selected_product = null;
      }
      if (id > 0) {
        _this.adjustmentItems.splice(index, 1);
        /*  _this.$store
          .dispatch(DELETE, { url: "line-item/" + id }) */
        /* .then(() => {
            _this.adjustmentItems.splice(index, 1);
          }) */
        /* .catch((error) => {
            _this.logError(error);
          }); */
      } else {
        _this.adjustmentItems.splice(index, 1);
      }
    },
    addLineItem() {
      this.adjustmentItems.push({
        id: null,
        image: null,
        batch: null,
        product: null,
        product_id: null,
        description: null,
        available_qty: null,
        stock_on_hand: null,
        adjusted_qty: null,
      });
    },
    updateLineItem(product, index) {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(product) === false) {
          _this.adjustmentItems[index].product = product;
          _this.adjustmentItems[index].batch = product.default_batch.id;
          _this.adjustmentItems[index].available_qty = product.available_stock;
          _this.adjustmentItems[index].description = product.description;
          _this.adjustmentItems[index].product_id = product.id;
          let focusElement = _this.$refs[`stock_on_hand_${index}`];
          if (focusElement[0]) {
            _this.$nextTick(() => focusElement[0].focus());
          }
          _this.setProductImage(index);
        } else {
          _this.clearLineItem(index);
        }
      } catch (error) {
        _this.clearLineItem(index);
        _this.logError(error);
      }
    },
    clearLineItem(index) {
      this.adjustmentItems[index] = new Object({
        id: null,
        image: null,
        batch: null,
        product: null,
        product_id: null,
        description: null,
        available_qty: null,
        stock_on_hand: null,
        adjusted_qty: null,
      });
    },
    getLineItems() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "product/get-line-products" })
        .then((response) => {
          _this.lineItemList = response.data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.setDefaultProduct();
        });
    },

    getProductPrice(id) {
      this.suppliersingle_id = id;
      if (this.suppliersingle_id > 0) {
        this.getSupplierContactAddress(this.suppliersingle_id);
      }
      this.selected_product = null;
      const ID = Number(id);
      const list = [];
      if (this.productCreateadjustmentId) {
        return;
      }
      forEach(this.ProductListNew, (ele) => {
        if (ele.suppliers.length > 0) {
          const _product = find(ele.suppliers, (row) => row.id == ID);
          if (_product) {
            list.push(ele);
          }
        }
      });
      this.ProductList = list;
      let lineItemData = map(list, function (row) {
        row.nameBarcode = `${row.barcode} - ${row.name}`;
        return row;
      });
      this.ProductList = lineItemData;
      this.adjustmentItems = [];
    },
    getProducttabledata(params) {
      const newArray = filter(this.ProductListNew, (row) => {
        if (row.id == params) {
          this.adjustmentItems.push({
            id: row.id,
            product_id: row.id,
            image: row.image,
            barcode: row.barcode,
            name: row.name,
            initial_stock: row.initial_stock,
            adjusted_qty: row.adjusted_qty,
            serial_number: row.barcode,
            adjusted_quantity: null,
            /* calculation_type:  {
          id: 2,
          text: "Addition",
        }, */
          });

          // this.ProductList.barcode = 0;
          return row;
        }
      });

      // this.ProductList.barcode = 0;
      console.log(newArray, "params");
    },
    getSupplierContactAddress(id) {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `supplier/options/${id}`, supplier: id })
        .then((response) => {
          _this.contactDetail = response.data;
          console.log(
            _this.contactDetail,
            "_this.contactDetail_this.contactDetail"
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getproduct() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "listing/all" })
        .then((response) => {
          // console.log(response.data.list);
          _this.ProductListall = response.data.list;
          let productData = map(_this.ProductListall, function (row) {
            console.log(row, "row");
            row.nameBarcode = `${row.barcode} - ${row.name}`;
            return row;
          });
          _this.ProductList = productData;

          _this.ProductListNew = response.data.list;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.setDefaultProduct();
        });
    },

    createAdjustment() {
      const _this = this;
      // console.log(_this.suppliersingle_id,"hello");
      console.log(_this.adjustmentItems, "_this.line");
      if (isEmpty(_this.adjustmentItems)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product then try again.")
        );
        return false;
      }
      /* if(_this.adjustmentItems.lenght==0){
        ErrorEventBus.$emit(
              "update:error",
              InitializeError("Line items are not created. Please try again.")
            );
            return false;
      }
 */

      return new Promise((resolve, reject) => {
        // console.log(_this.suppliersingle_id," _this.suppliersingle_id");
        _this.$store
          .dispatch(POST, {
            url: "product/adjustment",
            data: {
              ..._this.adjustment,
              suppliersingle_id: _this.suppliersingle_id,
            },
          })
          .then(({ data }) => {
            this.adjustmentCreated = true;
            this.forcePush = true; 
            resolve(data.id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createAdjustmentLineItem() {
      const _this = this;
      if (isEmpty(_this.adjustmentItems)) {
        /*  ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product then try again.")
        ); */
        return false;
      }

      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PUT, {
            url: "product/adjustment/" + _this.adjustmentId + "/line-item",
            data: {
              line_items: _this.adjustmentItems,
              suppliersingle_id: _this.suppliersingle_id,
            },
          })
          .then(({ data }) => {
            resolve(data.id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async onSubmit(status) {
      const _this = this;
      try {
        if (!_this.$refs.adjustmentForm.validate()) {
          return false;
        }

        _this.formLoading = true;
        _this.adjustment.status = _this.lodash.toSafeInteger(status);
        if (!_this.adjustmentId) {
          _this.adjustmentId = await _this.createAdjustment();
        }

        await _this.createAdjustmentLineItem();

        _this.formLoading = false;
        if (_this.productCreateadjustmentId) {
          _this.$router.push({ name: "admin.adjustment" });
          return;
        }
        if (_this.adjustmentId) {
          _this.$router.go(-1);
        }
      } catch (error) {
        _this.formLoading = false;
        _this.logError(error);
      }
    },
    setDefaultProduct() {
      const _this = this;
      let product = _this.lodash.toSafeInteger(_this.$route.query.product);
      let index = _this.lodash.findIndex(_this.lineItemList, function (row) {
        return (row.id = product);
      });
      if (
        index >= 0 &&
        _this.lodash.isEmpty(_this.lineItemList[index]) === false
      ) {
        _this.updateLineItem(_this.lineItemList[index], 0);
      }
    },

    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "adjustment/options",
          data: {
            product_type: _this.product_type,
            product_Id: _this.productCreateadjustmentId,
          },
        })
        .then((response) => {
          this.productoptiondata = response.data;
          console.log(this.productoptiondata, "this.productoptiondata");
          this.adjustmentItems.push({
            id: this.productoptiondata.id,
            product_id: this.productoptiondata.id,
            image: this.productoptiondata.image,
            barcode: this.productoptiondata.barcode,
            name: this.productoptiondata.name,
            initial_stock: this.productoptiondata.initial_stock,
            adjusted_qty: this.productoptiondata.adjusted_qty,
            serial_number: this.productoptiondata.serial_number,
            adjusted_quantity: null,
          });

          //   this.adjustment.suppliers =   this.productoptiondata.product_supplier;
          //  this.supplierList( this.productoptiondata.product_supplier[0].supplier);

          this.vendorList = map(
            this.productoptiondata.product_supplier,
            function (row) {
              // row.nameBarcode = `${row.supplier_data.barcode} - ${row.supplier_data.display_name}`;
              return {
                value: row.supplier,
                text: `${row.supplier_data.barcode} - ${row.supplier_data.display_name}`,
              };
            }
          );
          console.log(this.vendorList, "response");

          //     console.log(this.vendorList,'Hi')

          //   this.vendorList = this.productoptiondata.product_supplier
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  computed: {
    ProductListfunction: () => {
      return function (id) {
        console.log(id, "id");
        let _productList = cloneDeep(this.ProductList);
        //  console.log(this.productCreate.suppliers,'111')
        const newArray = filter(_productList, (row) => {
          // if (row.id != id) {
          if (
            !find(this.adjustmentItems, (inner_row) => inner_row.id == row.id)
          ) {
            return row;
          }
          // } else {
          //   return row;
          // }
        });
        return newArray;
      };
    },
    supplierList: () => {
      return function (id) {
        // console.log(id,'id');
        let _vendorList = cloneDeep(this.vendorList);
        // console.log(_vendorList, "_vendorList");
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(this.adjustment.suppliers, (inner_row) => inner_row == row)
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.adjustmentCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  mounted() {
    const _this = this;
    _this.getLineItems();
    _this.getproduct();

    if (_this.product_type == "goods" && _this.productCreateadjustmentId > 0) {
      _this.getOptions();
    }

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Adjustment", route: "adjustment" },
      /* { title: "Adjustment" }, */
      { title: "Create" },
    ]);

    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      if (!this.productCreateadjustmentId) {
        this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
      }
      // console.log(this.vendorList, "_vendorList");
      //console.log(this.$store.state.config.localDB['data']['suppliers'],"2222");
    });
  },
  created() {
    const _this = this;
    _this.productCreateadjustmentId = _this.lodash.toSafeInteger(
      _this.$route.query.generate_adjustment
    );
    _this.product_type = _this.$route.query.product_type;
  },
};
</script>
