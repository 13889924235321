<template>
  <Dialog :common-dialog="failedDialog" :dialog-width="dialogWidth">
    <template v-slot:title> Confirmation </template>
    <template v-slot:body>
      <v-form
        ref="mail_form"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="submit()"
      >
        <v-container class="px-10">
          <!--  <p class="font-weight-600 font-size-19 ml-2">
                        Are you sure, you want to
                        <span class="font-weight-700">Failed</span>?
                    </p> -->
          <!--    <p v-else class="font-weight-600 font-size-19">
                            You are cancel a contract job, do you want to reverse consume
                            service back to contract?
                        </p> -->
          <label
            for="cancel-reason"
            class="font-weight-600 font-size-18 ml-1 required"
            >Reason</label
          >
          <v-autocomplete
            dense
            color="cyan"
            filled
            id="payment-mode"
            :items="resonList"
            :disabled="formLoading"
            style="margin-bottom: 8px !important"
            :rules="[validateRules.required(cancel_reason_type, 'Reason')]"
            :loading="formLoading"
            v-model.trim="cancel_reason_type"
            label="Reason"
            solo
            flat
            item-color="cyan"
            item-text="text"
            item-value="value"
            hide-details
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Reason Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <label
            for="cancel-reason"
            class="font-weight-600 font-size-18 ml-1 required"
            >Remark</label
          >
          <v-textarea
            id="cancel-reason"
            auto-grow
            dense
            filled
            color="cyan"
            placeholder="Enter Remark..."
            solo
            flat
            v-model="cancel_reason"
            row-height="20"
            style="margin-bottom: 5px !important"
            :rules="[validateRules.required(cancel_reason, 'Remark')]"
          />
          <label for="cancel-reason" class="font-weight-600 font-size-18 ml-1"
            >Attachments</label
          >
          <table width="100%" style="table-layout: fixed">
            <tr
              v-for="(row, index) in vAttachments"
              :key="`create-visit-attachment-${index}`"
              class="ml-2"
            >
              <td class="px-0 py-0" style="padding-left: 2px !important">
                <v-file-input
                  hide-details
                  class="px-0"
                  dense
                  filled
                  solo
                  flat
                  v-model="row.file"
                  placeholder="Select File"
                  v-on:change="updateFile(index, $event)"
                  prepend-icon=""
                  prepend-inner-icon="$file"
                  color="cyan"
                />
              </td>
              <td class="py-0">
                <v-text-field
                  hide-details
                  class="px-0"
                  v-model="row.name"
                  placeholder="Name"
                  dense
                  filled
                  solo
                  flat
                  color="cyan"
                />
              </td>
              <td class="px-0 py-0" width="10%" valign="top">
                <v-btn
                  v-on:click="removeFile('vAttachments', index)"
                  icon
                  class="w-100"
                  color="red lighten-1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td class="px-0 pb-0" colspan="3">
                <v-btn
                  color="cyan white--text"
                  class="custom-bold-button"
                  depressed
                  v-on:click="addFile('vAttachments')"
                  tile
                  >Add More</v-btn
                >
              </td>
            </tr>
          </table>
        </v-container>
        <!--  -->
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
/*   import { validateEmail } from "@/core/plugins/validation-mixin"; */
import { POST } from "@/core/services/store/request.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    failedDialog: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      formValid: true,

      vAttachments: [
        {
          file: null,
          name: null,
          suffix: null,
        },
      ],
      cancel_reason: null,
      cancel_reason_type: null,
      resonList: [
        {
          value: "technical-issue",
          text: "Technical Issue",
        },
        /* {
                value: 'technical-issue',
                text: "Technical Issue",
                },
                {
                value: 'technical-issue',
                text: "Technical Issue",
                }, */
      ],
    };
  },
  watch: {},
  methods: {
    addFile() {
      this.vAttachments.push({
        file: null,
        name: null,
        suffix: null,
      });
    },
    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.vAttachments[index].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.vAttachments[index].suffix = `.${file.name.split(".").pop()}`;
      } else {
        this.vAttachments[index].name = null;
        this.vAttachments[index].suffix = null;
      }
    },
    close_dialog() {
      this.$emit("close", true);
    },

    submit() {
      const _this = this;
      _this.formLoading = true;
      const params = new FormData();
      for (let i = 0; i < this.vAttachments.length; i++) {
        params.append(`attachments[${i}][file]`, this.vAttachments[i].file);
        params.append(`attachments[${i}][name]`, this.vAttachments[i].name);
      }
      if (this.cancel_reason) {
        params.append(`cancel_reason`, this.cancel_reason);
      }
      if (this.cancel_reason_type) {
        params.append(`cancel_reason_type`, this.cancel_reason_type);
      }
      params.append(`status`, "mark_as_failed");
      _this.$store
        .dispatch(POST, {
          url: "failed-delivery/delivery/" + this.visitId,
          data: params,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    /*   this.getAvailableTeam() */
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 45);
    },
  },
};
</script>
