import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import { toSafeInteger, toNumber } from "lodash";

export default {
  methods: {
    CreateLineItems(param) {
      return new Promise((resolve, reject) => {
        try {
          const _this = this;
          let lineItem = param.formData;
          let lineItemFormData = new Array();
          for (let i = 0; i < lineItem.length; i++) {
            lineItemFormData.push({
              id: null,
              order: toSafeInteger(lineItem[i].order) || 0,
              product: toSafeInteger(lineItem[i].product_id) || null,
              group: lineItem[i].group,
              group_primary: lineItem[i].group_primary,
              product_type: lineItem[i].product_type,
              to_equipment: lineItem[i].to_equipment,
              has_warranty: toSafeInteger(lineItem[i].has_warranty),
              warranty: lineItem[i].warranty,
              description: lineItem[i].description,
              quantity: toSafeInteger(lineItem[i].quantity) || 1,
              rate: toNumber(lineItem[i].rate) || 0,
              project_price: toNumber(lineItem[i].project_price) || 0,
              is_optional: lineItem[i].is_optional,
            });
          }

          let formData = {
            line_items: lineItemFormData,
          };

          formData[param.type] = param.parent;

          if (param.visit) {
            formData["visit"] = param.visit;
          }

          _this.formLoading = true;
          _this.$store.dispatch(CLEAR_ERROR, {});

          _this.$store
            .dispatch(POST, {
              url: "line-item",
              data: formData,
            })
            .then((response) => {
              resolve(response);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
