<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      width="600"
      right
      temporary
      stateless
      style="z-index: 99"
    >
      <div class="drawer-wrapper">
        <v-flex
          class="py-1 px-5 d-flex align-items-center white--text orange darken-2"
        >
          <div class="form-title my-auto">Create Holiday</div>
          <v-spacer></v-spacer>
          <v-btn tile class="custom-bold-button" color="cyan white--text"
            >Save</v-btn
          >
          <v-btn tile color="" class="ml-2" v-on:click="$emit('close')"
            >Cancel</v-btn
          >
        </v-flex>
        <div class="drawer-content pt-5 px-5">
          <div class="d-form-grp mt-4">
            <label class="my-auto fw-500">Holiday Name</label>
            <v-text-field
              hide-details
              dense
              solo
              flat
              placeholder="Holiday Name"
              v-model="reason"
            ></v-text-field>
          </div>
          <div class="d-form-grp mt-4">
            <label class="my-auto fw-500">Start Date</label>
            <v-menu
              v-model="start_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  dense
                  solo
                  flat
                  readonly
                  placeholder="Start Date"
                  v-model="start_date"
                  v-bind="attrs"
                  append-icon="mdi-calendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="start_date"
                no-title
                @input="start_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="d-form-grp mt-4" v-if="half_day == false">
            <label class="my-auto fw-500">End Date</label>
            <v-menu
              v-model="end_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  dense
                  solo
                  flat
                  readonly
                  placeholder="Start Date"
                  v-model="end_date"
                  v-bind="attrs"
                  append-icon="mdi-calendar"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="end_date"
                no-title
                @input="end_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "CreateLeave",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      half_day: false,
      leaveRadioGroup: null,
      start_date_menu: false,
      end_date_menu: false,
    };
  },
};
</script>
