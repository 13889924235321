<template>
  <v-container fluid class="col-lg-10" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Address
        </h3>
        <!--  <p
            v-if="reLoginRequired()"
            class="my-2 text-warning font-weight-600 font-size-18"
          >
            <v-icon class="mr-2" color="warning">mdi-alert-outline</v-icon> Please
            logout and login again to see the effect of updated entities.
          </p> -->
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          color="cyan white--text"
          class="custom-bold-button mr-3"
          v-on:click="createPicupAddress"
        >
          <v-icon small left>mdi-plus</v-icon>
          Create
        </v-btn>
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row style="overflow: auto">
          <v-col md="12">
            <ListingTable
              :columnCount="6"
              :dataLoading="pageLoading"
              :rowData="addressArr"
            >
              <template v-slot:thead>
                <thead>
                  <tr>
                    <th class="simple-table-th">#</th>
                    <th class="simple-table-th">Name</th>
                    <th class="simple-table-th">Address</th>
                    <th class="simple-table-th">Primary</th>
                    <th class="simple-table-th">Created Time</th>
                    <!--   <th class="simple-table-th">Status</th> -->
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="custom-border-bottom">
                  <template v-if="addressArr.length">
                    <tr
                      v-for="(row, index) in addressArr"
                      :key="index"
                      link
                      class="address-listing-row"
                    >
                      <td
                        v-on:click="updatePropertyData(row)"
                        class="simple-table-td"
                      >
                        <Barcode :barcode="row.barcode"></Barcode>
                      </td>
                      <td
                        v-on:click="updatePropertyData(row)"
                        class="simple-table-td"
                      >
                        <template v-if="row.property_name">
                          {{ row.property_name }}</template
                        >
                        <em class="text-muted" v-else> no name</em>
                      </td>
                      <td
                        v-on:click="updatePropertyData(row)"
                        class="simple-table-td"
                        style="width: 500px"
                      >
                        <template v-if="row.street_1">
                          {{ row.street_1 }}</template
                        >
                        <template v-if="row.street_2"
                          >{{ row.street_2 }},</template
                        >
                        <template v-if="row.unit_no"
                          >{{ row.unit_no }},</template
                        >
                        <template v-if="row.country"
                          >{{ row.country }},</template
                        >
                        <template v-if="row.zip_code">{{
                          row.zip_code
                        }}</template>
                      </td>
                      <td
                        v-on:click="updatePropertyData(row)"
                        class="simple-table-td"
                      >
                        <v-switch
                          v-model="row.primary"
                          color="success"
                          hide-details
                          v-on:click.prevent.stop="activeInactive(row)"
                        ></v-switch>
                      </td>
                      <td
                        v-on:click="updatePropertyData(row)"
                        class="simple-table-td"
                      >
                        <TableActivity
                          v-if="!lodash.isEmpty(row.added_by)"
                          :data="row"
                        >
                          <template v-slot:display_name>
                            {{ row.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ row.created_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDate(row.added_at) }}
                            {{ formatDateStartTime(row.added_at) }}
                          </template>
                        </TableActivity>
                      </td>
                      <!--  <td
                          class="cursor-default simple-table-td text-center"
                          align="center"
                        >
                          <v-switch
                            class="my-2 p-0"
                            color="cyan"
                            v-model="row.activated"
                            inset
                            hide-details
                            :disabled="statusLoading"
                            v-on:change="updateStatus(row.id)"
                          ></v-switch>
                        </td> -->
                    </tr>
                  </template>
                  <tr v-else>
                    <td :colspan="6" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no entity at the moment.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </ListingTable>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <Dialog :common-dialog="entity_dialog" :dialogWidth="950">
      <template v-slot:title>{{ title }} Address</template>
      <template v-slot:body>
        <v-form
          ref="createProperty"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createProperty"
        >
          <DialogPropertyAddress
            :isDialog="true"
            :address-id="updated_id"
            v-on:savePropertyAddress="updateProperty"
          >
          </DialogPropertyAddress>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createProperty"
          >Save
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="entity_dialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import {
  GET,
  POST,
  PATCH,
  CLEAR_ERROR,
  PUT,
} from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";

/*   import PhoneTextField from "@/view/pages/partials/PhoneTextField"; */
import Barcode from "@/view/pages/partials/Barcode.vue";

import DialogPropertyAddress from "@/view/pages/profile/Setting/Pickup-Address";
export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "profile-company-setting",
  data() {
    return {
      formValid: true,
      updated_id: 0,
      statusLoading: false,
      title: "Create",
      pageLoading: true,
      propertyAddress: new Object(),
      createPropertyDialog: false,
      formLoading: false,
      entity_dialog: false,
      addressArr: [],
    };
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    createPicupAddress() {
      this.updated_id = 0;
      (this.propertyAddress = {}), (this.propertyAddress.name = null);
      (this.title = "Create"), (this.entity_dialog = true);
    },
    updateProperty(param) {
      this.propertyAddress = param;
      if (this.propertyAddress.id > 0) {
        this.title = "Update";
      }
    },
    updatePropertyData(data) {
      this.updated_id = data.id;
      if (this.updated_id > 0) {
        this.title = "Update";
      }
      this.entity_dialog = true;
    },
    createProperty() {
      const _this = this;
      if (!_this.$refs.createProperty.validate()) {
        return false;
      }
      const formData = _this.propertyAddress;
      if (_this.lodash.isEmpty(formData) === false) {
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});
        let requestType = "";
        let requestUrl = "";
        if (_this.propertyAddress && _this.propertyAddress.id > 0) {
          requestType = PUT;
          requestUrl = `pic-address/${_this.propertyAddress.id}`;
        } else {
          requestType = POST;
          requestUrl = "pic-address";
        }
        _this.$store
          .dispatch(requestType, {
            url: requestUrl,
            data: formData,
          })
          .then(() => {
            this.entity_dialog = false;
            this.getAdress();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    activeInactive(data) {
      const _this = this;
      if (data.primary == false) {
        data.primary = 0;
      } else {
        data.primary = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-address/${data.primary}/${data.id}`,
          data: {
            id: data.id,
            primary: data.primary,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getAdress();
        });
    },
    /* updateProperty(data) {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: `pic-single-address/${data.id}`,
        })
        .then(({data}) => {
            this.propertyAddress =  data
            this.entity_dialog = true;
          })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
        });
    }, */
    getAdress() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "pic-address" })
        .then(({ data }) => {
          _this.addressArr = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    /*    PhoneTextField, */
    Barcode,
    ListingTable,
    TableActivity,
    DialogPropertyAddress,
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.getAdress();
  },
};
</script>
