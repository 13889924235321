import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { DOWNLOAD, DELETE, UPLOAD } from "@/core/services/store/request.module";
import fileDownload from "js-file-download";

const BASE_PATH = process.env.VUE_APP_BASE_URL;

const AllowedExtension = [
  "csv",
  "pdf",
  "xls",
  "ppt",
  "pptx",
  "zip",
  "gif",
  "jpeg",
  "jpg",
  "png",
  "txt",
  "doc",
  "docx",
  "xlsx",
  "svg",
];
const AllowedImageExtension = ["gif", "jpeg", "jpg", "png"];
const FileUploadCountLimit = 50;
const FileUploadSizeLimit = 10 * 1024 * 1024; // 10 MB

export default {
  data() {
    return {
      dataDownloading: {},
      dataDeleting: {},
      primaryLoader: false,
      uploadCountLimit: FileUploadCountLimit,
      uploadSizeLimit: FileUploadSizeLimit,
      documents: [],
      dropzoneOptions: {
        maxFiles: FileUploadCountLimit,
        dropzoneOptions: false,
        url: this.$apiURL + "file-manager/documents",
        parallelUploads: FileUploadCountLimit,
        uploadMultiple: true,
        maxfilesexceeded: function (file) {
          this.removeAllFiles();
          this.addFile(file);
        },
      },
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    localFileExists(path) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let filePath = BASE_PATH + path;
          fetch(filePath, { method: "head" })
            .then((status) => {
              resolve(status.ok);
            })
            .catch((status) => {
              reject(status.ok);
            });
        } catch (error) {
          _this.logError(error);
          reject(false);
        }
      });
    },
    getFileExtension(path) {
      return this.lodash.last(path.split("."));
    },
    getFileName(path) {
      return this.lodash.last(path.split("/"));
    },
    isAllowedExtension(path) {
      let fileExtension = this.lodash.last(path.split("."));
      return this.lodash.includes(AllowedExtension, fileExtension);
    },
    isAllowedImageExtension(path) {
      let fileExtension = this.lodash.last(path.split("."));
      return this.lodash.includes(AllowedImageExtension, fileExtension);
    },
    isDownloading(index) {
      if (this.dataDownloading[index]) {
        return true;
      }
      return false;
    },
    isDeleting(index) {
      if (this.dataDeleting[index]) {
        return true;
      }
      return false;
    },
    deleteAPIFile(id, index, documents) {
      const _this = this;
      try {
        const deletePromise = () => {
          return new Promise((resolve, reject) => {
            try {
              _this.primaryLoader = true;
              _this.dataDeleting[index] = true;
              _this.$store
                .dispatch(DELETE, {
                  url: "file-manager/delete/" + id,
                })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                })
                .finally(() => {
                  _this.primaryLoader = false;
                  _this.dataDeleting = new Object();
                });
            } catch (error) {
              reject(error);
            }
          });
        };
        deletePromise()
          .then(() => {
            documents.splice(index, 1);
            this.$emit("file:updated", documents);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    downloadAPIFile(id, fileName, index) {
      const _this = this;
      try {
        const downloadPromise = () => {
          return new Promise((resolve, reject) => {
            try {
              _this.primaryLoader = true;
              _this.dataDownloading[index] = true;
              _this.$store
                .dispatch(DOWNLOAD, {
                  url: "file-manager/download/" + id,
                })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                })
                .finally(() => {
                  _this.primaryLoader = false;
                  _this.dataDownloading = new Object();
                });
            } catch (error) {
              reject(error);
            }
          });
        };
        downloadPromise()
          .then((response) => {
            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    uploadAttachment(file) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.primaryLoader = true;
          let formData = new FormData();
          formData.append("files[0]", file, file.name);
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.primaryLoader = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    uploadAttachmentCloser(file) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.primaryLoader = true;
          let formData = new FormData();
          formData.append("files[0]", file, file.name);
          _this.$store
            .dispatch(UPLOAD, {
              url: "file-manager/uploadcloser",
              data: formData,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.primaryLoader = false;
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
