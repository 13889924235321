<template>
  <Dialog :common-dialog="mailDialog" :dialog-width="dialogWidth">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-layout class="mb-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16 required"
                >Recipients</label
              >
            </v-flex>
            <v-flex md9>
              <v-combobox
                chips
                :items="autocompleteEmails()"
                v-model="email.recipients"
                placeholder="Recipients"
                label="Recipients"
                multiple
                flat
                color="cyan"
                item-color="cyan"
                :disabled="formLoading"
                v-on:change="validateTagEmail(email.recipients)"
                hide-details
                solo
                :rules="[
                  validateRules.required(email.recipients, 'Recipients'),
                ]"
              >
                <template v-slot:append-outer>
                  <v-chip
                    v-on:click="emailCCShow = !emailCCShow"
                    class="append-outer-chip"
                    label
                    :disabled="formLoading"
                    :color="emailCCShow ? 'green' : ''"
                    :text-color="emailCCShow ? 'white' : ''"
                  >
                    <span class="font-weight-500 font-size-16">CC</span>
                  </v-chip>
                </template>
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeEmail(item, 'recipients')"
                  >
                    <span class="font-weight-500 font-size-16">{{ item }}</span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout v-if="emailCCShow" class="my-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16">CC</label>
            </v-flex>
            <v-flex md9>
              <v-combobox
                v-model="email.cc"
                chips
                :disabled="formLoading"
                :items="autocompleteEmails()"
                placeholder="CC"
                label="CC"
                multiple
                flat
                color="cyan"
                item-color="cyan"
                v-on:change="validateTagEmail(email.cc)"
                hide-details
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeEmail(item, 'cc')"
                  >
                    <span class="font-weight-500 font-size-16">{{ item }}</span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16 required"
                >Subject</label
              >
            </v-flex>
            <v-flex md9>
              <v-text-field
                v-model="email.subject"
                dense
                filled
                label="Subject"
                placeholder="Subject"
                solo
                flat
                :disabled="formLoading"
                hide-details
                color="cyan"
                :rules="[validateRules.required(email.subject, 'Subject')]"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex md12>
              <TinyMCE
                :disabled="formLoading"
                v-model="email.message"
              ></TinyMCE>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex md12>
              <label class="font-weight-500 font-size-16"
                >Select Service Report</label
              >
              <div
                v-for="(row, index) in service_reports"
                :key="index"
                class="d-flex align-center"
              >
                <v-checkbox
                  color="cyan"
                  v-model="selected_reports"
                  :label="`${row.barcode} - ${row.title}`"
                  :value="row.id"
                  hide-details
                  class="py-0 my-0"
                ></v-checkbox>
                <v-icon
                  class="ml-3 my-0 py-0"
                  v-on:click="previewPDF(row)"
                  color="red"
                  >mdi-file-pdf</v-icon
                >
              </div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
      <PDFViewer
        v-on:close="closePDF()"
        :pdf-title="`${preview_pdf.barcode} - ${preview_pdf.title}`"
        :pdf-dialog="pdfDialog"
        :pdf-url="preview_pdf.pdf_url"
      />
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
      >
        <v-icon left>mdi-send mdi-rotate-315</v-icon>Send
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import PDFViewer from "@/view/pages/partials/PDFViewer.vue";
import { validateEmail } from "@/core/plugins/validation-mixin";
import { GET, POST } from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    mailDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    visitId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      emailCCShow: false,
      pdfDialog: false,
      service_reports: [],
      selected_reports: [],
      email: {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      },
      preview_pdf: {},
    };
  },
  watch: {
    mailDialog(param) {
      if (param) {
        this.init();
      }
    },
  },
  methods: {
    closePDF() {
      this.$emit("open", true);
      this.pdfDialog = false;
    },
    close_dialog() {
      this.$emit("close", true);

      this.email = {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      };
    },
    init() {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(GET, {
          url: `visit/${_this.visitId}/share-service-report-mail`,
        })
        .then(({ data }) => {
          _this.service_reports = data.reports;
          _this.email = new Object({
            recipients: data.template.person_emails,
            cc: [],
            subject: data.template.email_subject,
            message: data.template.email_body,
            action: "send_as_email",
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    submit() {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(POST, {
          url: `visit/${_this.visitId}/share-service-report-mail`,
          data: { email: _this.email, reports: _this.service_reports },
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    validateTagEmail(emails) {
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) {
          emails.splice(i, 1);
        }
      }
    },
    removeEmail(email, type) {
      const emails = this.email[type];
      const index = this.lodash.findIndex(emails, (row) => email == row);
      if (index >= 0) {
        this.email[type].splice(index, 1);
      }
    },
    autocompleteEmails() {
      return this.lodash.map(this.recipients, (row) => row.email);
    },
    previewPDF(row) {
      this.preview_pdf = row;
      this.$emit("close", true);
      this.$nextTick(() => {
        this.pdfDialog = true;
      });
    },
  },
  components: {
    Dialog,
    TinyMCE,
    PDFViewer,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
