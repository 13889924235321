<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Branding
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-menu offset-y left>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="pageLoading"
                  class="mr-4 custom-bold-button"
                  color="cyan white--text"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  {{ entity.name }}
                </v-btn>
              </template>
              <span>Entity</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in entityList"
              :key="index"
              link
              v-on:click="updateEntity(item)"
            >
              <v-list-item-title class="font-weight-600">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <h3 class="font-weight-700 custom-headline color-custom-blue">
            Company Logo <span class="changestyle">(dimensions:450×450)</span>
          </h3>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row class="text-overflow">
            <v-hover v-slot="{ hover }">
              <v-col md="12">
                <v-img
                  v-if="detail.company_logo"
                  contain
                  width="450"
                  height="450"
                  lazy-src="https://fakeimg.pl/450x450"
                  :src="detail.company_logo"
                ></v-img>
                <v-img
                  v-else
                  contain
                  width="450"
                  height="450"
                  lazy-src="https://fakeimg.pl/450x450"
                  src="https://fakeimg.pl/450x450"
                ></v-img>
                <template v-if="getPermission('setting:update')">
                  <span v-if="hover" class="custom-change-company-logo-button">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on:click="changeImage"
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="cyan"
                          class="text-white"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                        >
                          <span class="font-size-14">Change</span>
                        </v-btn>
                      </template>
                      <span>Click here to change logo</span>
                    </v-tooltip>
                  </span>
                  <div class="d-none">
                    <v-file-input
                      ref="uploadImage"
                      v-on:change="uploadCompanyLogo"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/jpg"
                    ></v-file-input>
                  </div>
                </template>
              </v-col>
            </v-hover>
            <!--  <v-col md="6">
              <v-img
                contain
                width="450"
                height="364"
                lazy-src="https://fakeimg.pl/450x450"
                src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/04oTO4w2gBNdv1fG33pmz9CAIrhuKULDdzdIzbmO.png"
              ></v-img>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <h3 class="font-weight-700 custom-headline color-custom-blue">
            PDF Header &amp; Footer
            <span class="changestyle">(dimensions:1644×287)</span>
          </h3>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row class="text-overflow">
            <v-col md="12" class="py-0">
              <v-list>
                <v-list-item v-for="item in items" :key="item.title">
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-600 font-size-16"
                      v-text="item.title"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-hover v-slot="{ hover }">
                      <div>
                        <v-img
                          v-if="headerFooter[item.key]"
                          contain
                          width="745"
                          height="135"
                          lazy-src="https://fakeimg.pl/745x135"
                          :src="headerFooter[item.key]"
                        ></v-img>
                        <v-img
                          v-else
                          contain
                          width="745"
                          height="135"
                          lazy-src="https://fakeimg.pl/745x135"
                          src="https://fakeimg.pl/745x135"
                        ></v-img>
                        <template v-if="getPermission('setting:update')">
                          <span
                            v-if="hover"
                            class="custom-change-company-header-button"
                          >
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-on:click="changeHeaderFooter(item.key)"
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  color="cyan"
                                  class="text-white"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                >
                                  <span class="font-size-14">Change</span>
                                </v-btn>
                              </template>
                              <span>Click here to change {{ item.title }}</span>
                            </v-tooltip>
                          </span>
                          <div class="d-none">
                            <v-file-input
                              :ref="item.key"
                              v-on:change="uploadHeader($event, item.key)"
                              :rules="imageRules"
                              accept="image/png, image/jpeg, image/jpg"
                            ></v-file-input>
                          </div>
                        </template>
                      </div>
                    </v-hover>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Social Networks
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="updateBrandingSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6">
              <v-text-field
                v-model.trim="detail.facebook_url"
                dense
                filled
                label="Facebook page URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              >
                <template slot="prepend">
                  <v-icon large class="facebook-prepend">mdi-facebook</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model.trim="detail.twitter_url"
                dense
                filled
                label="Twitter account URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              >
                <template slot="prepend">
                  <v-icon large class="twitter-prepend">mdi-twitter</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model.trim="detail.instagram_url"
                dense
                filled
                label="Instagram account URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              >
                <template slot="prepend">
                  <v-icon large class="instagram-prepend">mdi-instagram</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model.trim="detail.google_plus_url"
                dense
                filled
                label="Google business profile URL"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              >
                <template slot="prepend">
                  <v-icon large class="google-prepend">mdi-google-plus</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { UPLOAD, PATCH, QUERY } from "@/core/services/store/request.module";
import { getConfig } from "@/core/services/local.service";
export default {
  name: "profile-branding-setting",
  data() {
    return {
      pageLoading: true,
      items: [
        /*{
          title: "Quotation Header",
          key: "quotation_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },*/
        /*{
          title: "Quotation Footer",
          key: "quotation_footer",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg"
        },*/
        /*   {
          title: "Invoice Header",
          key: "invoice_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        }, */
        /*{
          title: "Invoice Footer",
          key: "invoice_footer",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg"
        },*/
        /*   {
          title: "Payment Header",
          key: "payment_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        }, */
        /*{
          title: "Payment Footer",
          key: "payment_footer",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg"
        },*/
        {
          title: "Visit Header",
          key: "visit_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },
        {
          title: "Delivery Header",
          key: "delivery_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },
        /*{
          title: "Project Costing Header",
          key: "project_costing_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },*/
        /*{
          title: "Project Costing Footer",
          key: "project_costing_footer",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg"
        },*/
        /*{
          title: "Purchase Order Header",
          key: "purchase_header",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },*/
        /*{
          title: "Purchase Order Footer",
          key: "purchase_footer",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg"
        }*/
      ],
      headerFooter: {},
      detail: {
        company_logo: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        google_plus_url: null,
      },
      entityList: [],
      entity: {},
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Logo size should be less than 2 MB!",
      ],
    };
  },
  methods: {
    getBrandingSetting() {
      const _this = this;
      _this.detail = new Object({
        company_logo: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        google_plus_url: null,
      });
      _this.$store
        .dispatch(QUERY, {
          url: "setting/branding",
          data: { entity: this.entity.id },
        })
        .then(({ data }) => {
          _this.detail = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getHeaderFooterSetting() {
      const _this = this;
      _this.headerFooter = new Object();
      _this.$store
        .dispatch(QUERY, {
          url: "setting/header-footer",
          data: { entity: this.entity.id },
        })
        .then(({ data }) => {
          _this.headerFooter = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    uploadCompanyLogo(param) {
      const _this = this;
      let formData = new FormData();
      _this.pageLoading = true;
      formData.append("files[]", param, param.name);
      _this.$store
        .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
        .then(async ({ data }) => {
          for (let i = data.length - 1; i >= 0; i--) {
            _this.detail.company_logo = data[i].file.url;
            break;
          }
          await _this.updateCompanyLogo();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    uploadHeader(param, key) {
      const _this = this;
      let formData = new FormData();
      _this.pageLoading = true;
      formData.append("files[]", param, param.name);
      _this.$store
        .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
        .then(async ({ data }) => {
          for (let i = data.length - 1; i >= 0; i--) {
            _this.detail[key] = data[i].file.url;
            break;
          }
          await _this.updateCompanyHeader(key);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateCompanyLogo() {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PATCH, {
            url: "setting/branding",
            data: {
              company_logo: _this.detail.company_logo,
              entity: _this.entity.id,
            },
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            _this.getBrandingSetting();
          });
      });
    },
    updateCompanyHeader(key) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(PATCH, {
            url: "setting/header-footer",
            data: { image: _this.detail[key], key, entity: _this.entity.id },
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            _this.getHeaderFooterSetting();
          });
      });
    },
    updateBrandingSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/branding",
          data: { ..._this.detail, entity: _this.entity.id },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    changeImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    changeHeaderFooter(key) {
      if (
        this.lodash.isEmpty(this.$refs[key]) === false &&
        this.lodash.isEmpty(this.$refs[key][0]) === false
      ) {
        this.$refs[key][0].$refs["input"].click();
      }
    },
    updateEntity(row) {
      this.entity = row;
      this.pageLoading = true;
      this.getBrandingSetting();
      this.getHeaderFooterSetting();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.entity) {
        this.updateEntity(this.entity);
      }
    });
  },
  beforeMount() {
    this.entityList = getConfig("entity");
    this.$nextTick(() => {
      this.entity = this.lodash.find(this.entityList, { id: 1 });
    });
  },
};
</script>
<style scoped>
span.changestyle {
  font-size: 12px;
  text-transform: capitalize;
  color: black;
}
</style>
