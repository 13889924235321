<template>
  <div class="position-relative">
    <v-text-field
      :id="id"
      :ref="inputRef"
      :label="label"
      :loading="loading || pageLoading"
      :type="type"
      :disabled="disabled || pageLoading"
      :clearable="clearable"
      :readonly="readonly"
      :prefix="prefix"
      :suffix="suffix"
      :hide-details="hideDetails"
      :hide-spin-buttons="hideSpinButtons"
      dense
      filled
      solo
      flat
      :rules="[...rules, fieldNameError ? false : true]"
      :autofocus="autofocus"
      v-mask="vMask"
      class="pt-0"
      :class="{ ...customClass, 'mt-3': !hideTopMargin }"
      v-model.trim="textinput"
      :append-outer-icon="appendOuterIcon"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependInnerIcon"
      :placeholder="placeholder"
      v-on:click:clear="$emit('click:clear', true)"
      v-on:click:append-outer="$emit('click:append-outer', true)"
      v-on:change="validateFieldName(textinput), $emit('change', true)"
      v-on:keyup="getExistingData(textinput)"
      v-on:click="$emit('click', true)"
      v-on:blur="
        isFocused = false;
        $emit('blur', textinput);
      "
      v-on:focus="isFocused = true"
      v-bind="vBindAttrs"
      :reverse="reverse"
      v-on="vOn"
    ></v-text-field>
    <template v-if="isFocused">
      <div
        class="suggested-content elevation-2"
        v-if="suggestedItems && suggestedItems.length"
      >
        <template v-for="(item, index) in suggestedItems">
          <v-list-item :key="index" class="py-2">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
            <span class="red--text px-3 pt-1">Already exists </span>
          </v-list-item>
          <v-divider class="my-0" :key="`index--${index}`"></v-divider>
        </template>
      </div>
    </template>
    <span
      v-if="fieldNameError"
      v-html="fieldNameError"
      class="red--text text--darken-1 font-small"
    ></span>
  </div>
</template>
<script>
import { GetExistingData, ValidateInputField } from "@/core/lib/common.lib";
// import { isEmpty } from "lodash";
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    fieldName: {
      type: String,
      default: null,
    },
    urlType: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    inputRef: {
      type: String,
      default: "textInput",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    vBindAttrs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vOn: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    hideSpinButtons: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    customClass: {
      type: String,
      default: "",
    },
    vMask: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
    validationField: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    currentId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      textinput: null,
      fieldNameError: null,
      previousText: null,
      pageLoading: false,
      isFocused: false,
      timeout: null,
      suggestedItems: [],
    };
  },
  watch: {
    value() {
      this.textinput = this.value;
    },
    textinput() {
      this.$emit("input", this.textinput);
    },
  },
  methods: {
    validateFieldName() {
      const _this = this;
      /* if (!this.validationField && isEmpty(this.validationField) && !this.validationField.url_type) {
				return false;
			} */
      _this.fieldNameError = null;
      if (!_this.textinput) {
        return false;
      }
      return new Promise((resolve, reject) => {
        _this.pageLoading = true;
        ValidateInputField(
          `validate/${this.validationField.url_type}/any-field`,
          {
            filter_type:
              _this.validationField && _this.validationField.filter_type
                ? _this.validationField.filter_type
                : null,
            field:
              _this.validationField && _this.validationField.field
                ? _this.validationField.field
                : null,
            value: _this.textinput,
            parentId: this.parentId,
            id: this.currentId,
          }
        )
          .then((output) => {
            if (output.exists) {
              _this.fieldNameError = `<b class='fw-600'>${_this.textinput}</b> already exists`;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      });
    },

    getExistingData(search_key) {
      const _this = this;
      if (search_key && this.showDropdown) {
        const params = {
          filter_type:
            _this.validationField && _this.validationField.filter_type
              ? _this.validationField.filter_type
              : null,
          field:
            _this.validationField && _this.validationField.field
              ? _this.validationField.field
              : null,
          filter:
            _this.validationField && _this.validationField.field
              ? _this.validationField.field
              : null,
          value: search_key,
          parentId: this.parentId,
          id: this.currentId,
        };
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          const data = await GetExistingData(
            this.validationField.url_type,
            params
          );
          this.suggestedItems = data.data;
        }, 300);
      }
      this.$emit("keyup", true);
    },
  },
  mounted() {
    this.previousText = this.value;
    this.textinput = this.value;
  },
};
</script>
<style scoped>
.suggested-content {
  position: absolute;
  top: 30px;
  width: 100%;
  /* background: #fbf8f8; */
  background: #fff;
  z-index: 99;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
</style>
