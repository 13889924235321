<template>
  <Dialog :commonDialog="visitScheduleDialog" :dialogWidth="dialogWidth">
    <template v-slot:title
      >Schedule a visit
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      Job# {{ detail.barcode }}</template
    >
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-form
          ref="visitForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <v-container fluid>
            <v-row>
              <v-col md="8">
                <v-text-field
                  v-model.trim="visitData.title"
                  dense
                  filled
                  flat
                  :rules="[
                    () => !!visitData.title || 'Title field is required',
                  ]"
                  :disabled="pageLoading"
                  label="Title"
                  solo
                  color="cyan"
                  class="remove-border-radius custom-grey-border"
                  hide-details
                ></v-text-field>
                <v-textarea
                  v-model.trim="visitData.instruction"
                  auto-grow
                  dense
                  filled
                  flat
                  :disabled="pageLoading"
                  label="Instructions"
                  solo
                  color="cyan"
                  class="remove-border-radius custom-grey-border"
                  hide-details
                  row-height="30"
                ></v-textarea>
              </v-col>
              <v-col md="4">
                <table class="width-100 font-weight-500">
                  <tr>
                    <td
                      class="color-custom-blue text-uppercase font-size-16 ml-4 py-2 font-weight-600"
                    >
                      Job details
                    </td>
                  </tr>
                  <tr class="custom-border-bottom">
                    <td class="py-2">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-if="lodash.isEmpty(customer) === false"
                            class="py-1 max-content-width"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-icon class="margin-auto-zero mr-4">
                              <v-icon class="font-size-28">mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="p-0">
                              <v-skeleton-loader
                                v-if="pageLoading"
                                class="custom-skeleton"
                                type="text"
                              ></v-skeleton-loader>
                              <v-list-item-title
                                v-else
                                class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                              >
                                <router-link
                                  :to="
                                    getDefaultRoute('customer.detail', {
                                      params: {
                                        id: customer.customer,
                                      },
                                    })
                                  "
                                  >{{ detail.attention }}</router-link
                                >
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action
                              link
                              class="m-0 ml-4"
                              v-on:click="
                                $router.push(
                                  getDefaultRoute('customer.detail', {
                                    params: {
                                      id: customer.customer,
                                    },
                                  })
                                )
                              "
                            >
                              <v-icon class="color-custom-blue"
                                >mdi-link mdi-rotate-135</v-icon
                              >
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                        <span>Attention</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr class="custom-border-bottom">
                    <td class="py-2">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            v-if="contactPerson"
                            class="py-1 max-content-width"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-icon class="margin-auto-zero mr-4">
                              <v-icon class="font-size-28">mdi-phone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="p-0">
                              <v-list-item-title
                                class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                              >
                                <a
                                  :href="'tel:' + contactPerson.primary_phone"
                                  >{{ contactPerson.primary_phone }}</a
                                >
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <span>Contact Phone</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-2">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item class="py-1" v-bind="attrs" v-on="on">
                            <v-list-item-icon class="margin-auto-zero mr-4">
                              <v-icon class="font-size-28"
                                >mdi-home-map-marker</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content class="p-0">
                              <v-list-item-title
                                class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                              >
                                <a
                                  target="_blank"
                                  :href="
                                    'https://maps.google.com/?q=' +
                                    PropertyDetail
                                  "
                                  >{{ PropertyDetail }}</a
                                >
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <span>Property Address</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <OneOffScheduleDetail
              :pageLoading="pageLoading"
              :internal="isInternal"
              :dailyRecurrence="dailyRecurrence"
              :monthlyRecurrence="monthlyRecurrence"
              :weeklyRecurrence="weeklyRecurrence"
            ></OneOffScheduleDetail>
          </v-container>
        </v-form>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeDialog"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/pages/partials/Dialog.vue";
import OneOffScheduleDetail from "@/view/pages/job/partials/One-Off-Schedule.vue";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PUT } from "@/core/services/store/request.module";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  props: {
    detail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    visit: {
      type: Number,
      default: 0,
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    dailyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    monthlyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    weeklyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler() {
        if (this.detail.title && !this.visitData.title) {
          this.visitData.title = this.detail.title;
        }
      },
    },
  },
  data() {
    return {
      isInternal: true,
      visitCreated: new Object(),
      assignedEngineers: new Array(),
      OneOffSchedule: new Object(),
      visitScheduleDialog: true,
      ScheduleCreated: false,
      ScheduleEngineerCreated: false,
      formValid: true,
      formLoading: false,
      visitData: {
        title: null,
        instruction: null,
        anytime: 0,
      },
    };
  },
  components: {
    Dialog,
    OneOffScheduleDetail,
  },
  methods: {
    closeDialog() {
      DialogJobEventBus.$emit("close:add-visit-dialog", true);
    },
    CreateJobSchedule({ job, schedule, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(schedule) === false &&
            moment(schedule.start_date).isValid()
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit",
                data: {
                  title: _this.visitData.title,
                  instruction: _this.visitData.instruction || null,
                  anytime:
                    _this.lodash.toSafeInteger(_this.visitData.anytime) || 0,
                  start_date: schedule.start_date
                    ? moment(schedule.start_date).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                  start_time: schedule.start_time
                    ? moment(schedule.start_time, ["h:mm A"]).format("HH:mm")
                    : moment().startOf("day").format("HH:mm"),
                  end_date: schedule.end_date
                    ? moment(schedule.end_date).format("YYYY-MM-DD")
                    : moment(schedule.start_date).format("YYYY-MM-DD"),
                  end_time: schedule.end_time
                    ? moment(schedule.end_time, ["h:mm A"]).format("HH:mm")
                    : moment().endOf("day").format("HH:mm"),
                  email: team.email_team,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Invalid Start Date in Schedule.");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    AssignTeam({ job, visit, team }) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (
            _this.lodash.isEmpty(team.assigned_team) === false &&
            _this.lodash.isArray(team.assigned_team)
          ) {
            _this.$store
              .dispatch(PUT, {
                url: "job/" + job + "/visit/" + visit + "/engineer",
                data: {
                  engineer: team.assigned_team,
                },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.visitForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      if (
        !_this.ScheduleCreated &&
        _this.lodash.isEmpty(_this.detail) === false
      ) {
        try {
          _this.visitCreated = await _this.CreateJobSchedule({
            job: _this.detail.id,
            schedule: _this.OneOffSchedule,
            team: _this.assignedEngineers,
          });
          _this.ScheduleCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.ScheduleCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Schedule is not created. Please try again.")
          );
        }
      }

      if (
        _this.lodash.isEmpty(_this.assignedEngineers) === false &&
        !_this.ScheduleEngineerCreated &&
        _this.lodash.isEmpty(_this.detail) === false
      ) {
        try {
          await _this.AssignTeam({
            job: _this.detail.id,
            visit: _this.visitCreated.id,
            team: _this.assignedEngineers,
          });
          _this.ScheduleEngineerCreated = true;
        } catch (error) {
          _this.ScheduleEngineerCreated = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError(
              "Engineer are not allocated to Schedule. Please try again."
            )
          );
        }
      }

      _this.formLoading = false;

      if (_this.lodash.isEmpty(_this.visitCreated) === false) {
        DialogJobEventBus.$emit("load:job", true);
        DialogJobEventBus.$emit("load:visit", true);
        _this.closeDialog();
      }
    },
  },
  beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("get:skills");
    JobScheduleTeamEventBus.$off("update:assigned-team");
    JobEventBus.$off("update:one-off-schedule");*/
  },
  beforeCreate() {
    const _this = this;
    JobScheduleTeamEventBus.$on("get:skills", () => {
      JobScheduleTeamEventBus.$emit("update:skills", _this.detail.ticket_type);
    });
  },
  created() {
    const _this = this;

    JobScheduleTeamEventBus.$on("update:assigned-team", (argument) => {
      _this.assignedEngineers = argument;
    });

    JobEventBus.$on("update:one-off-schedule", (argument) => {
      _this.OneOffSchedule = argument;
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 75);
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
</script>
