<template>
  <v-container
    fluid
    class="visit-listing white-background main-listing-page pb-0"
  >
    <div>
      <v-tabs
        active-class="custom-tab-active"
        v-model="visitTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent tab-sticky"
      >
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#all"
        >
          <template v-if="counts.all > 0">
            <v-badge bordered color="orange darken-4" :content="counts.all">
              All
            </v-badge>
          </template>
          <template v-else>All</template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#open"
        >
          <template v-if="counts.overdue > 0">
            <v-badge bordered color="orange darken-4" :content="counts.overdue">
              New
            </v-badge>
          </template>
          <template v-else>New</template>
        </v-tab>
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#in-Progress"
        >
          <template v-if="counts.today > 0">
            <v-badge bordered color="orange darken-4" :content="counts.today">
              In-Progress
            </v-badge>
          </template>
          <template v-else>In-Progress</template>
        </v-tab>
        <!--   <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#new"
        >
          <template v-if="counts.tomorrow > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.tomorrow"
            >
              New
            </v-badge>
          </template>
          <template v-else>New</template>
        </v-tab> -->
        <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#closed"
        >
          <template v-if="counts.this_week > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.this_week"
            >
              closed
            </v-badge>
          </template>
          <template v-else>closed</template>
        </v-tab>
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#completed"
        >
          <template v-if="counts.upcomming > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.upcomming"
            >
            Completed
            </v-badge>
          </template>
          <template v-else>Completed</template>
        </v-tab> -->
        <!--  <v-tab
          v-if="follow_up_route != 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#completed"
        >
          <template v-if="counts.completed > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.completed"
            >
              Completed
            </v-badge>
          </template>
          <template v-else>Completed</template>
        </v-tab> -->
        <v-tab
          v-if="follow_up_route == 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#follow_up_open"
        >
          <template v-if="counts.follow_up > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.follow_up"
            >
              Open
            </v-badge>
          </template>
          <template v-else>Open</template>
        </v-tab>
        <v-tab
          v-if="follow_up_route == 1"
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#follow_up_close"
        >
          <template v-if="counts.follow_up > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.follow_up"
            >
              Close
            </v-badge>
          </template>
          <template v-else>Close</template>
        </v-tab>

        <v-tabs-items v-model="visitTab">
          <v-tab-item value="all">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="all"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="open">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="new"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="in-Progress">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="in-Progress"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="new">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="new"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="closed">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="closed"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="completed">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="completed"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="completed">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="completed"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="follow_up_open">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="follow_up_open"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
          <v-tab-item value="follow_up_close">
            <ReportListingTemplate
              :is-admin="isAdmin"
              visitType="follow_up_close"
              detailRoute="incident.detail"
            ></ReportListingTemplate>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ReportListingTemplate from "@/view/pages/incident/incident-listing";

export default {
  name: "Visit",
  mounted() {
    const route_name = this.$route.name;
    this.follow_up_route = this.$route.query.follow_up;
    if (this.follow_up_route && this.follow_up_route == 1) {
      this.title = "Follow Up";
    } else {
      this.title = "Incident Report";
    }
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  data() {
    return {
      title: null,
      isAdmin: false,
      pageLoading: false,
      follow_up_route: null,
      // visitTab: null,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
        follow_up: 0,
      },
    };
  },
  methods: {
    getVisitCounts(countObject) {
      const _this = this;
      if (_this.lodash.isEmpty(countObject) === false) {
        _this.counts = {
          all: _this.lodash.toSafeInteger(countObject.all),
          overdue: _this.lodash.toSafeInteger(countObject.overdue),
          today: _this.lodash.toSafeInteger(countObject.today),
          tomorrow: _this.lodash.toSafeInteger(countObject.tomorrow),
          this_week: _this.lodash.toSafeInteger(countObject.thisWeek),
          upcomming: _this.lodash.toSafeInteger(countObject.upcomming),
          completed: _this.lodash.toSafeInteger(countObject.completed),
          follow_up: _this.lodash.toSafeInteger(countObject.follow_up),
        };
      }
    },
  },
  components: {
    ReportListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },
  computed: {
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "all";
      },
    },
  },
};
</script>
