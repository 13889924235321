<template>
  <v-container fluid class="custom-bthrust-dashboard pt-0">
    <v-row>
      <v-col md="12">
        <v-layout class="dashboard-top-button">
          <v-flex>
            <router-link
              :to="getDefaultRoute('customer.create')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Customer</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('contract')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Contract</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('project')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Project</router-link
            >
          </v-flex>
          <v-flex>
            <router-link
              :to="getDefaultRoute('engineer.create')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Technicians
            </router-link>
          </v-flex>
          <v-flex>
            <router-link
              to=""
              @click.native="createTransaction('job')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Visit</router-link
            >
          </v-flex>
          <v-flex>
            <router-link
              to=""
              @click.native="createTransaction('delivery')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Delivery</router-link
            >
          </v-flex>
          <!-- <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('invoice')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Invoice</router-link
            >
          </v-flex> -->

          <!--   <v-flex class="ml-4">
            <router-link
              :to="getDefaultRoute('profile.setting')"
              class="btn btn-blue btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Settings</router-link
            >
          </v-flex> -->
        </v-layout>
      </v-col>
      <v-col md="6">
        <!--begin::Mixed Widget 1-->
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-cyan py-5 pb-2">
            <h3 class="card-title font-weight-bolder text-white">
              Visit Status
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Chart-->
            <div
              id="kt_mixed_widget_1_chart"
              class="card-rounded-bottom bg-cyan dashboard-total-jobs"
              style="min-height: 100px"
            ></div>
            <div class="card-spacer smallbxc_row">
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-warning ttbox rounded-xl mr-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/ticket.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-warning font-weight-bold font-size-h6"
                    >Total Visits</router-link
                  >
                </div>
                <div class="col bg-light-primary ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/tickets.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_open_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'open',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-primary font-weight-bold font-size-h6"
                    >Total Open Visits</router-link
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row">
                <div class="col bg-light-info ttbox rounded-xl mr-7">
                  <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/close-t.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_completed_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'completed',
                        },
                      })
                    "
                    class="text-info font-weight-bold font-size-h6"
                    >Total Completed Visits</router-link
                  >
                </div>
                <div class="col bg-light-success ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/delete.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_cancelled_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'cancel',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-success font-weight-bold font-size-h6"
                    >Total Cancel Visits</router-link
                  >
                </div>
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/tt1.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_overdue_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'overdue',
                        },
                      })
                    "
                    class="text-danger font-weight-bold font-size-h6"
                    >Total Overdue Visits</router-link
                  >
                </div>
                <div v-if="false" class="col bg-light-warning ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/bug.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ bugCount }}
                    </template>
                  </div>
                  <router-link
                    :to="getDefaultRoute('bug.report')"
                    class="text-success font-weight-bold font-size-h6"
                    >Bug Reports</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Mixed Widget 1-->
      </v-col>
      <v-col md="6">
        <!--begin::Mixed Widget 1-->
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-purple py-5 pb-2">
            <h3 class="card-title font-weight-bolder text-white">
              Delivery Status
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Chart-->
            <div
              id="kt_mixed_widget_1_chart"
              class="card-rounded-bottom bg-purple dashboard-total-jobs"
              style="min-height: 100px"
            ></div>
            <div class="card-spacer smallbxc_row">
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-warning ttbox rounded-xl mr-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/ticket.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ deliveryCount.total_delivery }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('delivery', {
                        query: {
                          status: 'all',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-warning font-weight-bold font-size-h6"
                    >Total Deliveries</router-link
                  >
                </div>
                <div class="col bg-light-primary ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/tickets.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ deliveryCount.total_new_delivery }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('delivery', {
                        query: {
                          status: 'new',
                          tab: 'new',
                        },
                      })
                    "
                    class="text-primary font-weight-bold font-size-h6"
                    >Total New Deliveries</router-link
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row">
                <div class="col bg-light-info ttbox rounded-xl mr-7">
                  <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/close-t.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ deliveryCount.total_delivered }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('delivery', {
                        query: {
                          status: 'all',
                          tab: 'delivered',
                        },
                      })
                    "
                    class="text-info font-weight-bold font-size-h6"
                    >Total Delivered Deliveries</router-link
                  >
                </div>
                <div class="col bg-light-success ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/delete.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ deliveryCount.total_failed_delivey }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('delivery', {
                        query: {
                          status: 'failed',
                          tab: 'rejected',
                        },
                      })
                    "
                    class="text-success font-weight-bold font-size-h6"
                    >Total Failed Deliveries</router-link
                  >
                </div>
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/tt1.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ deliveryCount.total_partial_delivery }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('delivery', {
                        query: {
                          status: 'partial-delivered',
                          tab: 'partial-delivered',
                        },
                      })
                    "
                    class="text-danger font-weight-bold font-size-h6"
                    >Total Partial Delivered</router-link
                  >
                </div>
                <div v-if="false" class="col bg-light-warning ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/bug.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ bugCount }}
                    </template>
                  </div>
                  <router-link
                    :to="getDefaultRoute('bug.report')"
                    class="text-success font-weight-bold font-size-h6"
                    >Bug Reports</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Mixed Widget 1-->
      </v-col>
      <v-col md="6">
        <div class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar justify-content-between d-flex">
              <h4>VISITS DUE TIMES</h4>
              <h4>PRIORITY VISITS COUNT</h4>
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="dash_bottom d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <div class="d-flex flex-column text-left v_list">
                  <ul>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>In-Progress</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_inprogress_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Today</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_today_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Tomorrow</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_tomorrow_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>This week</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_this_week_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Upcoming</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_upcomming_visits }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  class="d-flex flex-column text-left v_list"
                  style="margin-top: -70px !important"
                >
                  <ul>
                    <template v-for="(row, index) in visitPriorityArr">
                      <li :key="index">
                        <span
                          :class="`svg-icon svg-icon-xl ${priorityColor(row)}`"
                        >
                          <inline-svg
                            :src="$assetURL('media/custom-svg/right.svg')"
                          />
                        </span>
                        <strong>{{ priorityText(row) }}</strong>
                        <div class="vdnum" v-if="countLoading">
                          <v-progress-circular
                            indeterminate
                            size="24"
                            color="#3a6afd"
                          ></v-progress-circular>
                        </div>

                        <span
                          v-else
                          :style="{ 'background-color': priorityBgColor(row) }"
                        >
                          {{ row.total_visit }}
                        </span>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="6">
        <div class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar justify-content-between d-flex">
              <h4>DELIVERIES DUE TIMES</h4>
              <h4>PRIORITY DELIVERIES COUNT</h4>
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="dash_bottom d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <div class="d-flex flex-column text-left v_list">
                  <ul>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>In-Progress</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ deliveryCount.total_inprogress_delivery }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Today</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ deliveryCount.total_today_delivery }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Tomorrow</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ deliveryCount.total_tomorrow_delivery }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>This week</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ deliveryCount.total_this_week_delivery }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Upcoming</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="#3a6afd"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ deliveryCount.total_unassigned_delivery }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  class="d-flex flex-column text-left v_list"
                  style="margin-top: -70px !important"
                >
                  <ul>
                    <template v-for="(row, index) in deliveryPriorityArr">
                      <li :key="index">
                        <span
                          :class="`svg-icon svg-icon-xl ${priorityColor(row)}`"
                        >
                          <inline-svg
                            :src="$assetURL('media/custom-svg/right.svg')"
                          />
                        </span>
                        <strong>{{ priorityText(row) }}</strong>
                        <div class="vdnum" v-if="countLoading">
                          <v-progress-circular
                            indeterminate
                            size="24"
                            color="#3a6afd"
                          ></v-progress-circular>
                        </div>

                        <span
                          v-else
                          :style="{ 'background-color': priorityBgColor(row) }"
                        >
                          {{ row.total_visit }}
                        </span>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">JOB TYPE-WISE VISITS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDurationNew"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoadingNew">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="issueChartoptionsNew"
                :series="issueSeriesNew"
              ></apexchart>
            </template>
          </div>
        </div>
        <IssueChartoption v-if="false"></IssueChartoption>
      </v-col>
      <v-col md="6" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">PAYMENT TYPE-WISE DELIVERIES</h3>

              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDuration"
                  hide-details
                  :disabled="issueDCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getDeliveryIssueGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueDCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="deliveryChartoptions"
                :series="deliverySeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
      <v-col md="12" sm="12" class="{'d-none': !overdue_visits}">
        <DashboardVisits
          v-on:init-component="overdue_visits = true"
        ></DashboardVisits>
      </v-col>
      <v-col md="12" sm="12" class="{'d-none': !overdue_deliveies}">
        <DashboardDeliveries
          v-on:init-component="overdue_deliveies = true"
        ></DashboardDeliveries>
      </v-col>
      <v-col md="12" sm="12" class="d-flex">
        <v-col md="6">
          <!--begin::Mixed Widget 1-->
          <div class="card card-custom bg-gray-100 card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 bg-orange py-5 pb-2">
              <h3 class="card-title font-weight-bolder text-white">
                Contract Status
              </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0 position-relative overflow-hidden">
              <!--begin::Chart-->
              <div
                id="kt_mixed_widget_1_chart"
                class="card-rounded-bottom bg-orange dashboard-total-jobs"
                style="min-height: 100px"
              ></div>
              <div class="card-spacer smallbxc_row">
                <!--begin::Row-->
                <div class="row m-0">
                  <div class="col bg-light-warning ttbox rounded-xl mr-7">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                    >
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/ticket.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.total_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'all',
                          },
                        })
                      "
                      class="text-warning font-weight-bold font-size-h6"
                      >Total Contracts</router-link
                    >
                  </div>
                  <div class="col bg-light-primary ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/tickets.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.draft_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'pending',
                          },
                        })
                      "
                      class="text-primary font-weight-bold font-size-h6"
                      >Total Draft Contracts</router-link
                    >
                  </div>
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row">
                  <div class="col bg-light-info ttbox rounded-xl mr-7">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/close-t.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="info"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.active_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'active',
                          },
                        })
                      "
                      class="text-info font-weight-bold font-size-h6"
                      >Total Active Contract</router-link
                    >
                  </div>
                  <div class="col bg-light-success ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/delete.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.cancelled_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'cancelled',
                          },
                        })
                      "
                      class="text-success font-weight-bold font-size-h6"
                      >Total Cancel Contracts</router-link
                    >
                  </div>
                </div>
                <!--end::Row-->

                <!--begin::Row-->
                <div class="row m-0">
                  <div class="col bg-light-warning ttbox rounded-xl mr-7">
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/tt1.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="red"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.accepted_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'accepted',
                          },
                        })
                      "
                      class="text-danger font-weight-bold font-size-h6"
                      >Total Accepted Contracts</router-link
                    >
                  </div>
                  <div class="col bg-light-black ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-black d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/delete.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ contractCount.expired_contracts }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('contract', {
                          query: {
                            status: 'expired',
                          },
                        })
                      "
                      class="text-success font-weight-bold font-size-h6"
                      >Total Expired Contracts</router-link
                    >
                  </div>
                  <div
                    v-if="false"
                    class="col bg-light-warning ttbox rounded-xl"
                  >
                    <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/bug.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ bugCount }}
                      </template>
                    </div>
                    <router-link
                      :to="getDefaultRoute('bug.report')"
                      class="text-success font-weight-bold font-size-h6"
                      >Bug Reports</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Mixed Widget 1-->
        </v-col>
        <v-col md="6">
          <!--begin::Mixed Widget 1-->
          <div class="card card-custom bg-gray-100 card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 bg-blue py-5 pb-2">
              <h3 class="card-title font-weight-bolder text-white">
                Project Status
              </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0 position-relative overflow-hidden">
              <!--begin::Chart-->
              <div
                id="kt_mixed_widget_1_chart"
                class="card-rounded-bottom bg-blue dashboard-total-jobs"
                style="min-height: 100px"
              ></div>
              <div class="card-spacer smallbxc_row">
                <!--begin::Row-->
                <div class="row m-0">
                  <div class="col bg-light-warning ttbox rounded-xl mr-7">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                    >
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/ticket.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.total_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'all',
                          },
                        })
                      "
                      class="text-warning font-weight-bold font-size-h6"
                      >Total Projects</router-link
                    >
                  </div>
                  <div class="col bg-light-primary ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/tickets.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.not_started_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'not_started',
                            tab: 'all',
                          },
                        })
                      "
                      class="text-primary font-weight-bold font-size-h6"
                      >Total Not Started Projects</router-link
                    >
                  </div>
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row">
                  <div class="col bg-light-info ttbox rounded-xl mr-7">
                    <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/close-t.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="info"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.in_progress_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'in_progress',
                          },
                        })
                      "
                      class="text-info font-weight-bold font-size-h6"
                      >Total In Progress Projects</router-link
                    >
                  </div>
                  <div class="col bg-light-success ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/delete.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.on_hold_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'on_hold',
                          },
                        })
                      "
                      class="text-success font-weight-bold font-size-h6"
                      >Total On Hold Projects</router-link
                    >
                  </div>
                </div>
                <!--end::Row-->

                <!--begin::Row-->
                <div class="row m-0">
                  <div class="col bg-light-danger ttbox rounded-xl">
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/tt1.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="red"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.cancelled_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'cancelled',
                          },
                        })
                      "
                      class="text-danger font-weight-bold font-size-h6"
                      >Total Cancelled Projects</router-link
                    >
                  </div>
                  <div class="col bg-light-black ttbox rounded-xl">
                    <span
                      class="svg-icon svg-icon-3x svg-icon-black d-block my-2"
                    >
                      <inline-svg
                        :src="$assetURL('media/custom-svg/delete.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ projectCount.finished_projects }}
                      </template>
                    </div>
                    <router-link
                      :to="
                        getDefaultRoute('project', {
                          query: {
                            status: 'finished',
                          },
                        })
                      "
                      class="text-success font-weight-bold font-size-h6"
                      >Total Finished Projects</router-link
                    >
                  </div>
                  <div
                    v-if="false"
                    class="col bg-light-warning ttbox rounded-xl"
                  >
                    <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/bug.svg')"
                      />
                    </span>
                    <div class="ttnumbr">
                      <template v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ bugCount }}
                      </template>
                    </div>
                    <router-link
                      :to="getDefaultRoute('bug.report')"
                      class="text-success font-weight-bold font-size-h6"
                      >Bug Reports</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Mixed Widget 1-->
        </v-col>
      </v-col>

      <v-col md="6" sm="12" v-if="false">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">PRIORITY-WISE VISITS</h3>

              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDuration"
                  hide-details
                  :disabled="issueCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="issueChartoptions"
                :series="issueSeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <template>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog="customerDialog"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomer="selectCustomer"
          v-on:selectCustomerPerson="selectCustomerPerson"
          v-on:selectCustomerProperty="selectCustomerProperty"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerDialog>
      </template>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          :customerPersonDialog="customerPersonDialog"
          :customer="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </v-container>
</template>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss"></style>
<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import DashboardVisits from "@/view/pages/partials/Dashboard-Visits.vue";
import DashboardDeliveries from "@/view/pages/partials/Dashboard-Deliveries.vue";
import IssueChartoption from "@/view/pages/Issues-Wise-chart.vue";

export default {
  name: "dashboard",
  data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      issueDCountLoading: false,
      overdue_visits: false,
      overdue_deliveies: false,
      prioritySeries: [],
      priorityDeliverySeries: [],
      issueCountLoadingNew: false,
      issueSeriesNew: [],
      issueChartoptionsNew: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      priorityDeliveryChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeries: [],
      deliverySeries: [],

      issueChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      deliveryChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      visitPriorityArr: [],
      deliveryPriorityArr: [],
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityDeliveryDuration: "this_month",
      priorityCountLoading: false,
      priorityCountDeliveryLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Year", value: "this_year" },
      ],
      customerDuration: "this_month",
      jobDuration: "this_month",
      issueDurationNew: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0,
      },
      quotationCount: {
        count: 0,
        percentage: 0,
        average_revenue: 0,
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0,
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0,
      },
      visitCount: {
        total_visits: 0,
        total_open_visits: 0,
        total_overdue_visits: 0,
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcomming_visits: 0,
        total_inprogress_visits: 0,
        total_hold_visits: 0,
        total_completed_visits: 0,
        total_cancelled_visits: 0,
      },

      contractCount: {
        active_contracts: 0,
        expired_contracts: 0,
        cancelled_contracts: 0,
        draft_contracts: 0,
        about_to_expired_contracts: 0,
        rejected_contracts: 0,
        total_contracts: 0,
      },
      projectCount: {
        cancelled_projects: 0,
        finished_projects: 0,
        in_progress_projects: 0,
        not_started_projects: 0,
        on_hold_projects: 0,
        total_projects: 0,
      },
      deliveryCount: {
        total_assigned_delivery: 0,
        total_delivered: 0,
        total_delivery: 0,
        total_failed_delivey: 0,
        total_inprogress_delivery: 0,
        total_is_accepted: 0,
        total_new_delivery: 0,
        total_overdue_delivery: 0,
        total_partial_delivery: 0,
        total_this_week_delivery: 0,
        total_today_delivery: 0,
        total_tomorrow_delivery: 0,
        total_unassigned_delivery: 0,
        total_upcomming_delivery: 0,
      },
    };
  },
  methods: {
    getIssueGraphNew() {
      const _this = this;
      _this
        .getDashboardIssueCountNew()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptionsNew.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeriesNew = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoadingNew = false;
        });
    },
    getDashboardIssueCountNew() {
      const _this = this;
      _this.issueCountLoadingNew = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueSeriesNew,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    priorityText(row) {
      if (row.priority == 1) {
        return "High";
      }
      if (row.priority == 2) {
        return "Medium";
      }
      if (row.priority == 3) {
        return "Low";
      }
      if (row.priority == 0) {
        return "None";
      }
      return null;
    },
    priorityColor(row) {
      if (row.priority == 1) {
        return "svg-icon-danger";
      }
      if (row.priority == 2) {
        return "svg-icon-warning";
      }
      if (row.priority == 3) {
        return "svg-icon-success";
      }
      if (row.priority == 0) {
        return "cyan";
      }
      return null;
    },
    priorityBgColor(row) {
      if (row.priority == 1) {
        return "#f64e60";
      }
      if (row.priority == 2) {
        return "#ffa800";
      }
      if (row.priority == 3) {
        return "#1bc5bd";
      }
      /* if (row.priority == 0) {
        return "cyan";
      } */
      return null;
    },

    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
    getDashboardCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-count",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityMainCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-priority-count",
            })
            .then(({ data }) => {
              this.visitPriorityArr = data.visitPriority;
              this.deliveryPriorityArr = data.deliveryPriority;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount() {
      const _this = this;
      _this.customerCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-customer",
              data: {
                duration: _this.customerDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount() {
      const _this = this;
      _this.jobCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-job",
              data: {
                duration: _this.jobDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount() {
      const _this = this;
      _this.quotationCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-quotation",
              data: {
                duration: _this.quotationDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    /* getDashboardPriorityCount() {
      const _this = this;
      _this.priorityCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-graph",
              data: {
                duration: _this.priorityDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    }, */
    getGrafcolor() {
      this.lodash.forEach(this.deliveryPriorityArr, (row) => {
        console.log(row, "rowrow");
      });
    },
    getDashboardDIssueCount() {
      const _this = this;
      _this.issueDCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-delivery-piegraph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              /*  console.log(data,'data') */
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-visit-graph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount() {
      const _this = this;
      _this
        .getDashboardCustomerCount()
        .then(({ customer }) => {
          if (customer) {
            _this.customerCount = customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerCountLoading = false;
        });
    },
    getJobsCount() {
      const _this = this;
      _this
        .getDashboardJobCount()
        .then(({ job }) => {
          if (job) {
            _this.jobsCount = job;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobCountLoading = false;
        });
    },
    getQuotationCount() {
      const _this = this;
      _this
        .getDashboardQuotationCount()
        .then(({ quotation }) => {
          if (quotation) {
            _this.quotationCount = quotation;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.quotationCountLoading = false;
        });
    },
    getPriorityGraph() {
      const _this = this;
      _this
        .getDashboardPriorityCount()
        .then(({ categories, prioritySeries }) => {
          if (categories) {
            _this.priorityChartoptions.xaxis.categories = categories;
          }
          if (prioritySeries) {
            _this.prioritySeries = prioritySeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.priorityCountLoading = false;
        });
    },

    getIssueGraph() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.issueChartoptions.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeries = issueSeries;
          }
          if (issueSeriesColor) {
            _this.issueChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoading = false;
        });
    },
    getDeliveryIssueGraph() {
      const _this = this;
      _this
        .getDashboardDIssueCount()
        .then(({ deliverySeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.deliveryChartoptions.labels = labels;
          }
          if (deliverySeries) {
            _this.deliverySeries = deliverySeries;
          }
          if (issueSeriesColor) {
            _this.deliveryChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueDCountLoading = false;
        });
    },
  },
  components: {
    CustomerDialog,
    DashboardVisits,
    DashboardDeliveries,
    IssueChartoption,
    CustomerPersonDialog,
    CustomerPropertyDialog,
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    _this.getCustomerCount();
    _this.getJobsCount();
    _this.getQuotationCount();

    _this.getDashboardPriorityMainCount();
    _this.getIssueGraph();
    _this.getIssueGraphNew();
    _this.getDeliveryIssueGraph();

    _this
      .getDashboardCount()
      .then(({ job, visit, bug, project, delivery, contract }) => {
        if (job) {
          _this.jobCount = job;
        }
        if (visit) {
          _this.visitCount = visit;
        }
        if (bug) {
          _this.bugCount = bug;
        }
        if (project) {
          _this.projectCount = project;
        }
        if (delivery) {
          _this.deliveryCount = delivery;
        }
        if (contract) {
          _this.contractCount = contract;
        }
      })
      .catch((error) => {
        _this.logError(error);
      })
      .finally(() => {
        _this.countLoading = false;
      });
  },
};
</script>
