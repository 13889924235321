<template>
  <v-container fluid class="custom-bthrust-dashboard pt-0">
    <v-row>
      <v-col md="12" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">ISSUE-WISE VISITS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDurationNew"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoadingNew">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="issueChartoptionsNew"
                :series="issueSeriesNew"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "dashboard",
  data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      issueCountLoadingNew: false,
      transactionType: null,
      overdue_visits: false,
      issueDurationNew: "this_month",
      prioritySeries: [],
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeriesNew: [],
      issueChartoptionsNew: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Year", value: "this_year" },
      ],
    };
  },

  methods: {
    getIssueGraphNew() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptionsNew.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeriesNew = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoadingNew = false;
        });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoadingNew = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueSeriesNew,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    this.getIssueGraphNew();
  },
};
</script>
