<template>
  <v-menu
    v-model="datepicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        flat
        :id="id"
        :placeholder="placeholder"
        hide-details
        solo
        :disabled="disabled"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :value="formattedDate"
        color="cyan"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      color="cyan"
      :min="minDate"
      :disabled="disabled"
      v-on:input="datepicker = false"
      v-on:change="emitValue()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "datepicker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    id: {
      type: String,
      default: "start-date",
    },
    placeholder: {
      type: String,
      default: "Start Date",
    },
    minDate: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function () {
      this.init();
    },
  },
  data() {
    return {
      datepicker: false,
      date: null,
    };
  },
  methods: {
    init() {
      this.date = this.value;
    },
    emitValue() {
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    formattedDate() {
      let Config = AppConfiguration.get();
      if (Config && this.date) {
        return moment(this.date).format(Config.dateFormat);
      }
      return this.date;
    },
  },
};
</script>
