<template>
  <CreateUpdateTemplate :routePreventDialog="routePreventDialog" :customClass="'customer-create create-form'"
    v-if="getPermission('customer:create')">
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="equipmentId > 0"> Update Equipment </template>
        <template v-else> Add New Equipment</template>
        <v-chip label color="chip-custom-blue" outlined class="text-white p-3 ml-2"
          style="font-size: 21px !important; font-weight: 700">{{ barcode }}</v-chip>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn v-if="!isCustomer" v-on:click="goBack" :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button">
        Cancel
      </v-btn>
      <v-btn v-if="isCustomer" v-on:click="$emit('close',true)" :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button">
        Cancel
      </v-btn>
      <v-btn :disabled="!formValid || formLoading" :loading="formLoading" class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate" color="cyan">
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form ref="assetForm" v-model.trim="formValid" lazy-validation v-on:submit.stop.prevent="updateOrCreate">
        <v-tabs v-model="assetTab" background-color="transparent" centered color="cyan" fixed-tabs
          class="custom-tab-transparent">
          <template v-for="(tab, index) in tabs">
            <v-tab class="font-size-16 font-weight-600 px-8"
              v-if="getPermission(tab.permission + ':create') && tab.status" :key="index" :href="'#tab-' + tab.key">
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="assetTab">
          <v-row>
            <v-col class="iv-custom-field" cols="12" md="12" sm="12">
              <v-tab-item :value="'tab-overview'">
                <v-layout>
                   <v-col class="custom-content-container pt-0" cols="9" :md="9" :sm="9">
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col class="iv-custom-field" cols="12" md="12" sm="12">
                          <perfect-scrollbar :options="{ suppressScrollX: true }" class="scroll"
                            style="max-height: 90vh; position: relative">
                            <v-container fluid>
                              <v-card flat class="custom-grey-border remove-border-radius">
                                <v-card-title class="headline grey lighten-4">
                                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                                    Core Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row v-if="!isCustomer && !equipmentId">
                                    <v-col lg="12" class="d-flex">
                                      <!-- <label
                                        class="font-size-16 font-weight-500"
                                        for="asset_type"
                                        >Asset Type</label
                                      > -->
                                      <v-btn depressed tile :outlined="!(asset.main_type == 'main_customer')
                                        " v-on:click="
                                          (asset.main_type = 'main_customer'),
                                          (asset.product = null)
                                          " value="main_customer" color="green darken-3 white--text"
                                                                              v-on:change="checkType">Customer</v-btn>
                                                                            <v-btn class="mx-2" depressed v-on:change="checkType" tile :outlined="!(asset.main_type == 'main_company')
                                                                              " v-on:click="
                                          (asset.main_type = 'main_company'),
                                          (asset.asset_type = 'company'),
                                          (asset.name = null),
                                          (asset.serial_no = null),
                                          (asset.category = null),
                                          (asset.brand = null),
                                          (asset.warranty_duration_type =
                                            null),
                                          (asset.warranty_duration = null)
                                          " value="main_company" color="blue darken-4 white--text">Company</v-btn>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="!equipmentId">
                                    <v-col lg="12" class="d-flex">
                                      <!-- <label
                                        class="font-size-16 font-weight-500"
                                        for="asset_type"
                                        >Asset Type</label
                                      > -->
                                      <v-radio-group v-model="asset.asset_type" :disabled="asset.main_type == 'main_company'
                                        " v-on:change="
                                          (asset.name = null),
                                          (asset.serial_no = null),
                                          (asset.product = 0),
                                          (asset.brand = null),
                                          (asset.warranty_duration = null),
                                          (asset.warranty_duration_type =
                                            null),
                                          (asset.category = null)
                                          " row hide-details class="mt-0">
                                        <v-radio style="margin-right: -2px" value="personal" color="cyan"></v-radio>
                                        <v-chip label color="cyan" outlined class="text-white p-3 mr-5"
                                          small>Customer/Own</v-chip>
                                        <v-radio value="company" color="red" style="margin-right: -2px"></v-radio>
                                        <v-chip label color="red" outlined class="text-white p-3" small>Acom</v-chip>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <template v-if="asset.main_type == 'main_customer'">
                                    <v-row>
                                      <v-col v-if="!isCustomer">
                                        <label for="customer-equipment"
                                          class="font-weight-700 font-size-16 required">Customer</label>
                                        <v-autocomplete hide-details :readonly="equipmentId > 0 ? true : false
                                          " id="customer-equipment" :items="customerList" dense flat filled
                                          label="Customer" :rules="[
                                            validateRules.required(
                                              asset.customer,
                                              'customer'
                                            ),
                                          ]" item-color="cyan" color="cyan" solo v-model.trim="asset.customer"
                                          item-text="display_name" item-value="id" v-on:change="filter_property($event)">
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title v-html="'No Customer(s) Found.'"></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                              <v-img :lazy-src="$defaultProfileImage" :src="$assetAPIURL(
                                                item.profile_logo
                                              )
                                                " aspect-ratio="1" class="margin-auto"
                                                transition="fade-transition"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
                                                item.display_name
                                              }}</v-list-item-title>
                                              <v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
                                                item.company_name
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action v-if="item.default_person" class="align-self-center">
                                              <v-list-item-subtitle
                                                class="text-lowercase pb-2 font-weight-500 font-size-14">{{
                                                  item.default_person
                                                    .primary_email
                                                }}</v-list-item-subtitle>
                                              <v-list-item-subtitle class="text-lowercase font-weight-500 font-size-14">{{
                                                item.default_person
                                                  .primary_phone
                                              }}</v-list-item-subtitle>
                                            </v-list-item-action>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>
                                      <v-col :lg="isCustomer ? 12 : 6">
                                        <label for="property-equipment" class="font-weight-700 font-size-16 required">Site
                                          Location</label>
                                        <v-autocomplete hide-details id="property-equipment" :readonly="equipmentId > 0 ? true : false
                                          " :items="filtered_property" :rules="[
                                                validateRules.required(
                                                  asset.property,
                                                  'property'
                                                ),
                                              ]" v-model.trim="asset.property" dense flat filled label="Site Location"
                                          item-color="cyan" color="cyan" solo item-text="barcode" item-value="id">
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title v-html="'No Site Location(s) Found.'
                                                "></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-icon class="mr-3">
                                              <v-icon large>mdi-home-map-marker</v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                              <v-layout style="max-width: 150px">
                                                <v-flex>
                                                  <Barcode class="my-0 mt-0 mb-0 mr-1" :barcode="item.barcode"></Barcode>
                                                </v-flex>
                                                <v-flex v-if="item.is_tenant">
                                                  <v-chip class="mx-1" label small color="green" outlined>Tenant</v-chip>
                                                </v-flex>
                                                <v-flex>
                                                  <v-chip label small class="ml-1" :color="item.type == 1
                                                      ? 'cyan'
                                                      : 'orange'
                                                    " outlined>
                                                    <template v-if="item.type == 1">Site Location</template>
                                                    <template v-else>Billing</template>
                                                  </v-chip>
                                                </v-flex>
                                              </v-layout>
                                              <v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
                                                get_formatted_property(item)
                                              }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col lg="12" v-if="asset.asset_type == 'company'">
                                        <label for="customer-equipment"
                                          class="font-weight-700 font-size-16 required">Product</label>
                                        <v-autocomplete hide-details id="customer-equipment" :items="products"
                                          v-model.trim="asset.product" dense flat filled label="Product" item-color="cyan"
                                          color="cyan" solo item-text="barcode" :rules="[
                                            validateRules.required(
                                              asset.product,
                                              'product'
                                            ),
                                          ]" v-on:change="productData($event)" item-value="id">
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title v-html="'No Product(s) Found.'"></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                              <v-img :lazy-src="$defaultImage" :src="item &&
                                                item.product_image &&
                                                item.product_image.file &&
                                                item.product_image.file.url
                                                " aspect-ratio="1" class="margin-auto"
                                                transition="fade-transition"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
                                                item.name
                                              }}</v-list-item-title>
                                              <v-list-item-title class="text-capitalize font-weight-500 font-size-16">{{
                                                item.barcode
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Installation
                                        Date</label>
                                      <DatePicker solo flat :mandatory="false" :nullable="true"
                                        placeholder="Installation Date" v-model="asset.installation_date"
                                        :min-date="minDate" :default-date="asset.installation_date"></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Activation
                                        Date</label>
                                      <DatePicker solo flat :mandatory="false" :nullable="true"
                                        placeholder="Activation Date" v-model="asset.activation_date" :min-date="minDate"
                                        :default-date="asset.activation_date"></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 required font-size-16 width-100">Name</label>
                                      <v-text-field id="name" v-model.trim="asset.name" :rules="[
                                        validateRules.required(
                                          asset.name,
                                          'name'
                                        ),
                                      ]" dense filled label="Name" solo flat color="cyan"
                                        class="required-field width-100"></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 required font-size-16 width-100">Serial
                                        No.</label>
                                      <v-text-field id="Serial No."  v-model.trim="asset.serial_no" :rules="[
                                            validateRules.required(
                                              asset.serial_no,
                                              'Serial No.'
                                            ),
                                          ]" dense filled label="Serial No." solo flat color="cyan"
                                        class="required-field width-100"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Category</label>
                                      <div class="d-flex">
                                        <v-autocomplete dense filled color="cyan" item-color="cyan" :items="categoryList"
                                          label="Category" solo flat append-outer-icon="mdi-cog" v-on:click:append-outer="
                                            manageGroupDialog = true
                                            " item-text="text" item-value="text" class="pr-2 width-100"
                                          v-model.trim="asset.category">
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title v-html="'No Category(s) Found.'
                                                  "></v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </template>
                                        </v-autocomplete>
                                      </div>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">End Life</label>
                                      <DatePicker solo flat :mandatory="false" :nullable="true" placeholder="End Life"
                                        v-model="asset.end_life" :min-date="minDate" :default-date="asset.end_life">
                                      </DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Contact for
                                        Item</label>
                                      <PhoneTextField v-model="asset.company_number"></PhoneTextField>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Model</label>
                                      <v-text-field id="model-equipment" dense filled label="Model" solo
                                        v-model="asset.model" flat color="cyan"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Location
                                      </label>

                                      <v-autocomplete hide-details id="location-equipment" :items="equipment_locations"
                                        dense v-model="asset.location" flat filled label="Location" item-color="cyan"
                                        color="cyan" solo item-text="text" item-value="id" append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_location = true
                                          ">
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title v-html="'No Location(s) Found.'"></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Brand</label>
                                      <v-autocomplete hide-details id="brand-equipment" :items="brands" dense flat filled
                                        label="Brand" item-color="cyan" v-model="asset.brand" color="cyan" solo
                                        item-text="text" item-value="text" append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_brand = true
                                          ">
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title v-html="'No Brand(s) Found.'"></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Tags</label>
                                      <TagAutoComplete type="equipment" v-model="asset.tags" />
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Description</label>
                                      <v-textarea v-model.trim="asset.description" auto-grow dense filled color="cyan"
                                        placeholder="Description" solo flat row-height="15" v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"></v-textarea>
                                      <v-col md="12" class="text-right">{{
                                        asset.description
                                        ? asset.description.length
                                        : 0
                                      }}/200</v-col>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <!--  <v-col lg="3" class="d-flex">
                                      <label>Is Return After End Life ? </label>
                                      <v-switch
                                        class="ml-4"
                                        color="cyan"
                                        dense
                                        inset
                                        hide-details
                                        v-model="asset.is_return"
                                      ></v-switch>
                                    </v-col> -->
                                    <v-col lg="3" class="d-flex">
                                      <label>Is any Warranty ? </label>
                                      <v-switch class="ml-4" color="cyan" dense :readonly="equipmentId > 0 ? true : false
                                          " inset hide-details v-on:change="changeWarrantyCheck"
                                        v-model="asset.check_warranty"></v-switch>
                                    </v-col>
                                    <v-col lg="3" class="d-flex" v-if="asset.asset_type == 'company'">
                                      <label>Is any Purchased Info ? </label>
                                      <v-switch class="ml-4" color="cyan" :readonly="equipmentId > 0 ? true : false
                                        " dense inset hide-details v-model="asset.is_purchase_info"></v-switch>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>

                              <v-card v-if="asset.is_purchase_info &&
                                asset.asset_type == 'company'
                                " flat class="custom-grey-border remove-border-radius mt-4">
                                <v-card-title class="headline grey lighten-4">
                                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                                    Purchased Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Cost Price</label>
                                      <v-text-field id="cost_price" v-model.trim="asset.cost_price" dense filled
                                        label="Cost Price" solo flat color="cyan" class="width-100"></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Purchased On</label>
                                      <DatePicker solo flat :mandatory="false" :nullable="true" placeholder="Purchased On"
                                        v-model="asset.purchased_on" :default-date="asset.purchased_on"></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Supplier</label>
                                      <v-autocomplete :items="supplierList" dense filled color="cyan" item-color="cyan"
                                        label="Supplier" solo flat item-text="display_name" item-value="id"
                                        class="pl-2 width-100" v-model.trim="asset.vendor">
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title v-html="'No Supplier(s) Found.'"></v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <!--  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Description</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.description"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Asset Remark"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                  <v-col cols="3" md="3" sm="3">
                    <template>
                    <v-img :lazy-src="$defaultImage" :src="getProfileImage" max-height="200" max-width="200"
                      height="200px" width="200px"
                      class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto">
                    </v-img>
                    <div class="margin-auto">
                      <v-btn class="my-4 custom-bold-button width-100" outlined small v-on:click="selectImage"
                        color="cyan" :loading="imageLoading" :disabled="imageLoading">
                        <template v-if="getProfileImage">Change Image</template>
                        <template v-else>Upload Image</template>
                      </v-btn>

                      <div class="d-none">
                        <v-file-input ref="uploadImage" v-on:change="updateProfileImage" :rules="imageRules"
                          accept="image/png, image/jpeg, image/jpg"></v-file-input>
                      </div>
                    </div>
                  </template>
                  <v-layout class="bg-equ" v-if="asset.product">
                   <!--  <v-flex md3>
                      <v-avatar>
                        <v-img :lazy-src="$defaultImage" :src="item && 
                            item.product_image &&
                            item.product_image.file &&
                            item.product_image.file.url
                            " aspect-ratio="1" class="margin-auto" height="50px" width="50px"
                            transition="fade-transition"></v-img>
                      </v-avatar>
                    </v-flex> -->
                    <v-flex class="mr-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <label>Product details</label>
                          </td>
                        </tr>
                        <tr v-if="show_name">
                          <td class="py-0">
                            <span class="mr-2 font-weight-600" v-if="
                              show_name
                              ">
                              <label class="font-weight-600">Name<span class="ml-5">:</span></label></span>
                            <label>{{
                              show_name
                            }}</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0">
                            <span class="mr-2" v-if="show_serial_no">
                              <label class="font-weight-600">Item No<span class="ml-3">:</span></label></span>
                            <label v-if="show_serial_no">{{
                              show_serial_no
                            }}</label>
                            <em v-else class="text-muted">
                              no item number</em>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0">
                            <span class="mr-2">
                              <label class="font-weight-600">Category<span class="ml-2">:</span></label></span>
                            <label v-if="show_category">{{
                              show_category
                            }}</label>
                            <em v-else class="text-muted">
                              no category</em>
                          </td>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>
              <v-tab-item :value="'tab-allocate-details'"> </v-tab-item>
              <v-tab-item :value="'tab-warranty'" v-if="assetTab == 'tab-warranty'">
                <v-layout>
                  <v-col class="custom-content-container pt-0" cols="12" :md="12" :sm="12">
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col class="iv-custom-field" cols="12" md="12" sm="12">
                          <perfect-scrollbar :options="{ suppressScrollX: true }" class="scroll"
                            style="max-height: 90vh; position: relative">
                            <v-container fluid>
                              <v-card flat class="custom-grey-border remove-border-radius">
                                <v-card-title class="headline grey lighten-4">
                                  <h3 class="font-weight-700 custom-headline color-custom-blue">
                                    Warranty Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        for="asset_type">Warranty Duration
                                      </label>
                                      <v-text-field id="duration" v-model.trim="asset.warranty_duration" dense filled
                                        label="Duration" solo :disabled="equipmentId > 0 ? true : false
                                          " flat color="cyan" class="pr-2 width-100" v-on:keyup="(e) =>
                                        updateWarrantyDuration(
                                          e,
                                          'warranty_duration'
                                        )
                                      "></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        for="asset_type">Warranty Duration Type</label>
                                      <v-autocomplete :items="durationTypeList" dense :disabled="equipmentId > 0 ? true : false
                                        " filled color="cyan" item-color="cyan" label="Duration Type" solo v-on:change="(e) =>
                                          updateWarrantyDuration(
                                            e,
                                            'warranty_duration_type'
                                          )
                                        " flat item-text="text" item-value="value" class="pl-2 width-100" v-model.trim="asset.warranty_duration_type
                                        ">
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required">Start Date
                                      </label>
                                      <DatePicker solo :disabled="dateDisable()" flat :rules="[
                                        validateRules.required(
                                          asset.warranty_start_date,
                                          'warranty_start_date'
                                        ),
                                      ]" :nullable="true" placeholder="Start Date"
                                        v-model="asset.warranty_start_date" :default-date="asset.warranty_start_date
                                          " v-on:change="(e) =>
                                              updateWarrantyDuration(
                                                e,
                                                'warranty_start_date'
                                              )
                                            " :offWeekend="false"></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required">End
                                        Date</label>
                                      <DatePicker solo flat :disabled="dateDisable()" :rules="[
                                        validateRules.required(
                                          asset.warranty_end_date,
                                          'warranty_end_date'
                                        ),
                                      ]" :nullable="true" placeholder="End Date" v-model="asset.warranty_end_date"
                                        :default-date="asset.warranty_end_date" :min-date="asset.warranty_start_date"
                                        v-on:change="(e) =>
                                            updateWarrantyDuration(
                                              e,
                                              'warranty_end_date'
                                            )
                                          " :offWeekend="false"></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">Cost</label>
                                      <v-text-field type="number" id="cost" v-model.trim="asset.warranty_cost" dense
                                        filled label="Cost" solo flat color="cyan" class="pr-2 width-100"></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <template v-if="asset &&
                                    asset.warranty_reminder &&
                                    asset.warranty_reminder.length > 0 &&
                                    false
                                    ">
                                    <template v-for="(
                                        data, index
                                      ) in asset.warranty_reminder">
                                      <v-row :key="`file-row-${index}`" :class="{
                                        'table-alternate-row': index % 2,
                                      }">
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100">Reminder</label>
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md4>
                                                  <v-radio-group hide-details id="export-type" class="pt-0" v-on:change="
                                                    manageReminder(
                                                      index,
                                                      'warranty'
                                                    )
                                                    " v-model="data.type" row>
                                                    <v-radio label="After" color="cyan" value="after"></v-radio>
                                                    <v-radio label="Before" color="cyan" value="before"></v-radio>
                                                  </v-radio-group>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-text-field id="duration" v-model.trim="data.duration" dense filled
                                                    label="Duration" solo flat color="cyan" class="pr-2 width-100"
                                                    v-on:keyup="
                                                      manageReminder(
                                                        index,
                                                        'warranty'
                                                      )
                                                      "></v-text-field>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-autocomplete :items="durationTypeList" dense filled color="cyan"
                                                    item-color="cyan" label="Duration Type" solo flat item-text="text"
                                                    item-value="text" class="pl-2 width-100" v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'warranty'
                                                      )
                                                      " v-model.trim="data.duration_type
    ">
                                                  </v-autocomplete>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn :disabled="asset.warranty_reminder
                                                  .length < 2
                                                " v-on:click="
    removeWarrantyReminder(index)
    " class="white--text" depressed color="red darken-4" tile
                                                style="margin-left: -10px">
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn :disabled="asset.warranty_reminder
                                                  .length > 4
                                                " v-on:click="
    addMoreWarrantyReminder()
    " class="white--text" depressed color="cyan" tile
                                                style="margin-left: -13px">
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <template v-if="wtx_attachments &&
                                    wtx_attachments.length > 0
                                    ">
                                    <template v-for="(
                                        file, indexFile
                                      ) in wtx_attachments">
                                      <v-row :key="`warranty-file-row-${indexFile}`" :class="{
                                        'table-alternate-row': indexFile % 2,
                                      }">
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100">Attachment</label>
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md6>
                                                  <v-file-input :id="`document-file-${indexFile}`"
                                                    placeholder="Select File" class="removeShadow" prepend-icon="" dense
                                                    solo prepend-inner-icon="mdi-attachment" hide-details
                                                    v-model="file.file" v-on:change="
                                                      updateFile(
                                                        indexFile,
                                                        $event
                                                      )
                                                      " v-on:click:clear="
    updateFile(
      indexFile,
      $event
    )
    "></v-file-input>
                                                </v-flex>
                                                <v-flex md6>
                                                  <v-text-field :id="`document-name-${indexFile}`" v-model="file.name"
                                                    dense filled placeholder="File Name" solo flat color="cyan"
                                                    class="pr-2 width-100"></v-text-field>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn :disabled="wtx_attachments.length < 2
                                                " v-on:click="
    removeFileWarranty(indexFile)
    " class="white--text" depressed color="red darken-4" tile
                                                style="margin-left: -10px">
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn :disabled="wtx_attachments.length > 4
                                                " v-on:click="addMoreWarranty()" class="white--text" depressed
                                                color="cyan" tile style="margin-left: -13px">
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <!--  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Remarks</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.warranty_remarks"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Asset Remark"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>
              <!-- <v-tab-item :value="'tab-maintenance'" v-if="false">
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Maintenance Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Start Date"
                                        v-model="asset.maintenance_start_date"
                                        :default-date="
                                          asset.maintenance_start_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="End Date"
                                        v-model="asset.maintenance_end_date"
                                        :default-date="
                                          asset.maintenance_end_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Maintenance Cost</label
                                      >
                                      <v-text-field
                                        id="cost"
                                        v-model.trim="asset.warranty_cost"
                                        dense
                                        filled
                                        label="Cost"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <template
                                    v-if="
                                      asset &&
                                      asset.maintenance_reminder &&
                                      asset.maintenance_reminder.length > 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        data, index
                                      ) in asset.maintenance_reminder"
                                    >
                                      <v-row
                                        :key="`file-row-${index}`"
                                        :class="{
                                          'table-alternate-row': index % 2,
                                        }"
                                      >
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                            >Reminder {{ index + 1 }}</label
                                          >
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md4>
                                                  <v-radio-group
                                                    hide-details
                                                    id="export-type"
                                                    class="pt-0"
                                                    v-model="data.type"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                    row
                                                  >
                                                    <v-radio
                                                      label="After"
                                                      :value="0"
                                                      color="cyan"
                                                    ></v-radio>
                                                    <v-radio
                                                      label="Before"
                                                      :value="1"
                                                      color="cyan"
                                                    ></v-radio>
                                                  </v-radio-group>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-text-field
                                                    id="duration"
                                                    v-model.trim="data.duration"
                                                    dense
                                                    filled
                                                    label="Duration"
                                                    solo
                                                    flat
                                                    color="cyan"
                                                    class="pr-2 width-100"
                                                    v-on:keyup="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                  ></v-text-field>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-autocomplete
                                                    :items="durationTypeList"
                                                    dense
                                                    filled
                                                    color="cyan"
                                                    item-color="cyan"
                                                    label="Duration Type"
                                                    solo
                                                    flat
                                                    item-text="text"
                                                    item-value="text"
                                                    class="pl-2 width-100"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                    v-model.trim="
                                                      data.duration_type
                                                    "
                                                  >
                                                  </v-autocomplete>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.maintenance_reminder
                                                    .length < 2
                                                "
                                                v-on:click="
                                                  removeMaintenanceReminder(
                                                    index
                                                  )
                                                "
                                                class="white--text"
                                                depressed
                                                color="red darken-4"
                                                tile
                                                style="margin-left: -10px"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.maintenance_reminder
                                                    .length > 4
                                                "
                                                v-on:click="
                                                  addMoreMaintenanceReminder()
                                                "
                                                class="white--text"
                                                depressed
                                                color="cyan"
                                                tile
                                                style="margin-left: -13px"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <template
                                    v-if="
                                      mtx_attachments &&
                                      mtx_attachments.length > 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        file, indexFile
                                      ) in mtx_attachments"
                                    >
                                      <v-row
                                        :key="`warranty-file-row-${indexFile}`"
                                        :class="{
                                          'table-alternate-row': indexFile % 2,
                                        }"
                                      >
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                            >Attachment12
                                            {{ indexFile + 1 }}</label
                                          >
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md6>
                                                </v-flex>
                                                <v-flex md6>
                                                  <v-text-field
                                                    :id="`document-name-${indexFile}`"
                                                    v-model="file.name"
                                                    dense
                                                    filled
                                                    placeholder="File Name"
                                                    solo
                                                    flat
                                                    color="cyan"
                                                    class="pr-2 width-100"
                                                  ></v-text-field>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  mtx_attachments.length < 2
                                                "
                                                v-on:click="
                                                  removeFileMaintenance(
                                                    indexFile
                                                  )
                                                "
                                                class="white--text"
                                                depressed
                                                color="red darken-4"
                                                tile
                                                style="margin-left: -10px"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  mtx_attachments.length > 4
                                                "
                                                v-on:click="
                                                  addMoreMaintenance()
                                                "
                                                class="white--text"
                                                depressed
                                                color="cyan"
                                                tile
                                                style="margin-left: -13px"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Remarks</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.warranty_remarks"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Asset Remark"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item> -->
            </v-col>
            <v-col md3 v-if="false">
                  <template>
                    <v-img :lazy-src="$defaultImage" :src="getProfileImage" max-height="200" max-width="200"
                      height="200px" width="200px"
                      class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto">
                    </v-img>
                    <div class="margin-auto">
                      <v-btn class="my-4 custom-bold-button width-100" outlined small v-on:click="selectImage"
                        color="cyan" :loading="imageLoading" :disabled="imageLoading">
                        <template v-if="getProfileImage">Change Image</template>
                        <template v-else>Upload Image</template>
                      </v-btn>

                      <div class="d-none">
                        <v-file-input ref="uploadImage" v-on:change="updateProfileImage" :rules="imageRules"
                          accept="image/png, image/jpeg, image/jpg"></v-file-input>
                      </div>
                    </div>
                  </template>
                  <v-layout class="bg-quo" v-if="asset.product">
                    <v-flex class="mr-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <label>Product details</label>
                          </td>
                        </tr>
                        <tr v-if="show_name">
                          <td class="py-0">
                            <span class="mr-2 font-weight-600" v-if="
                              show_name
                              ">
                              <label class="font-weight-600">Name<span class="ml-">:</span></label></span>
                            <label>{{
                              show_name
                            }}</label>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0">
                            <span class="mr-2" v-if="show_serial_no">
                              <label class="font-weight-600">Item No<span class="ml-3">:</span></label></span>
                            <label v-if="show_serial_no">{{
                              show_serial_no
                            }}</label>
                            <em v-else class="text-muted">
                              no item number</em>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0">
                            <span class="mr-2">
                              <label class="font-weight-600">Category<span class="ml-2">:</span></label></span>
                            <label v-if="show_category">{{
                              show_category
                            }}</label>
                            <em v-else class="text-muted">
                              no category</em>
                          </td>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
            </v-col>
          </v-row>
        </v-tabs-items>
      </v-form>
      <ManageBrand :b-dialog="manage_brand" :brand="brands" Equipment v-on:close-dialog="manage_brand = false"
        v-on:get-product-brand="get_options"></ManageBrand>
      <ManageEquipmentLocation :dialog="manage_location" :equipment="equipment_locations"
        v-on:close-dialog="manage_location = false" v-on:get-equipment-location="get_options"></ManageEquipmentLocation>
      <template v-if="manageGroupDialog">
        <ManageCategory Equipment :c-dialog="manageGroupDialog" :category="categoryList"
          v-on:close-dialog="manageGroupDialog = false" v-on:get-product-category="get_options"></ManageCategory>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, CLEAR_ERROR, GET } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { cloneDeep, findIndex, toSafeInteger /*  filter  */ } from "lodash";
import {
  /* SET_MESSAGE, */ SET_ERROR,
} from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import DatePicker from "@/view/components/DatePicker.vue";
import MomentJS from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
export default {
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  name: "customer-create",
  title: "Create Asset",
  data() {
    return {
      leadId: 0,
      imageLoading: false,
      customerList: [],
      formValid: true,
      formLoading: false,
      manage_brand: false,
      manage_location: false,
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      durationTypeList: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      lead: {},
      minDate: null,
      categoryList: [],
      equipmentId: 0,
      barcode: null,
      manageGroupDialog: false,
      propertyAddress: {},
      billingAddress: {},
      all_customers: [],
      all_property: [],
      brands: [],
      products: [],
      supplierList: [],
      equipment_locations: [],
      filtered_property: [],
      contactDetail: {},
      contactPerson: [],
      notification: {},
      updatedImg: null,
      show_serial_no :null,
      show_name :null,
      show_category :null,
      asset: {
        asset_type: "personal",
        name: null,
        main_type: "main_customer",
        customer: 0,
        brand: null,
        installation_date: null,
        activation_date: null,
        property: 0,
        product: 0,
        asset_image: null,
        category: null,
        pico_barcode: null,
        serial_no: null,
        location: 0,
        model: 0,
        check_warranty: false,
        is_return: false,
        is_purchase_info: false,
        description: null,
        tags: [],
        warranty_start_date: null,
        warranty_end_date: null,
        warranty_duration: 0,
        warranty_duration_type: "month",
        warranty_cost: 0,
        warranty_reminder: [
          {
            type: "after",
            duration: null,
            duration_type: "Day",
            reminder_type: null,
          },
        ],
      },
      wtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      /*  mtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }), */
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "customer",
          status: true,
        },
        {
          title: "Allocate Details",
          icon: "mdi-account-multiple",
          key: "allocate-details",
          permission: "contact-person",
          status: false,
        },
        {
          title: "Warranty Details",
          icon: "mdi-home-map-marker",
          key: "warranty",
          permission: "property",
          status: true,
        },
        /*   {
          title: "Maintenance Details",
          icon: "mdi-credit-card",
          key: "maintenance",
          permission: "customer",
          status: true,
        }, */
      ],
      assetTab: "tab-overview",
      isCustomerCreated: false,
      isAddressCreated: false,
      options: {},
      steps: {
        details: false,
        "contact-person": true,
        property: true,
        maintenance: true,
        notification: true,
      },
    };
  },
  props: {
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PhoneTextField,
    TagAutoComplete,
    CreateUpdateTemplate,
    ManageCategory,
    DatePicker,
    ManageBrand,
    Barcode,
    ManageEquipmentLocation,
  },
  watch: {
    assetTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.assetForm.validate();
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },

    /* customerId:{
      deep:true,
      immediate : true,
      handler(param) {
        if(param){
          this.filter_property(param)
        }
      }
    } */
  },
  methods: {
    dateDisable() {
      if (this.equipmentId > 0) {
        return true;
      } else {
        return false;
      }
    },
    updateFile(indexFile, file) {
      if (file && file.name) {
        this.wtx_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.wtx_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.wtx_attachments[indexFile].name = null;
        this.wtx_attachments[indexFile].suffix = null;
      }
    },
    checkType() {
      if (this.asset.main_type == "main_company") {
        this.asset.asset_type = "company";
      } else {
        this.asset.asset_type = "personal";
      }
    },
    productData(product) {
      if (product > 0) {
        let filtered_product = this.lodash.filter(this.products, {
          id: product,
        });
        this.asset.name = filtered_product[0].name;
        this.asset.brand = filtered_product[0].brand;
        this.asset.serial_no = filtered_product[0].barcode;
        this.asset.category = filtered_product[0].category;
        this.asset.warranty_duration_type = filtered_product[0].duration_time;
        this.asset.warranty_duration = filtered_product[0].warranty_check;
         this.show_name = filtered_product[0].name;
         this.show_serial_no = filtered_product[0].barcode;
         this.show_category  = filtered_product[0].category;
      }
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    /*   new_equipment() {
      this.new_equipment_dailog = true;
      this.filter_property();
    }, */

    get_Equipment() {
      this.$store
        .dispatch(GET, { url: `equipment/${this.equipmentId}` })
        .then(({ data }) => {
          (this.updatedImg = data.image ? data.image : null),
            (this.asset.asset_type = data.type ? data.type : null);
          this.asset.name = data.name ? data.name : null;
          this.asset.barcode = data.barcode ? data.barcode : null;
          this.barcode = data.barcode;
          this.asset.main_type = data.main_type ? data.main_type : null;
          this.asset.end_life = data.end_life ? data.end_life : null;
          this.asset.company_number = data.company_number
            ? data.company_number
            : null;
          this.asset.customer =
            data.customer && data.customer.id ? data.customer.id : 0;
          this.asset.property =
            data.property && data.property.id ? data.property.id : 0;
          this.filter_property(this.asset.customer);
          this.asset.brand = data.brand ? data.brand : null;
          this.asset.installation_date = data.installation_date
            ? data.installation_date
            : null;
          this.asset.activation_date = data.activation_date
            ? data.activation_date
            : null;
          this.asset.product = data.product ? data.product : 0;
          this.asset.category = data.category ? data.category : null;
          /*    this.asset.asset_image = data.asset_image ? data.asset_image : null; */
          this.asset.model = data.model ? data.model : null;
          this.asset.location =
            data.location && data.location.id
              ? this.lodash.toString(data.location.id)
              : null;

          this.asset.serial_no = data.serial_no ? data.serial_no : null;

          this.asset.check_warranty = data.has_warranty;
          this.changeWarrantyCheck(this.asset.check_warranty);
          this.asset.is_purchase_info = data.is_purchase_info
            ? data.is_purchase_info
            : 0;
          this.asset.purchased_on = data.purchased_on
            ? data.purchased_on
            : null;
          this.asset.cost_price = data.cost_price ? data.cost_price : 0;
          this.asset.vendor =
            data.supplier && data.supplier.id ? data.supplier.id : 0;

          this.asset.description = data.description ? data.description : null;
          this.asset.tags =
            data.tags /*  && data.tags.length ? data.tags : [] */;
          this.asset.warranty_start_date =
            data.warranty && data.warranty.start_date
              ? data.warranty.start_date
              : null;
          this.asset.warranty_end_date =
            data.warranty && data.warranty.end_date
              ? data.warranty.end_date
              : null;
          this.asset.warranty_duration_type =
            data.warranty && data.warranty.duration_type
              ? data.warranty.duration_type
              : null;
          this.asset.warranty_duration =
            data.warranty && data.warranty.duration
              ? data.warranty.duration
              : 0;
          this.asset.warranty_cost =
            data.warranty && data.warranty.cost ? data.warranty.cost : 0;

          this.asset.asset_image = data.image;
          if(data && data.product > 0){
            this.productData(data.product);
          }
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    get_options() {
      this.$store
        .dispatch(GET, { url: "equipment/options" })
        .then(({ data }) => {
          this.customerList = data.customers;
          this.supplierList = data.suppliers;
          this.all_property = data.properties;
          if (this.customerId && this.isCustomer) {
            this.filter_property(this.customerId);
          }
          this.brands = data.brands;
          this.categoryList = data.category;
          this.products = data.products;
          this.equipment_locations = data.equipment_locations;
          if (this.equipmentId == 0) {
            this.barcode = data.barcode;
          }
          if (this.equipmentId > 0) {
            this.get_Equipment();
          }
          /* this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.asset.customer,
          }); */
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    filter_property(param) {
      this.filtered_property = this.lodash.filter(this.all_property, {
        customer: param,
      });
    },
    manageReminder(index, type) {
      let reminderData = cloneDeep(this.asset.maintenance_reminder);
      let endDate = this.asset.maintenance_end_date;
      if (type == "warranty") {
        reminderData = cloneDeep(this.asset.warranty_reminder);
        endDate = this.asset.warranty_end_date;
      }
      if (
        reminderData[index] &&
        reminderData[index].duration &&
        reminderData[index].duration_type &&
        endDate
      ) {
        endDate = MomentJS(endDate, "YYYY-MM-DD");
        let reminderDate = null;
        if (reminderData[index].type == 0) {
          reminderDate = endDate.subtract(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        } else {
          reminderDate = endDate.add(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        }
        if (type == "warranty") {
          this.asset.warranty_end_date[index].date =
            reminderDate.format("YYYY-MM-DD");
        }
      }
    },
    addMoreWarranty() {
      this.wtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileWarranty(index) {
      this.wtx_attachments.splice(index, 1);
      if (this.wtx_attachments.length < 1) {
        this.addMoreWarranty();
      }
    },
    addMoreMaintenance() {
      this.mtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileMaintenance(index) {
      this.mtx_attachments.splice(index, 1);
      if (this.mtx_attachments.length < 1) {
        this.addMoreWarranty();
      }
    },
    updateProfileImage(param) {
      const _this = this;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          _this.asset.asset_image =
            data && data.file && data.file.url ? data.file.url : null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    addMoreWarrantyReminder() {
      this.asset.warranty_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    addMoreMaintenanceReminder() {
      this.asset.maintenance_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    removeWarrantyReminder(index) {
      this.asset.warranty_reminder.splice(index, 1);
      if (this.asset.warranty_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    removeMaintenanceReminder(index) {
      this.asset.maintenance_reminder.splice(index, 1);
      if (this.asset.maintenance_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    updateWarrantyDuration(e, type) {
      let warranty_start_date = e;
      if (type != "start_date") {
        warranty_start_date =
          this.asset && this.asset.warranty_start_date
            ? this.asset.warranty_start_date
            : null;
      }
      if (warranty_start_date) {
        let { warranty_duration_type, warranty_duration } = this.asset;
        let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
        let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
        switch (warranty_duration_type) {
          case "day":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.asset.warranty_end_date = date.format("YYYY-MM-DD");
      }
    },

    changeWarrantyCheck(data) {
      let warrantyIndex = findIndex(this.tabs, (row) => row.key == "warranty");
      this.tabs[warrantyIndex].status = data;
    },
    manageLimit(e) {
      if (this.asset.description && this.asset.description.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.asset.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.asset.description = trimValue;
        e.preventDefault();
      }
    },

    async updateOrCreate() {
      const _this = this;
      const formErrors = _this.validateForm(_this.$refs.assetForm);

      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }

      if (!_this.$refs.assetForm.validate()) {
        return false;
      }

      if (this.asset.check_warranty && !this.asset.warranty_start_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty start date is required")
        );
        return false;
      }
      if (this.asset.check_warranty && !this.asset.warranty_end_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty end date is required")
        );
        return false;
      }
      /* let attachment = filter(_this.mtx_attachments, function(row){
        console.log(row);
        return row;
      }); */

      let formData = new FormData();
      formData.append("type", this.asset.asset_type);
      if (this.asset.main_type == "main_company") {
        formData.append("customer", 0);
        formData.append("property", 0);
      }
      if (this.asset.main_type == "main_customer") {
        formData.append(
          "customer",
          this.asset.customer ? this.asset.customer : this.customerId
        );
        formData.append(
          "property",
          this.asset.property ? this.asset.property : 0
        );
      }
      formData.append("name", this.asset.name ? this.asset.name : 0);
      formData.append("barcode", this.barcode ? this.barcode : null);
      formData.append(
        "serial_no",
        this.asset.serial_no ? this.asset.serial_no : null
      );
      if (this.asset && this.asset.category) {
        formData.append(
          "category",
          this.asset.category ? this.asset.category : null
        );
      }
      if (this.asset && this.asset.installation_date) {
        formData.append(
          "installation_date",
          this.asset.installation_date ? this.asset.installation_date : null
        );
      }
      if (this.asset && this.asset.activation_date) {
        formData.append(
          "activation_date",
          this.asset.activation_date ? this.asset.activation_date : null
        );
      }
      /*  if (this.asset && this.asset.main_type && !this.isCustomer) { */
      formData.append(
        "main_type",
        this.asset.main_type ? this.asset.main_type : null
      );
      /*   } */
      if (this.asset && this.asset.end_life) {
        formData.append(
          "end_life",
          this.asset.end_life ? this.asset.end_life : null
        );
      }
      if (this.asset && this.asset.company_number) {
        formData.append(
          "company_number",
          this.asset.company_number ? this.asset.company_number : null
        );
      }
      if (this.asset && this.asset.cost) {
        formData.append("cost", this.asset.cost ? this.asset.cost : 0);
      }
      if (this.asset && this.asset.location) {
        formData.append(
          "location",
          this.asset.location ? this.asset.location : 0
        );
      }
      if (this.asset && this.asset.product) {
        formData.append("product", this.asset.product ? this.asset.product : 0);
      }
      if (this.asset && this.asset.model) {
        formData.append("model", this.asset.model ? this.asset.model : null);
      }
      if (this.asset && this.asset.asset_image) {
        formData.append("asset_image", this.asset.asset_image);
      }

      if (this.asset && this.asset.brand) {
        formData.append("brand", this.asset.brand ? this.asset.brand : null);
      }
      if (this.asset && this.asset.description) {
        formData.append(
          "description",
          this.asset.description ? this.asset.description : null
        );
      }
      if (this.asset && this.asset.check_warranty) {
        formData.append("check_warranty", this.asset.check_warranty ? 1 : 0);
      }
      if (this.asset && this.asset.is_purchase_info) {
        formData.append(
          "is_purchase_info",
          this.asset.is_purchase_info ? 1 : 0
        );
      }
      if (this.asset && this.asset.is_purchase_info && this.asset.cost_price) {
        formData.append(
          "cost_price",
          this.asset.cost_price ? this.asset.cost_price : 0
        );
      }
      if (
        this.asset &&
        this.asset.is_purchase_info &&
        this.asset.purchased_on
      ) {
        formData.append(
          "purchased_on",
          this.asset.purchased_on ? this.asset.purchased_on : null
        );
      }
      if (this.asset && this.asset.is_purchase_info && this.asset.vendor) {
        formData.append("vendor", this.asset.vendor ? this.asset.vendor : 0);
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration
      ) {
        formData.append(
          "warranty_duration",
          this.asset.warranty_duration ? this.asset.warranty_duration : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration_type
      ) {
        formData.append(
          "warranty_duration_type",
          this.asset.warranty_duration_type
            ? this.asset.warranty_duration_type
            : null
        );
      }

      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_start_date
      ) {
        formData.append(
          "warranty_start_date",
          this.asset.warranty_start_date ? this.asset.warranty_start_date : null
        );
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_end_date
      ) {
        formData.append(
          "warranty_end_date",
          this.asset.warranty_end_date ? this.asset.warranty_end_date : null
        );
      }
      if (this.asset && this.asset.check_warranty && this.asset.warranty_cost) {
        formData.append(
          "warranty_cost",
          this.asset.warranty_cost ? this.asset.warranty_cost : 0
        );
      }

      if (
        this.asset &&
        this.asset.tags &&
        this.asset.tags.length &&
        this.asset.tags.length > 0
      ) {
        for (let i = 0; i < this.asset.tags.length; i++) {
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].type
          ) {
            formData.append(`tags[${i}][type]`, this.asset.tags[i].type);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].color
          ) {
            formData.append(`tags[${i}][color]`, this.asset.tags[i].color);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].text
          ) {
            formData.append(`tags[${i}][text]`, this.asset.tags[i].text);
          }
        }
      }
      if (
        this.asset &&
        this.asset.warranty_reminder &&
        this.asset.warranty_reminder.length &&
        this.asset.warranty_reminder.length > 0
      ) {
        for (let i = 0; i < this.asset.warranty_reminder.length; i++) {
          formData.append(
            `warranty_reminder[${i}][type]`,
            this.asset.warranty_reminder[i].type
              ? this.asset.warranty_reminder[i].type
              : 0
          );

          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration
          ) {
            formData.append(
              `warranty_reminder[${i}][duration]`,
              this.asset.warranty_reminder[i].duration
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration_type
          ) {
            formData.append(
              `warranty_reminder[${i}][duration_type]`,
              this.asset.warranty_reminder[i].duration_type
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].reminder_type
          ) {
            formData.append(
              `warranty_reminder[${i}][reminder_type]`,
              this.asset.warranty_reminder[i].reminder_type
            );
          }
        }
      }

      if (
        this.wtx_attachments &&
        this.wtx_attachments.length &&
        this.wtx_attachments.length > 0
      ) {
        for (let i = 0; i < this.wtx_attachments.length; i++) {
          if (
            this.wtx_attachments &&
            this.wtx_attachments[i] &&
            this.wtx_attachments[i].file
          ) {
            formData.append(`file[${i}][file]`, this.wtx_attachments[i].file);
            formData.append(`file[${i}][name]`, this.wtx_attachments[i].name);
          }
        }
      }

      let requestTYPE = POST;
      let requestURL = "";
      if (this.equipmentId > 0) {
        requestURL = `equipment-new/${this.equipmentId}`;
      } else {
        requestURL = "equipment-new";
      }
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then((data) => {
          this.forcePush = true;
          if (this.isCustomer) {
            _this.$emit("update:warranty", true);
            _this.$emit("close:warranty", true);
          }
          if (!this.isCustomer) {
            this.$router.push(
              this.getDefaultRoute("equipment", {
                params: { id: data.id },
              })
            );
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_options();
    this.$refs.assetForm.validate();
    if (this.equipmentId > 0) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "equipment", route: "equipment" },
        { title: "Update" },
      ]);
      /* this.get_Equipment(); */
    } else {
      this.changeWarrantyCheck(false);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "equipment", route: "equipment" },
        { title: "Create" },
      ]);
    }
  },
  created() {
    if (!this.isCreate) {
      this.equipmentId = this.lodash.toSafeInteger(this.$route.params.id);
    }
  },
  /*   beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  }, */
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  computed: {
    getProfileImage() {
      return this.asset.asset_image ? this.asset.asset_image : null;
      /*  return this.lodash.isEmpty(this.asset.asset_image) === false 
        ? this.asset.asset_image.file.url
        : null; */
    },
  },
};
</script>
