<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllTimesheets"
            @keydown.tab="getAllTimesheets"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllTimesheets"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllTimesheets"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="timesheetDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Timesheet</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="4"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Task #</th>
            <th class="simple-table-th" width="200">Member</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr :key="index" v-for="(data, index) in dataRows">
              <td class="simple-table-td">
                <template v-if="data.task">
                  <Barcode
                    route="task.detail"
                    :barcode="data.task.barcode"
                    :id="data.task.id"
                  ></Barcode>
                </template>
              </td>
              <td class="simple-table-td">
                <div v-if="lodash.isEmpty(data.user) === false" class="d-flex">
                  <v-tooltip
                    :key="index"
                    top
                    content-class="custom-top-tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        v-if="lodash.isEmpty(data.user) === false"
                      >
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="$assetAPIURL(data.user.profile_logo)"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <span>{{ data.user.full_name }}</span>
                  </v-tooltip>
                </div>
                <template v-else>
                  <em class="text--secondary">No Member Associated</em>
                </template>
              </td>
              <td class="simple-table-td" width="100">
                <div class="timesheet-listing">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Started At: </b>
                    <template v-if="data.started_at">{{
                      formatDate(data.started_at)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Started Yet</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Finished At: </b>
                    <template v-if="data.finished_at">{{
                      formatDate(data.finished_at)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Finished Yet</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Duration: </b>
                    <template v-if="data.duration"
                      >{{
                        lodash.toSafeInteger(data.duration / 60)
                      }}
                      Hrs</template
                    >
                    <template v-else
                      ><em class="text--secondary"
                        >No Logged Hours</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="200">
                <TableActivity
                  v-if="!lodash.isEmpty(data.added_by)"
                  :data="data"
                >
                  <template v-slot:display_name>
                    {{ data.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ data.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(data.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no timesheet at the moment.
              </p>
            </td>
          </tr>
        </tbody>
        <Dialog :commonDialog="timesheetDialog">
          <template v-slot:title>Create new Timesheet</template>
          <template v-slot:body>
            <v-container fluid class="pt-0" v-if="timesheetDialog">
              <v-form
                ref="timesheetForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="createTimesheet"
              >
                <v-row>
                  <v-col md="12" class="pt-0">
                    <table class="width-100">
                      <tr>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Start Date</label
                          >
                          <DatePicker
                            :pageLoading="pageLoading"
                            solo
                            :placeholder="'Start Date'"
                            v-on:update:date-picker="setStartDate"
                          ></DatePicker>
                        </td>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >End Date</label
                          >
                          <DatePicker
                            :pageLoading="pageLoading"
                            solo
                            :placeholder="'End Date'"
                            v-on:update:date-picker="setEndDate"
                          ></DatePicker>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Start Time</label
                          >
                          <TimePicker
                            :pageLoading="pageLoading"
                            solo
                            :placeholder="'Start Time'"
                            v-on:update:time-picker="setStartTime"
                          ></TimePicker>
                        </td>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >End Time</label
                          >
                          <TimePicker
                            :pageLoading="pageLoading"
                            solo
                            :placeholder="'End Time'"
                            v-on:update:time-picker="setEndTime"
                          ></TimePicker>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2" colspan="2">
                          <label class="font-weight-500 font-size-16"
                            >Task</label
                          >
                          <v-autocomplete
                            dense
                            color="cyan"
                            filled
                            :items="taskList"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model.trim="timesheetCreate.task"
                            label="Task"
                            solo
                            flat
                            item-color="cyan"
                            item-text="subject"
                            item-value="id"
                            hide-details
                            v-on:change="getTaskMembers"
                            :rules="[
                              validateRules.required(
                                timesheetCreate.task,
                                'Task'
                              ),
                            ]"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Task Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2" colspan="2">
                          <label class="font-weight-500 font-size-16"
                            >Member</label
                          >
                          <v-autocomplete
                            dense
                            color="cyan"
                            filled
                            :items="taskMemberList"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model.trim="timesheetCreate.member"
                            label="Member"
                            solo
                            flat
                            item-color="cyan"
                            item-text="full_name"
                            item-value="id"
                            hide-details
                            :rules="[
                              validateRules.required(
                                timesheetCreate.member,
                                'Member'
                              ),
                            ]"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Member Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2" colspan="2">
                          <label class="font-weight-500 font-size-16"
                            >Note</label
                          >
                          <editor v-model="timesheetCreate.description" />
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    v-on:click="timesheetDialog = false"
                    :disabled="pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || pageLoading"
                    :loading="pageLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="createTimesheet"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Dialog>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY, GET, POST } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import TimePicker from "@/view/pages/partials/Timepicker.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timesheet-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      dataRows: [],
      dataLoading: true,
      timesheetDialog: false,
      pageLoading: true,
      taskList: [],
      taskMemberList: [],
      filter: {
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
      timesheetCreate: new Object({
        task: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        member: null,
        description: null,
      }),
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllTimesheets();
        }
      },
    },
    timesheetDialog(param) {
      if (param) {
        this.getTasks();
      }
    },
  },
  components: {
    Barcode,
    Dialog,
    DatePicker,
    TimePicker,
    TableActivity,
    ListingTable,
    editor: TinyMCE,
  },
  methods: {
    setStartDate(param) {
      this.timesheetCreate.start_date = param;
    },
    setEndDate(param) {
      this.timesheetCreate.end_date = param;
    },
    setStartTime(param) {
      this.timesheetCreate.start_time = param;
    },
    setEndTime(param) {
      this.timesheetCreate.end_time = param;
    },
    getTasks() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "tasks",
          data: {
            status: "all",
            type: "project",
            type_id: _this.parent,
          },
        })
        .then(({ data }) => {
          _this.taskList = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getTaskMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.timesheetCreate.task + "/members",
        })
        .then(({ data }) => {
          _this.taskMemberList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createTimesheet() {
      const _this = this;
      if (!_this.$refs.timesheetForm.validate()) {
        return false;
      }

      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.timesheetCreate.task + "/timesheets",
          data: {
            project: _this.parent,
            user: _this.timesheetCreate.member,
            started_at: moment(
              _this.timesheetCreate.start_date +
                " " +
                _this.timesheetCreate.start_time
            ).format("YYYY-MM-DD HH:MM"),
            finished_at: moment(
              _this.timesheetCreate.end_date +
                " " +
                _this.timesheetCreate.end_time
            ).format("YYYY-MM-DD HH:MM"),
            description: _this.timesheetCreate.description,
          },
        })
        .then(() => {
          _this.timesheetCreate = new Object({
            task: null,
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            member: null,
            description: null,
          });
          _this.getAllTimesheets();
          _this.timesheetDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllTimesheets();
    },
    getAllTimesheets() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: _this.type + "/" + _this.parent + "/timesheets",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
