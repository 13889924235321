import { toNumber } from "lodash";
import accounting from "accounting-js";

export const toBtxNumber = (param) => {
  let output = toNumber(param);
  if (isNaN(output) || !output) {
    return 0;
  }
  return output;
};

export const toBtxFixed = (param) => {
  let output = toBtxNumber(param);
  return accounting.toFixed(output);
};
