<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'contract-create'"
    v-if="getPermission('contract:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Contract For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refDialogOpen"
          >
            <!-- <template v-if="lodash.isEmpty(customer)">Client Name</template> -->
            <template v-if="lodash.isEmpty(customer)"
              ><label class="required">Client Name</label></template
            >
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-chip
        label
        color="chip-custom-blue"
        outlined
        class="text-white p-3"
        style="font-size: 21px !important; font-weight: 700"
        >{{ contractCreate.barcode }}</v-chip
      >
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate()"
      >
        Save as Draft
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate(_, 'mark_as_approved')"
      >
        Save and Approve
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="contractForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate()"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: calc(100vh - 185px); position: relative"
        >
          <div class="p-5 pt-0">
            <v-row>
              <v-col cols="12" v-if="getPermission('contract:create')">
                <v-container fluid>
                  <v-card flat class="remove-border-radius">
                    <v-card-text ref="overview" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="7" class="pb-0">
                          <div>
                            <label
                              class="font-weight-600 font-size-18 ml-2 required"
                              >Contract Title</label
                            >
                            <v-text-field
                              v-model.trim="contractCreate.job_title"
                              dense
                              filled
                              label="Title"
                              solo
                              flat
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              :rules="[
                                validateRules.required(
                                  contractCreate.job_title,
                                  'Contract Title'
                                ),
                                validateRules.minLength(
                                  contractCreate.job_title,
                                  'Contract Title',
                                  1
                                ),
                                validateRules.maxLength(
                                  contractCreate.job_title,
                                  'Contract Title',
                                  100
                                ),
                              ]"
                            ></v-text-field>
                            <v-textarea
                              v-model.trim="contractCreate.description"
                              auto-grow
                              dense
                              filled
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              color="cyan"
                              label="Description"
                              solo
                              flat
                              :rules="[
                                validateRules.minLength(
                                  contractCreate.description,
                                  'Description',
                                  1
                                ),
                                validateRules.maxLength(
                                  contractCreate.description,
                                  'Description',
                                  1024
                                ),
                              ]"
                              row-height="25"
                              v-on:keypress="(e) => descriptionmanageLimit(e)"
                              v-on:paste="(e) => descriptiononPaste(e)"
                            ></v-textarea>
                            <div class="d-flex justify-end">
                              {{
                                contractCreate.description
                                  ? contractCreate.description.length
                                  : 0
                              }}/200
                            </div>
                          </div>

                          <!-- <v-layout class="my-4">
                            <v-flex md6 class="mr-2 custom-border-right">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Service Location</label>
                                    <v-icon
                                      v-if="!contractId"
                                      class="ml-3"
                                      v-on:click="customerPropertyDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="routeToServiceHistory()"
                                      color="cyan"
                                      small
                                      >mdi-history</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      property.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      label
                                      x-small
                                      color="green"
                                      outlined
                                      class="ml-3"
                                      v-if="property.is_tenant"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      v-if="false"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'property';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="property_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_phone"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr
                                  v-if="property_contact_person.primary_email"
                                >
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                contractCreate.property_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              contractCreate.property_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                            <v-flex md6 class="ml-2">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Billing Address</label>
                                    <v-icon
                                      v-if="!contractId"
                                      class="ml-3"
                                      v-on:click="customerBillingDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label>{{
                                      billing.property_address
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-icon
                                      v-if="!contractId"
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'billing';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_phone">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_phone
                                    }}</label>
                                  </td>
                                </tr>
                                <tr v-if="billing_contact_person.primary_email">
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.primary_email
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                contractCreate.billing_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              contractCreate.billing_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                          </v-layout> -->

                          <v-layout class="my-4 bg-quo">
                            <v-flex md6 class="ml-2">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Billing Address</label>

                                    <v-icon
                                      v-if="customer && customer.id"
                                      class="ml-3"
                                      v-on:click="customerBillingDialog = true"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <template v-if="billing.property_address">
                                      <!-- <div
                                        style="
                                          font-weight: 600 !important;
                                          color: black;
                                        "
                                      >
                                        {{ billing.property_name }}
                                      </div> -->
                                      <label>
                                        <label
                                          v-if="
                                            billing && billing.property_address
                                          "
                                          >{{ billing.street_1 }},
                                          <br
                                            v-if="
                                              billing.street_2 ||
                                              billing.unit_no
                                            "
                                          />
                                          <template v-if="billing.street_2">
                                            {{ billing.street_2 }},
                                          </template>
                                          <template v-if="billing.unit_no">
                                            {{ billing.unit_no }},
                                          </template>
                                          <br />
                                          {{ billing.country }},
                                          {{ billing.zip_code }}
                                        </label>
                                        <!-- {{
                                        billing.property_address
                                      }} --></label
                                      >
                                    </template>
                                    <template v-else
                                      ><em class="text-muted">
                                        no billing address</em
                                      ></template
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="
                                        billing_contact_person &&
                                        billing_contact_person.tenant
                                      "
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'billing';
                                        customerPersonDialog = true;
                                      "
                                      v-if="customer && customer.id"
                                      color="cyan"
                                      small
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr
                                  v-if="
                                    billing_contact_person &&
                                    billing_contact_person.display_name
                                  "
                                >
                                  <td class="py-0">
                                    <label>{{
                                      billing_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label
                                      v-if="
                                        billing_contact_person &&
                                        billing_contact_person.primary_phone
                                      "
                                      >{{
                                        billing_contact_person.primary_phone
                                      }}</label
                                    >
                                    <em v-else class="text-muted">
                                      no phone number</em
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label
                                      v-if="
                                        billing_contact_person &&
                                        billing_contact_person.primary_email
                                      "
                                      >{{
                                        billing_contact_person.primary_email
                                      }}</label
                                    >
                                    <em v-else class="text-muted"> no email</em>
                                  </td>
                                </tr>
                                <tr v-if="false && !quotationId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                contractCreate.billing_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              contractCreate.billing_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                            <v-flex md6 class="mr-2 custom-border-right">
                              <table width="100%">
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Site Location</label>
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="customerPropertyDialog = true"
                                      color="cyan"
                                      small
                                      v-if="customer && customer.id"
                                      >mdi-pencil</v-icon
                                    >
                                    <!-- <v-icon
                                      class="ml-3"
                                      v-on:click="routeToServiceHistory()"
                                      v-if="customer && customer.id"
                                      color="cyan"
                                      small
                                      >mdi-history</v-icon
                                    > -->
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <template v-if="property.property_address">
                                      <!--  <div
                                        style="
                                          font-weight: 600 !important;
                                          color: black;
                                        "
                                      >
                                        {{ property.property_name }}
                                      </div> -->
                                      <label>
                                        <label
                                          v-if="
                                            property &&
                                            property.property_address
                                          "
                                          >{{ property.street_1 }},
                                          <br
                                            v-if="
                                              property.street_2 ||
                                              property.unit_no
                                            "
                                          />
                                          <template v-if="property.street_2">
                                            {{ property.street_2 }},
                                          </template>
                                          <template v-if="property.unit_no">
                                            {{ property.unit_no }},
                                          </template>
                                          <br />
                                          {{ property.country }},
                                          {{ property.zip_code }}
                                        </label>
                                        <!-- {{
                                        property.property_address
                                      }} --></label
                                      >
                                    </template>
                                    <template v-else
                                      ><em class="text-muted">
                                        no Site Location</em
                                      ></template
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="font-weight-600">
                                    <label>Contact details</label>
                                    <v-chip
                                      v-if="property_contact_person.tenant"
                                      small
                                      color="green white--text"
                                      label
                                      class="ml-2 tenant-small-chip"
                                      >Tenant</v-chip
                                    >
                                    <v-icon
                                      class="ml-3"
                                      v-on:click="
                                        person_type = 'property';
                                        customerPersonDialog = true;
                                      "
                                      color="cyan"
                                      small
                                      v-if="customer && customer.id"
                                      >mdi-pencil</v-icon
                                    >
                                  </td>
                                </tr>
                                <tr v-if="property_contact_person.display_name">
                                  <td class="py-0">
                                    <label>{{
                                      property_contact_person.display_name
                                    }}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label
                                      v-if="
                                        property_contact_person.primary_phone
                                      "
                                      >{{
                                        property_contact_person.primary_phone
                                      }}</label
                                    >
                                    <em v-else class="text-muted">
                                      no phone number</em
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td class="py-0">
                                    <label
                                      v-if="
                                        property_contact_person.primary_email
                                      "
                                      >{{
                                        property_contact_person.primary_email
                                      }}</label
                                    >
                                    <em v-else class="text-muted"> no email</em>
                                  </td>
                                </tr>
                                <tr v-if="false && !quotationId">
                                  <td>
                                    <v-layout>
                                      <v-flex
                                        ><label class="font-weight-600">
                                          Email Notification
                                        </label></v-flex
                                      >
                                      <v-flex>
                                        <v-tooltip
                                          top
                                          content-class="custom-top-tooltip"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-switch
                                              class="m-0 p-0"
                                              color="cyan"
                                              dense
                                              v-model="
                                                contractCreate.property_person_notify
                                              "
                                              inset
                                            ></v-switch>
                                          </template>
                                          <span
                                            v-if="
                                              contractCreate.property_person_notify ==
                                              1
                                            "
                                            >Send Notification</span
                                          >
                                          <span v-else>No Notification</span>
                                        </v-tooltip>
                                      </v-flex>
                                    </v-layout>
                                  </td>
                                </tr>
                                <!--  <tr>
                                  <td>
                                    <div class="d-flex">
                                      <label class="font-weight-600">
                                        Delivery Site (Service Locaiton As
                                        Default)
                                      </label>

                                      <v-checkbox
                                        color="cyan"
                                        hide-details
                                        v-model="checkdeliverysite"
                                        class="m-0 p-0"
                                      ></v-checkbox>
                                    </div>
                                  </td>
                                </tr> -->
                                <tr v-if="false">
                                  <td>
                                    <div class="d-flex">
                                      <template v-if="checkdeliverysite">
                                        <v-textarea
                                          v-model.trim="
                                            contractCreate.delivery_site
                                          "
                                          auto-grow
                                          dense
                                          filled
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          color="cyan"
                                          label="Delivery Site"
                                          solo
                                          flat
                                          row-height="25"
                                          :rules="[
                                            validateRules.required(
                                              contractCreate.delivery_site,
                                              'Delivery Site'
                                            ),
                                            validateRules.minLength(
                                              contractCreate.delivery_site,
                                              'Delivery Site',
                                              1
                                            ),
                                            validateRules.maxLength(
                                              contractCreate.delivery_site,
                                              'Delivery Site',
                                              100
                                            ),
                                          ]"
                                        ></v-textarea>

                                        <!-- <v-text-field
                                          label="Delivery Site"
                                          dense
                                          filled
                                          solo
                                          flat
                                          :disabled="pageLoading"
                                          color="cyan"
                                          v-model="
                                            contractCreate.delivery_site
                                          "
                                        ></v-text-field> -->
                                      </template>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </v-flex>
                          </v-layout>
                        </v-col>
                        <v-col cols="5" class="pb-0">
                          <table width="100%">
                            <!--  <tr>
                              <td width="150" class="font-weight-600">
                                <label>Contract number</label>
                              </td>
                              <td class="font-weight-700">
                                <label>{{ contractCreate.barcode }}</label>
                              </td>
                            </tr> -->
                            <!--  <tr>
                              <td width="150" class="font-weight-600">
                                <label>Rate opportunity</label>
                              </td>
                              <td class="font-weight-700">
                                <v-rating
                                  :readonly="pageLoading"
                                  v-model.trim="contractCreate.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating>
                              </td>
                            </tr> -->
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="reference-no">Reference #</label>
                              </td>
                              <td>
                                <v-text-field
                                  id="reference-no"
                                  v-model.trim="contractCreate.reference"
                                  dense
                                  filled
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  color="cyan"
                                  label="Reference #"
                                  :rules="[
                                    validateRules.minLength(
                                      contractCreate.reference,
                                      'Reference',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      contractCreate.reference,
                                      'Reference',
                                      100
                                    ),
                                  ]"
                                  solo
                                  flat
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="sales-representatives"
                                  >Sales representatives</label
                                >
                              </td>
                              <td>
                                <v-autocomplete
                                  dense
                                  color="cyan"
                                  filled
                                  id="sales-representatives"
                                  :items="salesExecutiveList"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  v-model.trim="contractCreate.sales_executive"
                                  label="Sales representatives"
                                  solo
                                  flat
                                  item-color="cyan"
                                  item-text="full_name"
                                  item-value="id"
                                  hide-details
                                  v-on:change="updateSales()"
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="
                                            'No Sales Representative Found.'
                                          "
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </td>
                            </tr>
                            <tr>
                              <td width="150" class="font-weight-600">
                                <label for="prepared-by">Prepared by</label>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  filled
                                  id="prepared-by"
                                  color="cyan"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  :rules="[
                                    validateRules.minLength(
                                      contractCreate.prepared_by,
                                      'Prepared by',
                                      1
                                    ),
                                    validateRules.maxLength(
                                      contractCreate.prepared_by,
                                      'Prepared by',
                                      100
                                    ),
                                  ]"
                                  v-model.trim="contractCreate.prepared_by"
                                  label="Prepared by"
                                  solo
                                  flat
                                  hide-details
                                ></v-text-field>
                              </td>
                            </tr>
                            <tr v-if="false">
                              <td width="150" class="font-weight-600">
                                <label>Is Contract ?</label>
                              </td>
                              <td valign="middle">
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-switch
                                      class="m-0 my-2 p-0"
                                      color="cyan"
                                      dense
                                      v-model="contractCreate.is_contract"
                                      inset
                                      hide-details
                                    ></v-switch>
                                  </template>
                                  <span>Contract ?</span>
                                </v-tooltip>
                              </td>
                            </tr>
                            <template v-if="contractCreate.is_contract">
                              <tr>
                                <td width="150" class="font-weight-600">
                                  <label for="duration-value">Duration</label>
                                </td>
                                <td>
                                  <v-layout>
                                    <v-flex md6>
                                      <v-text-field
                                        dense
                                        filled
                                        id="duration-value"
                                        color="cyan"
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        v-mask="'###'"
                                        v-model.trim="
                                          contractCreate.duration_value
                                        "
                                        label="Duration"
                                        solo
                                        flat
                                        hide-details
                                        v-on:change="durationOnChnage"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                      <v-select
                                        dense
                                        color="cyan"
                                        filled
                                        id="duration-type"
                                        :items="durationTypeList"
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        v-model.trim="
                                          contractCreate.duration_type
                                        "
                                        v-on:change="
                                          updateDuration(
                                            contractCreate.contract_start
                                          )
                                        "
                                        label="Duration"
                                        solo
                                        flat
                                        item-color="cyan"
                                        hide-details
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                v-html="
                                                  'No Duration Type Found.'
                                                "
                                              ></v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-select>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                              <tr>
                                <td width="150" class="font-weight-600">
                                  <label for="start-date-picker">Dates</label>
                                </td>
                                <td>
                                  <v-layout>
                                    <v-flex md6
                                      ><DatePicker
                                        solo
                                        flat
                                        :default-date="
                                          contractCreate.contract_start
                                        "
                                        v-model="contractCreate.contract_start"
                                        v-on:change="updateDuration($event)"
                                      ></DatePicker
                                    ></v-flex>

                                    <v-flex md6>
                                      <DatePicker
                                        solo
                                        flat
                                        disabled
                                        :default-date="
                                          contractCreate.contract_end
                                        "
                                        v-model="contractCreate.contract_end"
                                      ></DatePicker>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                              <tr v-if="false">
                                <td width="150" class="font-weight-600">
                                  <label for="duration-picker">Duration</label>
                                </td>
                                <td>
                                  <DateRangePicker
                                    prop_id="duration-picker"
                                    :page-loading="pageLoading"
                                    prop_label="Duration"
                                    :prop_dates="contractCreate.duration"
                                    v-model="contractCreate.duration"
                                  ></DateRangePicker>
                                </td>
                              </tr>
                              <tr>
                                <td width="150" class="font-weight-600">
                                  <label for="no-of-services" class="required"
                                    >No. of Services</label
                                  >
                                </td>
                                <td>
                                  <v-text-field
                                    dense
                                    filled
                                    id="no-of-services"
                                    color="cyan"
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    v-mask="'###'"
                                    :rules="[
                                      validateRules.required(
                                        contractCreate.total_service,
                                        'No. of Services'
                                      ),
                                    ]"
                                    v-model.trim="contractCreate.total_service"
                                    label="No. of Services"
                                    solo
                                    flat
                                    hide-details
                                  ></v-text-field>
                                </td>
                              </tr>
                              <tr v-if="false">
                                <td width="150" class="font-weight-600 d-flex">
                                  <label for="no-of-services">Remark</label>
                                </td>
                                <td>
                                  <v-textarea
                                    v-model="contractCreate.remark"
                                    auto-grow
                                    dense
                                    filled
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    color="cyan"
                                    label="Remark"
                                    solo
                                    flat
                                    row-height="25"
                                    v-on:keypress="(e) => manageLimit(e)"
                                    v-on:paste="(e) => onPaste(e)"
                                  ></v-textarea>
                                  <div class="d-flex justify-end">
                                    {{
                                      contractCreate.remark
                                        ? contractCreate.remark.length
                                        : 0
                                    }}/200
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col
                cols="12"
                v-if="false && getPermission('line-item:create')"
              >
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-card-text ref="lineItem" class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="12" class="pt-0 pb-0">
                          <ContractLineItemNew
                            is-contract
                            can-update
                            :entity-id="entityId"
                            :property-id="contractProperty"
                            :currency="contractCreate.currency"
                            :db-line-items="dbLineItems"
                            :db-equipments="dbEquipments"
                            :discount-value="contractCreate.discount_value"
                            :discount-value-type="
                              contractCreate.discount_value_type
                            "
                            :apply-tax="contractCreate.tax_applied"
                            :adjustment-value="contractCreate.adjustment"
                            v-on:update:equipment="updateEquipment($event)"
                            v-on:update:line-item="updateLineItem($event)"
                            v-on:update:line-item-calculation="
                              updateLineItemCalculation($event)
                            "
                          ></ContractLineItemNew>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12">
                <!-- <v-col cols="6">
                  <QuoteTermsConditions
                    :update-data="updateData"
                    :page-loading="pageLoading"
                    :quotationId="contractId"
                    moduleType="quotation"
                  ></QuoteTermsConditions>
                </v-col> -->

                <!-- <ContractTermsConditions
                  v-if="false"
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></ContractTermsConditions> -->
              </v-col>
              <v-col cols="12">
                <TermsConditions
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></TermsConditions>
              </v-col>
              <v-col cols="12">
                <NotesAttachment
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></NotesAttachment>
              </v-col>
              <v-col cols="12" v-if="false">
                <ContractNotesAttachment
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></ContractNotesAttachment>
              </v-col>
              <v-col cols="12" v-if="false">
                <ContractTermsConditions
                  :update-data-term="updateData"
                  :page-loading="pageLoading"
                  :quotationId="contractId"
                  moduleType="contract"
                ></ContractTermsConditions>
              </v-col>
              <v-col cols="12" v-if="false">
                <ContractImageAttachment
                  :update-data="updateData"
                  :page-loading="pageLoading"
                ></ContractImageAttachment>
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-form>
      <template v-if="refEntityId && refCustomerDialog">
        <RefCustomerDialog
          :entity="refEntityId"
          :customer-dialog="refCustomerDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomer="refSelectCustomer"
        ></RefCustomerDialog>
      </template>
      <template v-if="refCustomerPropertyDialog">
        <RefCustomerPropertyDialog
          disabled-auto-select
          :customer-property-dialog="refCustomerPropertyDialog"
          :customer="refCustomerId"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectCustomerProperty="refSelectCustomerProperty"
        ></RefCustomerPropertyDialog>
      </template>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          disabled-auto-select
          :customerPersonDialog="customerPersonDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          disabled-auto-select
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
      <template v-if="customerBillingDialog">
        <CustomerBillingDialog
          disabled-auto-select
          :customerBillingDialog="customerBillingDialog"
          :customer="customer.id"
          v-on:close="closeDialog"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerBillingDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { QUERY, POST, PUT } from "@/core/services/store/request.module";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import ContractLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";

import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { TermConditionOptionsEventBus } from "@/core/lib/term.condition.lib";
import TermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import NotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
//import ContractTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
//import QuoteTermsConditions from "@/view/pages/partials/Quote-Terms-Conditions.vue";
import ContractTermsConditions from "@/view/pages/partials/Contract-Terms-Conditions.vue";
import ContractNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import ContractImageAttachment from "@/view/pages/partials/Image-Attachment.vue";
import {
  /* concat, */ /*  compact, */ filter,
  isEmpty,
  toSafeInteger /* , map */,
} from "lodash";
import RefCustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import RefCustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "contract-create",
  title: "Create Contract",
  data() {
    return {
      isDupChanged: 0,
      refEntityId: 1,

      refCustomerId: 0,
      refPropertyId: 0,
      refCustomerDialog: false,
      refCustomerPropertyDialog: false,
      entityId: 1,
      formLoading: false,
      pageLoading: false,
      contractCreated: false,
      lineItemCreated: false,
      customer: {},
      property: {},
      terms_condition: null,
      billing: {},
      barcodeSetting: {},
      contractCreate: {
        job_title: null,
        description: null,
        property_person_notify: 1,
        billing_person_notify: 1,
        rating: 1,
        reference: null,
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        sales_executive: null,
        prepared_by: null,
        is_contract: true,
        duration: null,
        total_service: null,
        remark: null,
        discount_value: null,
        discount_value_type: null,
        tax_applied: null,
        adjustment: null,
        sales: null,
        terms: {
          quote: null,
          validity: null,
          /*  delivery: null,
          warranty: null,
          payment: null,
          gst_charges: null,
          deposit: null,
          cancellation: null,
          stock_availability: null, */
        },
      },
      durationTypeList: [
        { text: "Days", value: "days" },
        { text: "Month", value: "month" },
        { text: "Year", value: "year" },
      ],
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      person_type: null,
      ticketId: null,
      visitId: null,
      invoiceId: null,
      contractId: null,
      property_contact_person: {},
      billing_contact_person: {},
      salesExecutiveList: [],
      lineItem: [],
      equipments: [],
      dbLineItems: [],
      dbEquipments: [],
      noteAttachment: {},
      termsCondition: null,
      lineItemCalculation: {
        taxApplied: 0,
        discountValue: 0,
        adjustmentAmount: 0,
        discountType: 1,
        discountValueType: 0,
      },
      reviseContract: 0,
      updateData: {
        term_conditions: null,
        admin_remark: "",
        client_remark: "",
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
      },
    };
  },
  components: {
    RefCustomerDialog,
    ContractImageAttachment,
    RefCustomerPropertyDialog,
    DatePicker,
    DateRangePicker,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
    ContractLineItemNew,
    CreateUpdateTemplate,
    ContractTermsConditions,
    TermsConditions,
    NotesAttachment,
    // QuoteTermsConditions,
    ContractNotesAttachment,
  },
  methods: {
    refDialogOpen() {
      if (!this.contractId) {
        this.refCustomerDialog = true;
      } else {
        return false;
      }
    },
    refCloseDialog() {
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refCustomerDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },
    refSelectCustomer(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.refCustomerDialog = false;
        this.refCustomerPropertyDialog = true;
      });
    },

    /* refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      let route = this.getDefaultRoute("contract.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
          isDupChanged: 1,
        },
      });
      const resolved = this.$router.resolve(route);
      console.log(resolved, "resolved");
      window.location.href = resolved.href;
    }, */

    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      /*   let route =  */ this.getDefaultRoute("contract.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
        },
      });

      this.duplicateContract = ObjectPath.get(this.$route, "query.duplicate");

      (this.contractProperty = this.refPropertyId),
        (this.contractCustomer = this.refCustomerId),
        (this.contractBilling = 0),
        (this.contractPropertyPerson = 0),
        (this.contractBillingPerson = 0),
        this.pushToRouteContract();
      this.closeDialog();
      this.refCustomerPropertyDialog = false;
      /* const resolved = this.$router.resolve(route);
      window.location.href = resolved.href; */
    },

    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.contractCreate.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.contractCreate.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.contractCreate.remark &&
        this.contractCreate.remark.length > 199
      ) {
        e.preventDefault();
      }
    },

    descriptiononPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.contractCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.contractCreate.description = trimValue;
        e.preventDefault();
      }
    },

    descriptionmanageLimit(e) {
      if (
        this.contractCreate.description &&
        this.contractCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },

    durationOnChnage() {
      if (this.contractCreate.duration_value < 1) {
        this.contractCreate.duration_value = 1;
      }
      this.updateDuration(this.contractCreate.contract_start);
    },

    updateDuration(contract_start) {
      this.contractCreate.contract_start = contract_start;

      this.$nextTick(() => {
        const { duration_value, duration_type } = this.contractCreate;

        let output = moment(contract_start).add(duration_value, duration_type);
        this.contractCreate.contract_end = moment(output)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      });

      /* if (duration_type == "days" && duration_value != "") {
        this.contractCreate.contract_end = moment(contract_start).add(duration_value - 1, duration_type).format("YYYY-MM-DD");
      } else {
        this.contractCreate.contract_end = moment(contract_start).add(duration_value, duration_type).format("YYYY-MM-DD");;
      } */
    },
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateEquipment(rows) {
      this.equipments = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = row.apply_tax;
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },
    updateSales() {
      const sales_executive = find(this.salesExecutiveList, {
        id: this.contractCreate.sales_executive,
      });
      if (sales_executive) {
        this.contractCreate.sales = sales_executive.full_name;
      }
    },
    routeToServiceHistory() {
      this.forcePush = true;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("property.detail", {
            params: { id: this.property.id },
            query: {
              tab: "property-history",
            },
          })
        );
      });
    },
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.contractPropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.contractPropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.contractBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.contractBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteContract();
    },
    selectCustomerProperty(param) {
      if (this.contractProperty == param) {
        this.closeDialog();
        return false;
      }
      this.contractProperty = param;
      this.closeDialog();
      this.pushToRouteContract();
    },
    selectCustomerBilling(param) {
      if (this.contractBilling == param) {
        this.closeDialog();
        return false;
      }
      this.contractBilling = param;
      this.closeDialog();
      this.pushToRouteContract();
    },
    pushToRouteContract() {
      let routeName = "";
      if (this.contractId && this.contractId > 0) {
        routeName = "contract.update";
      } else {
        routeName = "contract.create";
      }
      this.$router
        .replace(
          this.getDefaultRoute(routeName, {
            query: {
              customer: this.contractCustomer,
              billing: this.contractBilling,
              property: this.contractProperty,
              property_person: this.contractPropertyPerson,
              billing_person: this.contractBillingPerson,
              duplicate: this.duplicateContract,
              revise: this.reviseContract,
              ticket: this.ticketId,
              visit: this.visitId,
              t: new Date().getTime(),
            },
          })
        )
        .then(() => {
          this.getOptions();
        });
    },
    async updateOrCreate(type, statusapprove) {
      const _this = this;
      //console.log(statusapprove);
      const validateStatus = _this.$refs.contractForm.validate();

      const formErrors = _this.validateForm(_this.$refs.contractForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (_this.contractCreate.total_service == 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select Services then try again.")
        );
        return false;
      }
      if (_this.contractCustomer == 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select customer then try again.")
        );
        return false;
      }
      // const line_items = concat(_this.equipments, _this.lineItem);

      /* let validateLineItem = compact(
        map(line_items, function (row) {
          return row.product_id;
        })
      ); */

      /* if (isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service/equipment then try again.")
        );
        return false;
      } */

      if (!validateStatus) {
        return false;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "contract";

      if (_this.contractId && _this.contractId > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "contract/" + _this.contractId;
      }

      if (_this.reviseContract && _this.reviseContract > 0) {
        type = "revise";
        REQUEST_TYPE = PUT;
        REQUEST_URL = "contract/" + _this.reviseContract;
      }

      const formData = new Object({
        action: typeof type == "string" ? type : undefined,
        statusapprove: statusapprove ? statusapprove : null,
        ticket: _this.ticketId,
        visit: _this.visitId,
        invoice: _this.invoiceId,
        customer: _this.contractCustomer,
        billing: _this.contractBilling,
        property: _this.contractProperty,
        property_person: _this.contractPropertyPerson,
        billing_person: _this.contractBillingPerson,
        job_title: _this.contractCreate.job_title,
        description: _this.contractCreate.description,
        property_person_notify: toSafeInteger(
          _this.contractCreate.property_person_notify
        ),
        billing_person_notify: toSafeInteger(
          _this.contractCreate.billing_person_notify
        ),
        barcode: _this.contractCreate.barcode,
        reference: _this.contractCreate.reference,
        duration_value: _this.contractCreate.duration_value,
        duration_type: _this.contractCreate.duration_type,
        contract_start: _this.contractCreate.contract_start,
        contract_end: _this.contractCreate.contract_end,
        rating: _this.contractCreate.rating,
        sales_executive: _this.contractCreate.sales_executive,
        prepared_by: _this.contractCreate.prepared_by,
        is_contract: toSafeInteger(_this.contractCreate.is_contract),
        duration: _this.contractCreate.duration || [],
        total_service: toSafeInteger(_this.contractCreate.total_service),
        remark: _this.contractCreate.remark,
        sales: _this.contractCreate.sales,
        documents: _this.noteAttachment.documents,
        admin_remark: _this.noteAttachment.admin_notes,
        quote_terms: _this.terms,
        client_remark: _this.noteAttachment.client_notes,
        term_conditions: _this.terms_condition,
        additional_remarks: _this.additional_remarks,
        tax_applied: _this.lineItemCalculation.taxApplied,
        discount_value: _this.lineItemCalculation.discountValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
      });

      if (!_this.contractCreated) {
        try {
          const contract = await _this.$store.dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: formData,
          });

          if (!isEmpty(contract)) {
            _this.contractId = toSafeInteger(contract.data.id);
            _this.contractCreated = true;
            _this.forcePush = true;
          }
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Contract is not created. Please try again.")
          );
          return false;
        }
      }

      /*   if (!_this.lineItemCreated && _this.contractCreated && _this.contractId) {
        try {
          await _this.CreateLineItems({line_items
            type: "contract",
            parent: _this.contractId,
            formData: line_items,
          });

          _this.lineItemCreated = true;
        } catch (error) {
          _this.logError(error);
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not created. Please try again.")
          );
          return false;
        }
      } */

      //  _this.formLoading = false;

      _this.$nextTick(() => {
        _this.$router.push(
          _this.getDefaultRoute("contract", {
            query: {
              status: "all",
            },
          })
        );
      });
    },
    getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setUpdateData(param) {
      const _this = this;

      _this.contractCreate.job_title = param.job_title;
      _this.contractCreate.description = param.description;
      _this.contractCreate.property_person_notify =
        param.property_person_notify;
      _this.contractCreate.billing_person_notify = param.billing_person_notify;
      _this.contractCreate.rating = param.rating;
      _this.contractCreate.reference = param.reference;
      _this.contractCreate.prepared_by = param.prepared_by;
      _this.contractCreate.is_contract = true;
      _this.contractCreate.duration_value = param.duration_value;
      _this.contractCreate.duration_type = param.duration_type;
      _this.contractCreate.contract_start = param.contract_start;
      _this.contractCreate.contract_end = param.contract_end;
      _this.contractCreate.duration = param.duration;
      _this.contractCreate.total_service = param.total_service
        ? param.total_service
        : null;
      _this.contractCreate.remark = param.remark;
      _this.contractCreate.discount_value = param.discount_value;
      _this.contractCreate.discount_value_type = param.discount_value_type;
      _this.contractCreate.tax_applied = param.tax_applied;
      _this.contractCreate.adjustment = param.adjustment;
      _this.contractCreate.sales_executive = param.sales_representatives?.id;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.additional_remarks = param.additional_remarks;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = param.documents;
      /* if (!isEmpty(data.client_remark)) {
            this.updateData.client_remark = data.client_remark[0].value;
          }
          if (!isEmpty(data.options)) {
            this.updateData.term_conditions = data.options.terms_conditions;
          } */
      /*  _this.updateData.terms = {
        validity: param.additional_remarks,
        quote: param.quote,
        cancellation: param.cancellation,
        delivery: param.delivery,
        deposit: param.deposit,
        gst_charges: param.gst_charges,
        payment: param.payment,
        stock_availability: param.stock_availability,
        warranty: param.warranty,
      }; */

      /* const sales_executive = find(_this.salesExecutiveList, {
        full_name: param.sales,
      });
      if (sales_executive) {
        _this.contractCreate.sales = sales_executive.full_name;
        _this.contractCreate.sales_executive = sales_executive.id;
      } */

      _this
        .getLineItems({
          contract: _this.contractId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: data[i].id,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,

              threshold_price: data[i].threshold_price,
              project_price: data[i] ? data[i].project_price : 0,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setReviseData(param) {
      const _this = this;

      _this.contractCreate.job_title = param.job_title;
      _this.contractCreate.description = param.description;
      _this.contractCreate.property_person_notify =
        param.property_person_notify;
      _this.contractCreate.billing_person_notify = param.billing_person_notify;
      _this.contractCreate.rating = param.rating;
      _this.contractCreate.reference = param.reference;
      _this.contractCreate.sales_executive = param.sales_representatives?.id;
      _this.contractCreate.prepared_by = param.prepared_by;
      _this.contractCreate.is_contract = true;
      _this.contractCreate.duration_value = toSafeInteger(param.duration_value);
      _this.contractCreate.duration_type = param.duration_type;
      _this.contractCreate.contract_start = param.contract_start;
      _this.contractCreate.contract_end = param.contract_end;
      _this.updateDuration(this.contractCreate.contract_start);
      _this.contractCreate.duration = param.duration;
      _this.contractCreate.total_service = param.total_service;
      _this.contractCreate.remark = param.remark;
      _this.contractCreate.discount_value = param.discount_value;
      _this.contractCreate.discount_value_type = param.discount_value_type;
      _this.contractCreate.tax_applied = param.tax_applied;
      _this.contractCreate.adjustment = param.adjustment;
      _this.contractCreate.sales = param.sales;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.additional_remarks = param.additional_remarks;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      // _this.updateData.documents = [];
      _this.updateData.documents = param.documents;

      /* const sales_executive = find(_this.salesExecutiveList, {
        full_name: param.sales,
      }); */
      /* if (sales_executive) {
        _this.contractCreate.sales = sales_executive.full_name;
        _this.contractCreate.sales_executive = sales_executive.id;
      } */

      _this
        .getLineItems({
          contract: _this.reviseContract,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,

              threshold_price: data[i].threshold_price,
              project_price: data[i] ? data[i].project_price : 0,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setDuplicateData(param) {
      const _this = this;

      _this.contractCreate.job_title = param.job_title;
      _this.contractCreate.description = param.description;
      _this.contractCreate.property_person_notify =
        param.property_person_notify;
      _this.contractCreate.billing_person_notify = param.billing_person_notify;
      _this.contractCreate.rating = param.rating;
      _this.contractCreate.reference = `Contract # ${param.barcode}`;
      _this.contractCreate.sales_executive = param.sales_executive;
      _this.contractCreate.prepared_by = param.prepared_by;
      _this.contractCreate.is_contract = true; //param.is_contract;
      _this.contractCreate.duration_value = param.duration_value;
      _this.contractCreate.duration_type = param.duration_type;
      _this.contractCreate.contract_start = param.contract_start;
      _this.contractCreate.contract_end = param.contract_end;
      _this.contractCreate.duration = param.duration;
      _this.contractCreate.total_service = param.total_service;
      _this.contractCreate.remark = param.remark;
      _this.contractCreate.discount_value = param.discount_value;
      _this.contractCreate.discount_value_type = param.discount_value_type;
      _this.contractCreate.tax_applied = param.tax_applied;
      _this.contractCreate.adjustment = param.adjustment;
      _this.contractCreate.sales = param.sales;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      const sales_executive = find(_this.salesExecutiveList, {
        full_name: param.sales,
      });
      if (sales_executive) {
        _this.contractCreate.sales = sales_executive.full_name;
        _this.contractCreate.sales_executive = sales_executive.id;
      }

      _this
        .getLineItems({
          contract: _this.duplicateContract,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              threshold_price: data[i].threshold_price,
              project_price: data[i] ? data[i].project_price : 0,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setTicketData(param) {
      const _this = this;

      _this.contractCreate.job_title = param.title;
      _this.contractCreate.description = param.description;
      _this.contractCreate.property_person_notify =
        param.property_person_notify;
      _this.contractCreate.billing_person_notify = param.billing_person_notify;
      _this.contractCreate.reference = `Visit # ${param.barcode}`;
      _this.contractCreate.discount_value = param.discount_value;
      _this.contractCreate.discount_value_type = param.discount_value_type;
      _this.contractCreate.tax_applied = param.tax_applied;
      _this.contractCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          job: _this.ticketId,
          visit: _this.visitId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              threshold_price: data[i].threshold_price,
              project_price: data[i] ? data[i].project_price : 0,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setInvoiceData(param) {
      const _this = this;

      _this.contractCreate.job_title = param.title;
      _this.contractCreate.property_person_notify =
        param.property_person_notify;
      _this.contractCreate.billing_person_notify = param.billing_person_notify;
      _this.contractCreate.reference = `Invoice # ${param.barcode}`;
      _this.contractCreate.discount_value = param.discount_value;
      _this.contractCreate.discount_value_type = param.discount_value_type;
      _this.contractCreate.tax_applied = param.tax_applied;
      _this.contractCreate.adjustment = param.adjustment;

      _this.updateData.term_conditions = param.term_conditions;
      _this.updateData.admin_remark = param.admin_remark;
      _this.updateData.client_remark = param.client_remark;
      _this.updateData.notify_admin = param.notify_admin;
      _this.updateData.notify_customer = param.notify_customer;
      _this.updateData.documents = [];

      _this
        .getLineItems({
          invoice: _this.invoiceId,
        })
        .then((data) => {
          const result = [];
          for (let i = 0; i < data.length; i++) {
            result.push({
              id: null,
              group: data[i].group,
              group_primary: data[i].group_primary,
              product: data[i].product,
              product_id: data[i].product_id,
              serial_no: data[i].serial_no,
              eq_model: data[i].eq_model,
              location: data[i].location,
              product_type: data[i].product_type,
              has_warranty: data[i].has_warranty,
              warranty: data[i].warranty,
              description: data[i].description,
              rate: data[i].rate,
              quantity: data[i].quantity,
              uom: data[i].uom,
              total: data[i].total,
              visit_barcode: data[i].visit_barcode,
              threshold_price: data[i].threshold_price,
              project_price: data[i] ? data[i].project_price : 0,
              is_optional: data[i].is_optional,
            });
          }

          _this.$nextTick(() => {
            _this.dbLineItems = filter(result, function (row) {
              return (
                row.product_type == "goods" || row.product_type == "service"
              );
            });
            _this.dbEquipments = filter(result, {
              product_type: "equipment",
            });
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions() {
      this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "contract/options",
          data: {
            duplicate: this.duplicateContract,
            revise: this.reviseContract,
            contract: this.contractId,
            invoice: this.invoiceId,
            ticket: this.ticketId,
            visit: this.visitId,
            customer: this.contractCustomer,
            is_dup_changed: this.isDupChanged,
            billing: this.contractBilling,
            property: this.contractProperty,
            property_person: this.contractPropertyPerson,
            billing_person: this.contractBillingPerson,
          },
        })
        .then(({ data }) => {
          if (data.user && data.user.full_name) {
            this.contractCreate.prepared_by = data.user.full_name;
          }

          if (data.barcode) {
            this.contractCreate.barcode = data.barcode;
          }

          if (!isEmpty(data.sales_users)) {
            this.salesExecutiveList = data.sales_users;
          }

          if (!isEmpty(data.options)) {
            this.barcodeSetting = data.options;
          }
          if (!isEmpty(data.options) && !this.contractId) {
            this.updateData.term_conditions = data.options.terms_conditions;
          }

          if (!isEmpty(data.contract)) {
            this.setUpdateData(data.contract);
          }
          if (!isEmpty(data.revise)) {
            this.setReviseData(data.revise);
          }

          if (!isEmpty(data.invoice)) {
            this.setInvoiceData(data.invoice);
          }

          if (!isEmpty(data.ticket)) {
            this.setTicketData(data.ticket);
          }

          if (!isEmpty(data.duplicate)) {
            this.setDuplicateData(data.duplicate);
          }

          if (!isEmpty(data.customer)) {
            this.customer = data.customer;
            this.contractCustomer = this.customer.id;
          }

          if (!isEmpty(data.billing)) {
            this.billing = data.billing;
            this.contractBilling = this.billing.id;
          }

          if (!isEmpty(data.property)) {
            this.property = data.property;
            this.contractProperty = this.property.id;
          }

          if (!isEmpty(data.property_person)) {
            this.property_contact_person = data.property_person;
            this.contractPropertyPerson = this.property_contact_person.id;
          }

          if (!isEmpty(data.billing_person)) {
            this.billing_contact_person = data.billing_person;
            this.contractBillingPerson =
              this.billing_contact_person && this.billing_contact_person.id
                ? this.billing_contact_person.id
                : 0;
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getOptions();

    if (this.contractId > 0) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Contract", route: "contract" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Contract", route: "contract" },
        { title: "Create" },
      ]);
    }
    TermConditionOptionsEventBus.$on(
      "update:term-terms-options",
      (argument) => {
        this.termsCondition = argument.title;
        this.additional_remarks = argument.validity;
      }
    );
    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.terms_condition = argument;
    });
    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.noteAttachment = argument;
    });

    this.updateDuration(moment().format("YYYY-MM-DD"));
  },
  beforeMount() {
    this.contractId = toSafeInteger(this.$route.params.id);
    this.contractCustomer = toSafeInteger(this.$route.query.customer);
    this.contractBilling = toSafeInteger(this.$route.query.billing);
    this.contractProperty = toSafeInteger(this.$route.query.property);
    this.contractPropertyPerson = toSafeInteger(
      this.$route.query.property_person
    );
    this.contractBillingPerson = toSafeInteger(
      this.$route.query.billing_person
    );
    this.duplicateContract = toSafeInteger(this.$route.query.duplicate);
    this.reviseContract = toSafeInteger(this.$route.query.revise);
    this.ticketId = toSafeInteger(this.$route.query.ticket);
    this.visitId = toSafeInteger(this.$route.query.visit);
    this.invoiceId = toSafeInteger(this.$route.query.invoice);
    this.isDupChanged = toSafeInteger(this.$route.query.isDupChanged);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.contractCreated && _this.lineItemCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
