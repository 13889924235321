<template>
  <v-container fluid>
    <v-row>
      <v-col md="8" v-if="loglist.length > 0">
        <v-timeline align-top>
          <v-timeline-item
            v-for="(item, i) in loglist"
            :key="i"
            color="cyan"
            small
            right
          >
            <template v-slot:opposite>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="font-size-16 font-weight-500"
                    >{{ dateTimeHumanize(item.datetime) }}</span
                  >
                </template>
                <span>{{ formatDateTime(item.datetime) }}</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-text class="white text--primary">
                <table class="width-100">
                  <tr>
                    <td class="font-size-16 py-1 font-weight-400">Device</td>
                    <td class="font-size-16 py-1 font-weight-500">
                      {{ item.device }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 py-1 font-weight-400">Platform</td>
                    <td class="font-size-16 py-1 font-weight-500">
                      {{ item.platform }} - {{ item.platform_version }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 py-1 font-weight-400">Browser</td>
                    <td class="font-size-16 py-1 font-weight-500">
                      {{ item.browser }} - {{ item.browser_version }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 py-1 font-weight-400">Robot</td>
                    <td class="font-size-16 py-1 font-weight-500">
                      <template v-if="item.robot">Yes</template>
                      <template v-else>No</template>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="font-size-16 py-1 font-weight-400">Languages</td>
                    <td class="font-size-16 py-1 font-weight-500">
                     <template v-if="item && item.languages"> {{ item.languages.join(", ") }}</template> 
                    </td>
                  </tr> -->
                  <tr>
                    <td class="font-size-16 py-1 font-weight-400">
                      IP Address
                    </td>
                    <td class="font-size-16 py-1 font-weight-500">
                      {{ item.ip_address }}
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col md="12" v-else>
        <p class="m-0 row-not-found text-center font-weight-500 font-size-16">
          <img
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mt-4"
          />
          <span class="d-block mt-4"
            >Uhh... There are no login log at the moment.</span
          >
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "login-log",
  props: {
    logs: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
    user: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  watch: {
    logs: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param.length > 0) {
          let temp = this.lodash.cloneDeep(param);
          temp.sort(function compare(a, b) {
            var dateA = new Date(b.datetime);
            var dateB = new Date(a.datetime);
            return dateA - dateB;
          });
          this.loglist = this.lodash.cloneDeep(temp);
        }
      },
    },
  },
  data() {
    return {
      loglist: [],
    };
  },
};
</script>
