<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Notifications -->
    <div
      v-on:click="reloadPage"
      class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
    >
      <span class="svg-icon svg-icon-xl svg-icon-primary">
        <inline-svg :src="$assetURL('media/svg/icons/General/Update.svg')" />
      </span>
      <span class="pulse-ring"></span>
    </div>
    <!--begin: Notifications -->
    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="false"
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg
              :src="$assetURL('media/svg/icons/Code/Compiling.svg')"
            />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-450px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";

export default {
  name: "KTTopbar",
  components: {
    KTQuickUser,
    KTDropdownNotification,
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
