<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Visit
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        v-if="false"
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Visit #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col v-if="false" md="6" sm="6" class="pb-4 col-lg-6">
              <label
                >Buffer Time (in minutes)
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dense
                      style="position: absolute; top: 10px; margin-left: 3px"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span
                    >You can't assign Job to particular Engineer/Technician
                    before<br />
                    or after for this buffer time of already assigned Job to
                    him.<br />
                    e.g. For Engineer A Job assigned from 10 AM to 11 AM &amp;
                    you<br />
                    kept Buffer time as 30 minutes, then in this case you can
                    not<br />
                    assign another Job to him between 9:30 AM till 11:30
                    AM</span
                  >
                </v-tooltip>
              </label>
              <v-text-field
                v-model.trim="setting.buffer_time"
                dense
                filled
                label="Buffer Time (in minutes)"
                solo
                flat
                type="number"
                min="10"
                max="1440"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Job Type
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createJobType">
                  <span class="font-size-16 font-weight-600"
                    >Create Job Type</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in jobTypeList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Job Type"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteJobType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="10" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Add On fuinctions for mobile application
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="2" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobAppSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                 
                  <tr v-for="(data, index) in app_setting" :key="index">
                     <div class="d-flex">
                        <v-switch
                        v-model="data.value"
                        :readonly="data.autoload ? false : true"
                        v-on:change="changeEvent(index)"
                        class="mt-0"
                        :class="data.field == 'all_technician_allocation'? 'mb-5' :''"
                        inset
                        :color="getColor(data.field)"
                      ></v-switch>
                      <div class="mt-1 text-black"  :class="getClass(data.field)">
                        {{getText(data.field)}}
                      </div>
                     </div>
                  </tr>
                 <!--  <tr>
                    <v-switch
                      v-model="allow_creatation"
                      class="mt-0"
                      inset
                      label="Visit Creation Allow In Application"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="self_allow"
                      class="mt-0"
                      inset
                      label="Self"
                      color="green"
                    ></v-switch>
                  </tr>
                  <tr>
                    <v-switch
                      v-model="all_technician"
                      class="mt-0"
                      inset
                      label="All technician"
                      color="green"
                    ></v-switch>
                  </tr> -->
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Remarks
              </h3>
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateJobSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-row>
          <v-col md="12" class="pb-4">
            <editor
              v-model="setting.client_remark"
              :disabled="pageLoading"
              :loading="pageLoading"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Job Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <TinyMCE v-model="setting.terms_conditions" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius mt-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Job Internal Notes
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.admin_note"
                :disabled="pageLoading"
                :loading="pageLoading"
                auto-grow
                dense
                filled
                label="Admin"
                solo
                flat
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
            <v-col md="6" class="pb-4">
              <v-textarea
                v-model.trim="setting.client_note"
                :disabled="pageLoading"
                :loading="pageLoading"
                auto-grow
                dense
                filled
                label="Client"
                solo
                flat
                row-height="30"
                color="cyan"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-job-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        client_remark: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      app_setting: [],
      
      jobTypeList: [
        {
          text: null,
        },
      ],
    };
  },
  components: {
    TinyMCE,
  },
  methods: {
    getClass(text){
      if(text == 'allow_creatation' || text == 'allow_user_allocations'){
          return 'custom-size';  
      }
       return 'ml-2';
    },
    getColor(text){
      if(text == 'allow_creatation' || text == 'allow_user_allocations'){
          return 'green';  
      }
       return 'blue';
    },
    getText(text){
     if(text == 'allow_creatation'){
         return '2. Engineer/Tech can create adhoc visit via mobile Application';  
     }else if(text == 'self_allow'){
      return 'a. Can allocate self only';
     }else if(text == 'all_technician'){
      return 'b. can allocate anyone';
     }
     else if(text == 'allow_user_allocations'){
      return '1. Allow Engineer/Tech  to edit allocations for jobs created by ADMIN';
     }else if(text == 'self_allow_allocation'){
      return 'a. Only can edit self';
     }else if(text == 'all_technician_allocation'){
      return 'b. Can edit all ';
     }
    },
    createJobType() {
      this.jobTypeList.push({
        text: null,
      });
    },
    deleteJobType(index) {
      if (this.jobTypeList.length > 1) {
        this.jobTypeList.splice(index, 1);
      }
    },
    getJobSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.setting = data;
          _this.app_setting = data.app_setting;
          if (data.job_type) {
            _this.jobTypeList = data.job_type.map((row) => {
              return { text: row };
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateJobSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.setting.job_type = _this.jobTypeList.map((row) => {
        return row.text;
      });
      _this.$store
        .dispatch(PATCH, { url: "setting/job", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateJobAppSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/job-app",
          data: {
            app_setting : this.app_setting,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    changeEvent(index){
      
      if(index ==  0){
        if(this.app_setting[0].value){
          this.app_setting[1].autoload = 1;
          this.app_setting[2].autoload = 1;
          this.app_setting[1].value = true;
        }else{
          this.app_setting[1].autoload = 0;
          this.app_setting[2].autoload = 0;
          this.app_setting[1].value = false;
          this.app_setting[2].value = false;
        } 
      }
      if(index ==  1){
        if(this.app_setting[1].value){
          this.app_setting[2].value = false;
        }else{
          this.app_setting[2].value = true;
        }
      }
      if(index ==  2){
        if(this.app_setting[2].value){
          this.app_setting[1].value = false;
        }else{
          this.app_setting[1].value = true;
        }
      }
      // for user edit 
      if(index ==  3){
        if(this.app_setting[3].value){
          this.app_setting[4].autoload = 1;
          this.app_setting[5].autoload = 1;
          this.app_setting[4].value = true;
        }else{
          this.app_setting[4].autoload = 0;
          this.app_setting[5].autoload = 0;
          this.app_setting[4].value = false;
          this.app_setting[5].value = false;
        } 
      }
      if(index ==  4){
        if(this.app_setting[4].value){
          this.app_setting[5].value = false;
        }else{
          this.app_setting[5].value = true;
        }
      }
      if(index ==  5){
        if(this.app_setting[5].value){
          this.app_setting[4].value = false;
        }else{
          this.app_setting[4].value = true;
        }
      }
      console.log({index})
    
    }
  },
  mounted() {
    this.getJobSetting();
  },
};
</script>
